export const BALANCE = {
  // Dev mode
  IS_ANIMALS_DISABLED: false,
  IS_NIGHT_STALKER_DISABLED:false,
  IS_INGREDIENTS_DISABLED: false,
  IS_WORKSHOP_LEVELS_DISABLED: false,
  IS_NIGHTFALL_DISABLED: false,

              //milli sec min 
  DAY_IN_MILLISECONDS: 1000 * 60 * 30, // this is thirty minutes in milliseconds

  SLEDGEHAMMER_WORSE_THAN_HAMMER_RATE: 12, // a sledgehammer has to hit something twelve times to undo the progress of one hammer hit!

  // Mines
  NO_MINE_CHANCE: 0.91, // probability of a panel not having a mine entrance
  MAX_MINE_DEPTH: -15,

  COMMON_ORE_CHANCE: 0.05,
  RARE_ORE_CHANCE: 0.02,
  ULTRA_RARE_ORE_CHANCE: 0.01,

  SALT: -1,
  GRANITE: -1,
  LIMESTONE: -1,
  CLAYSTONE: -1,
  MARBLE: -10,
  BASALT: -10,
  LEAD_ORE: -3,
  GOLD_ORE: -3,
  SILVER_ORE: -3,
  GARNET: -1,
  AMETHYST: -2,
  AQUAMARINE: -3,
  DIAMOND: -4,
  EMERALD: -5,
  MOONSTONE: -6,
  RUBY: -7,
  PERIDOT: -8,
  SAPPHIRE: -9,
  OPAL: -10,
  CITRINE: -11,
  TURQUOISE: -12,
  AMBER: -13,
  JANSSIUM_ORE: -1,
  MATSUNIUM_ORE: -1,
  SUGARIUM_ORE: -4,
  EXUPERIUM_ORE: -4,
  MOEBIUM_ORE: -8,
  WALDIUM_ORE: -8,
  TOLKIUM_ORE: -12,
  LOWRIUM_ORE: -12,
  URSULIUM_ORE: -14,
  OCTAVIUM_ORE: -14,

  // Animals
  NIGHT_STALKER_PACK_SIZE: 20,
  COD_PACK_SIZE: 1,
  KRAKEN_PACK_SIZE: 1,
  MACKEREL_PACK_SIZE: 1,
  REINDEER_PACK_SIZE: 4,
  BROWN_BEAR_PACK_SIZE: 1,
  SEAL_PACK_SIZE: 8,
  MAGMA_CROC_PACK_SIZE: 1,
  RABBIT_PACK_SIZE: 4,
  DEER_PACK_SIZE: 4,
  WOLF_PACK_SIZE: 4,
  BEAVER_PACK_SIZE: 4,
  SAIGA_PACK_SIZE: 4,
  HARE_PACK_SIZE: 4,
  HORSE_PACK_SIZE: 4,
  PIKE_PACK_SIZE: 1,
  SLIME_BEAST_PACK_SIZE: 1,
  RACOON_PACK_SIZE: 2,
  TIGER_PACK_SIZE: 1,
  SNUB_NOSED_MONKEY_PACK_SIZE: 6,
  PANDA_PACK_SIZE: 1,
  SABLE_PACK_SIZE: 1,
  LEOPARD_PACK_SIZE: 1,
  SALMON_PACK_SIZE: 1,
  SHEEP_PACK_SIZE: 4,
  BLACK_BEAR_PACK_SIZE: 1,
  CAMEL_PACK_SIZE: 4,
  PAINTREE_MONKEY_PACK_SIZE: 4,
  DISCORDANT_MONKEY_PACK_SIZE: 5,
  SLOTH_PACK_SIZE: 1,
  CAPYBARA_PACK_SIZE: 2,
  JAGUAR_PACK_SIZE: 1,
  COW_PACK_SIZE: 4,
  ANTELOPE_PACK_SIZE: 6,
  LION_PACK_SIZE: 4,
  BASS_PACK_SIZE: 1,
  CRUSTY_MONKEY_PACK_SIZE: 4,
  ARMADILLO_PACK_SIZE: 2,
  GOAT_PACK_SIZE: 4,
  KOALA_PACK_SIZE: 3,
  KANGAROO_PACK_SIZE: 7,
  CATFISH_PACK_SIZE: 1,
  WONEYED_MONKEY_PACK_SIZE: 7,
  WONEYED_BEAST_PACK_SIZE: 1,
  CHIMPANZEE_PACK_SIZE: 5,
  HOWLER_MONKEY_PACK_SIZE: 4,
  OKAPI_PACK_SIZE: 4,
  POLAR_BEAR_PACK_SIZE: 1,

  REINDEER: {
      level: 1,
      name: 'Reindeer',
      maxHealthPoints: 13,
      weapon: {
        toHit: 0,
        penetration: 1,
        damage: '1d3',
      },
      armour: {
        dodge: 6,
        defence: 4
      }
    },
    HORSE: {
      level: 1,
      name: 'Horse',
      maxHealthPoints: 45,
      weapon: {
        toHit: 0,
        penetration: 1,
        damage: '1d3',
      },
      armour: {
        dodge: 6,
        defence: 4
      }
    },
    CAMEL: {
      level: 7,
      name: 'Camel',
      maxHealthPoints: 30,
      weapon: {
        toHit: -4,
        penetration: 3,
        damage: '1d2',
      },
      armour: {
        dodge: 2,
        defence: 7
      }
    },
    SHEEP: {
      level: 5,
      name: 'Sheep',
      maxHealthPoints: 8,
      weapon: {
        toHit: 1,
        penetration: 1,
        damage: '1d3',
      },
      armour: {
        dodge: 9,
        defence: 2
      }
    },
    COW: {
      level: 5,
      name: 'Cow',
      maxHealthPoints: 5,
      weapon: {
        toHit: 1,
        penetration: 2,
        damage: '1d2',
      },
      armour: {
        dodge: 6,
        defence: 2
      }
    },
    GOAT: {
      level: 6,
      name: 'Azar',
      maxHealthPoints: 27,
      weapon: {
        toHit: 1,
        penetration: 2,
        damage: '1d3',
      },
      armour: {
        dodge: 6,
        defence: 4
      }
    },
    DEER: {
      level: 8,
      name: 'Deer',
      maxHealthPoints: 45,
      weapon: {
        toHit: 1,
        penetration: 1,
        damage: '1d3',
      },
      armour: {
        dodge: 7,
        defence: 6
      }
    },
    ANTELOPE: {
      level: 5,
      name: 'Antelope',
      maxHealthPoints: 5,
      weapon: {
        toHit: 1,
        penetration: 2,
        damage: '1d2',
      },
      armour: {
        dodge: 6,
        defence: 2
      }
    },
    SAIGA: {
      level: 9,
      name: 'Saiga',
      maxHealthPoints: 25,
      weapon: {
        toHit: 0,
        penetration: 1,
        damage: '1d8',
      },
      armour: {
        dodge: 6,
        defence: 3
      }
    },
    HARE: {
      level: 1,
      name: 'Hare',
      maxHealthPoints: 8,
      weapon: {
        toHit: 0,
        penetration: 1,
        damage: '1d3',
      },
      armour: {
        dodge: 6,
        defence: 2
      }
    },
    ARMADILLO: {
      level: 7,
      name: 'Armadillo',
      maxHealthPoints: 20,
      weapon: {
        toHit: -4,
        penetration: 2,
        damage: '1d3',
      },
      armour: {
        dodge: 2,
        defence: 3
      }
    },
    KANGAROO: {
      level: 18,
      name: 'Kangaroo',
      maxHealthPoints: 25,
      weapon: {
        toHit: -5,
        penetration: 7,
        damage: '2d2',
      },
      armour: {
        dodge: 1,
        defence: 3
      }
    },
    OKAPI: {
      level: 1,
      name: 'Okapi',
      maxHealthPoints: 9,
      weapon: {
        toHit: 1,
        penetration: -1,
        damage: '1d3',
      },
      armour: {
        dodge: 7,
        defence: 2
      }
    },
    RACOON: {
      level: 1,
      name: 'Racoon',
      maxHealthPoints: 8,
      weapon: {
        toHit: 0,
        penetration: 1,
        damage: '1d3',
      },
      armour: {
        dodge: 6,
        defence: 2
      }
    },
    RABBIT: {
      level: 1,
      name: 'Rabbit',
      maxHealthPoints: 20,
      weapon: {
        toHit: -7,
        penetration: 3,
        damage: '1d3',
      },
      armour: {
        dodge: -1,
        defence: 3
      }
    },
    SNUB_NOSED_MONKEY: {
      level: 8,
      name: 'Snub Nosed Monkey',
      maxHealthPoints: 20,
      weapon: {
        toHit: -4,
        penetration: 2,
        damage: '1d3',
      },
      armour: {
        dodge: 2,
        defence: 6
      }
    },
    PAINTREE_MONKEY: {
      level: 6,
      name: 'Paintree Monkey',
      maxHealthPoints: 27,
      weapon: {
        toHit: 1,
        penetration: 2,
        damage: '1d3',
      },
      armour: {
        dodge: 6,
        defence: 4
      }
    },
    CRUSTY_MONKEY: {
      level: 9,
      name: 'Crusty Monkey',
      maxHealthPoints: 25,
      weapon: {
        toHit: -4,
        penetration: 2,
        damage: '1d8',
      },
      armour: {
        dodge: 6,
        defence: 3
      }
    },
    DISCORDANT_MONKEY: {
      level: 15,
      name: 'Discordant Monkey',
      maxHealthPoints: 60,
      weapon: {
        toHit: 1,
        penetration: 6,
        damage: '1d8',
      },
      armour: {
        dodge: 7,
        defence: 7
      }
    },
    WONEYED_MONKEY: {
      level: 15,
      name: 'Woneyed Monkey',
      maxHealthPoints: 60,
      weapon: {
        toHit: 1,
        penetration: 6,
        damage: '1d8',
      },
      armour: {
        dodge: 7,
        defence: 7
      }
    },
    CHIMPANZEE: {
      level: 30,
      name: 'Chimpanzee',
      maxHealthPoints: 100,
      weapon: {
        toHit: 4,
        penetration: 12,
        damage: '3d3',
      },
      armour: {
        dodge: 13,
        defence: 4
      }
    },
    HOWLER_MONKEY: {
      level: 25,
      name: 'Howler Monkey',
      maxHealthPoints: 135,
      weapon: {
        toHit: -1,
        penetration: 13,
        damage: '3d3',
      },
      armour: {
        dodge: 5,
        defence: 9
      }
    },
    SLOTH: {
      level: 7,
      name: 'Sloth',
      maxHealthPoints: 30,
      weapon: {
        toHit: -4,
        penetration: 3,
        damage: '1d2',
      },
      armour: {
        dodge: 2,
        defence: 7
      }
    },
    PANDA: {
      level: 13,
      name: 'Panda',
      maxHealthPoints: 40,
      weapon: {
        toHit: 2,
        penetration: 2,
        damage: '1d5',
      },
      armour: {
        dodge: 8,
        defence: 5
      }
    },
    SABLE: {
      level: 7,
      name: 'Sable',
      maxHealthPoints: 20,
      weapon: {
        toHit: -4,
        penetration: 2,
        damage: '1d3',
      },
      armour: {
        dodge: 2,
        defence: 3
      }
    },
    KOALA: {
      level: 8,
      name: 'Koala',
      maxHealthPoints: 20,
      weapon: {
        toHit: -4,
        penetration: 2,
        damage: '1d3',
      },
      armour: {
        dodge: 2,
        defence: 6
      }
    },
    BROWN_BEAR: {
      level: 28,
      name: 'Brown Bear',
      maxHealthPoints: 160,
      weapon: {
        toHit: 6,
        penetration: 3,
        damage: '2d3+1',
      },
      armour: {
        dodge: 12,
        defence: 7
      }
    },
    TIGER: {
      level: 34,
      name: 'Tiger',
      maxHealthPoints: 200,
      weapon: {
        toHit: 3,
        penetration: 9,
        damage: '1d3',
      },
      armour: {
        dodge: 9,
        defence: 4
      }
    },
    LEOPARD: {
      level: 33,
      name: 'Leopard',
      maxHealthPoints: 140,
      weapon: {
        toHit: 4,
        penetration: 15,
        damage: '4d4',
      },
      armour: {
        dodge: 2,
        defence: 6
      }
    },
    BLACK_BEAR: {
      level: 12,
      name: 'Black Bear',
      maxHealthPoints: 45,
      weapon: {
        toHit: 4,
        penetration: 2,
        damage: '1d3',
      },
      armour: {
        dodge: 10,
        defence: 7
      }
    },
    POLAR_BEAR: {
      level: 32,
      name: 'Polar Bear',
      maxHealthPoints: 120,
      weapon: {
        toHit: 7,
        penetration: 3,
        damage: '2d6+1',
      },
      armour: {
        dodge: 13,
        defence: 4
      }
    },
    LION: {
      level: 32,
      name: 'Lion',
      maxHealthPoints: 180,
      weapon: {
        toHit: 4,
        penetration: 9,
        damage: '6d4',
      },
      armour: {
        dodge: 10,
        defence: 8
      }
    },
    JAGUAR: {
      level: 29,
      name: 'Jaguar',
      maxHealthPoints: 175,
      weapon: {
        toHit: 6,
        penetration: 12,
        damage: '3d3',
      },
      armour: {
        dodge: 6,
        defence: 10
      }
    },
    WOLF: {
      level: 12,
      name: 'Wolf',
      maxHealthPoints: 40,
      weapon: {
        toHit: 1,
        penetration: 2,
        damage: '2d2+1',
      },
      armour: {
        dodge: 7,
        defence: 5
      }
    },
    NIGHT_STALKER: {
      level: 22,
      name: 'Night Wraith',
      maxHealthPoints: 100,
      weapon: {
        toHit: 5,
        penetration: 2,
        damage: '2d2+1',
      },
      armour: {
        dodge: 17,
        defence: 6
      }
    },
    SNAPJAW: {
      level: 3,
      name: 'Snapping Maw',
      maxHealthPoints: 13,
      weapon: {
        toHit: 1,
        penetration: -1,
        damage: '1d4',
      },
      armour: {
        dodge: 7,
        defence: 2
      }
    },
    POISONER: {
      level: 29,
      name: 'Festering Cat',
      maxHealthPoints: 175,
      weapon: {
        toHit: 6,
        penetration: 12,
        damage: '3d3',
      },
      armour: {
        dodge: 6,
        defence: 10
      }
    },
    SWARMER: {
      level: 18,
      name: 'Whirling Alf',
      maxHealthPoints: 25,
      weapon: {
        toHit: -5,
        penetration: 7,
        damage: '2d2',
      },
      armour: {
        dodge: 1,
        defence: 3
      }
    },
    TROLL: {
      level: 8,
      name: 'Stinking Troll',
      maxHealthPoints: 45,
      weapon: {
        toHit: 1,
        penetration: 1,
        damage: '1d3',
      },
      armour: {
        dodge: 7,
        defence: 6
      }
    },
    SCARY: {
      level: 25,
      name: 'Flayed Ogre',
      maxHealthPoints: 135,
      weapon: {
        toHit: -1,
        penetration: 13,
        damage: '3d3',
      },
      armour: {
        dodge: 5,
        defence: 9
      }
    },
    SHOOTER: {
      level: 12,
      name: 'Dinodemon',
      maxHealthPoints: 40,
      weapon: {
        toHit: 1,
        penetration: 2,
        damage: '2d2',
      },
      armour: {
        dodge: 7,
        defence: 5
      }
    },
    SWAPPER: {
      level: 28,
      name: 'Jangling Swapper',
      maxHealthPoints: 100,
      weapon: {
        toHit: 3,
        penetration: 12,
        damage: '4d4',
      },
      armour: {
        dodge: 3,
        defence: 5
      }
    },
    RUSTER: {
      level: 27,
      name: 'Sucking Ruster',
      maxHealthPoints: 50,
      weapon: {
        toHit: 3,
        penetration: 11,
        damage: '1d8+2',
      },
      armour: {
        dodge: 3,
        defence: 5
      }
    },
    AOE: {
      level: 22,
      name: 'Minotaur',
      maxHealthPoints: 100,
      weapon: {
        toHit: 5,
        penetration: 2,
        damage: '2d2+1',
      },
      armour: {
        dodge: 17,
        defence: 6
      }
    },
    PHASER: {
      level: 15,
      name: 'Wall Walker',
      maxHealthPoints: 30,
      weapon: {
        toHit: 4,
        penetration: 1,
        damage: '2d4',
      },
      armour: {
        dodge: 14,
        defence: 4
      }
    },
    PHASED_SHOOTER: {
      level: 32,
      name: 'Wall Sniper',
      maxHealthPoints: 180,
      weapon: {
        toHit: 4,
        penetration: 9,
        damage: '6d4',
      },
      armour: {
        dodge: 10,
        defence: 8
      }
    },
    DISARMER: {
      level: 13,
      name: 'Undead Nymph',
      maxHealthPoints: 40,
      weapon: {
        toHit: 2,
        penetration: 2,
        damage: '1d5',
      },
      armour: {
        dodge: 8,
        defence: 5
      }
    },
    LIFE_LEECH: {
      level: 27,
      name: 'Leech Wight',
      maxHealthPoints: 50,
      weapon: {
        toHit: 3,
        penetration: 11,
        damage: '1d8+2',
      },
      armour: {
        dodge: 3,
        defence: 5
      }
    },
    SLUGGISHER: {
      level: 25,
      name: 'Fetid Slime',
      maxHealthPoints: 75,
      weapon: {
        toHit: 6,
        penetration: 2,
        damage: '1d6',
      },
      armour: {
        dodge: 14,
        defence: 1
      }
    },
    HP_HALVER: {
      level: 23,
      name: 'Elephcant',
      maxHealthPoints: 85,
      weapon: {
        toHit: -4,
        penetration: 8,
        damage: '1d4+3',
      },
      armour: {
        dodge: 2,
        defence: 6
      }
    },
    TELEPORTER: {
      level: 25,
      name: 'Craggyak',
      maxHealthPoints: 75,
      weapon: {
        toHit: 6,
        penetration: 2,
        damage: '1d6',
      },
      armour: {
        dodge: 14,
        defence: 1
      }
    },
    HORN_BLOWER: {
      level: 15,
      name: 'Blind Warbler',
      maxHealthPoints: 30,
      weapon: {
        toHit: 4,
        penetration: 1,
        damage: '2d4',
      },
      armour: {
        dodge: 14,
        defence: 4
      }
    },
    BEASTIE_SPAWNER: {
      level: 28,
      name: 'Wyrm Cat',
      maxHealthPoints: 160,
      weapon: {
        toHit: 6,
        penetration: 3,
        damage: '2d3+1',
      },
      armour: {
        dodge: 12,
        defence: 7
      }
    },
    DEATH_SONG: {
      level: 23,
      name: 'Swan Singer',
      maxHealthPoints: 85,
      weapon: {
        toHit: -4,
        penetration: 8,
        damage: '1d4+3',
      },
      armour: {
        dodge: 2,
        defence: 6
      }
    },
    TELEPORT_LEVEL: {
      level: 32,
      name: 'Void Caller',
      maxHealthPoints: 120,
      weapon: {
        toHit: 7,
        penetration: 3,
        damage: '2d6+1',
      },
      armour: {
        dodge: 13,
        defence: 4
      }
    },
    BLINDNESSER: {
      level: 35,
      name: 'Naga Orc',
      maxHealthPoints: 175,
      weapon: {
        toHit: 8,
        penetration: 6,
        damage: '3d3',
      },
      armour: {
        dodge: 14,
        defence: 12
      }
    },
    WONEYED_BEAST: {
      level: 30,
      name: 'Woneyed Beast',
      maxHealthPoints: 100,
      weapon: {
        toHit: 7,
        penetration: 12,
        damage: '3d3',
      },
      armour: {
        dodge: 13,
        defence: 4
      }
    },
    WEB_SHOOTER: {
      level: 34,
      name: 'Grab Crab',
      maxHealthPoints: 200,
      weapon: {
        toHit: 3,
        penetration: 9,
        damage: '1d3',
      },
      armour: {
        dodge: 9,
        defence: 4
      }
    },
    ACID_SPLASH: {
      level: 28,
      name: 'Cawcatrice',
      maxHealthPoints: 100,
      weapon: {
        toHit: 3,
        penetration: 12,
        damage: '4d4',
      },
      armour: {
        dodge: 3,
        defence: 5
      }
    },
    FIRE_SHOOTER: {
      level: 33,
      name: 'Balrog',
      maxHealthPoints: 140,
      weapon: {
        toHit: 4,
        penetration: 15,
        damage: '4d4',
      },
      armour: {
        dodge: 2,
        defence: 6
      }
    },
    SLIME_BEAST: {
      level: 26,
      name: 'Slime Beast',
      maxHealthPoints: 50,
      weapon: {
        toHit: -1,
        penetration: 6,
        damage: '3d3',
      },
      armour: {
        dodge: 5,
        defence: 5
      }
    },
    MAGMA_CROC: {
      level: 35,
      name: 'Burning Beast',
      maxHealthPoints: 175,
      weapon: {
        toHit: 8,
        penetration: 6,
        damage: '3d3',
      },
      armour: {
        dodge: 14,
        defence: 12
      }
    },
    SALMON: {
      level: 5,
      name: 'Salmon',
      maxHealthPoints: 8,
      weapon: {
        toHit: 1,
        penetration: 1,
        damage: '1d3',
      },
      armour: {
        dodge: 9,
        defence: 2
      }
    },
    PIKE: {
      level: 1,
      name: 'Pike',
      maxHealthPoints: 8,
      weapon: {
        toHit: 0,
        penetration: 1,
        damage: '1d3',
      },
      armour: {
        dodge: 6,
        defence: 2
      }
    },
    MACKEREL: {
      level: 1,
      name: 'Mackerel',
      maxHealthPoints: 20,
      weapon: {
        toHit: -7,
        penetration: 3,
        damage: '1d3',
      },
      armour: {
        dodge: -1,
        defence: 3
      }
    },
    SEAL: {
      level: 1,
      name: 'Seal',
      maxHealthPoints: 9,
      weapon: {
        toHit: 1,
        penetration: -1,
        damage: '1d3',
      },
      armour: {
        dodge: 7,
        defence: 2
      }
    },
    CAPYBARA: {
      level: 5,
      name: 'Capybara',
      maxHealthPoints: 11,
      weapon: {
        toHit: 2,
        penetration: 0,
        damage: '2d3',
      },
      armour: {
        dodge: 10,
        defence: 2
      }
    },
    COD: {
      level: 1,
      name: 'Cod',
      maxHealthPoints: 13,
      weapon: {
        toHit: 0,
        penetration: 1,
        damage: '1d3',
      },
      armour: {
        dodge: 6,
        defence: 4
      }
    },
    BEAVER: {
      level: 5,
      name: 'Beaver',
      maxHealthPoints: 5,
      weapon: {
        toHit: 1,
        penetration: 2,
        damage: '1d2',
      },
      armour: {
        dodge: 6,
        defence: 2
      }
    },
    BASS: {
      level: 5,
      name: 'Bass',
      maxHealthPoints: 11,
      weapon: {
        toHit: 2,
        penetration: 0,
        damage: '2d3',
      },
      armour: {
        dodge: 10,
        defence: 2
      }
    },
    KRAKEN: {
      level: 26,
      name: 'Kraken',
      maxHealthPoints: 50,
      weapon: {
        toHit: -1,
        penetration: 6,
        damage: '3d3',
      },
      armour: {
        dodge: 5,
        defence: 5
      }
    },
    CATFISH: {
      level: 3,
      name: 'Catfish',
      maxHealthPoints: 14,
      weapon: {
        toHit: 1,
        penetration: -1,
        damage: '1d4',
      },
      armour: {
        dodge: 7,
        defence: 2
      }
    },

  // Monsters

  // XP
  HIT_POINTS_LEVEL_UP_FACTOR: 1,
  WORKSHOP_LEVEL_UP_SP_COST: 4,
  HIT_POINT_REGEN_ON_TURN: 50,

  // HUNGER
  HUNGER_IMPACTS_VALUE: 2000,
  HUNGER_REGENS_EVERY_X_TURNS: 10,

  // INVENTORY SIZE
  BASE_CHARACTER_WEIGHT: 1000,
  MAX_FLOOR_WEIGHT: 1000,
  MAX_WAGON_WEIGHT: 1000, //TODO IMPLEMENT

  WORKSHOP_LEVEL_UPGRADE_COST: 4,

  // PLANT YIELDS
  MIN_YIELD: 1,
  MAX_YIELD: 10,

  DAYS_REQUIRED_TO_MAKE_NEW_EMBARK: 40
}