import {
    CREATE_LOCK_LOADING,
    CREATE_LOCK_SUCCESS,
    CREATE_LOCK_FAILURE,
    GET_LOCKS_LOADING,
    GET_LOCKS_SUCCESS,
    GET_LOCKS_FAILURE,
    ADD_LOCK,
    UPDATE_LOCK,
    REMOVE_LOCK,
    GET_TRADE_LOCKS_SUCCESS,
    GET_TRADE_LOCKS_FAILURE,
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

export const createLockSuccess = payload => ({
    type: CREATE_LOCK_SUCCESS,
    payload
})

export const createLockFailure = () => ({
    type: CREATE_LOCK_FAILURE
})

export const createLockAsync = payload => dispatch => {
    const { _id, lockTypeId, direction, selectedCharacterId, workshopId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'lock-instances',
        name: 'create',
        data: {
            lockTypeId,
            selectedCharacterId,
            workshopId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const installLockAsync = payload => dispatch => {
    const { _id, lockTypeId, direction } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'lock-instances',
        commandType: 'patch',
        data: {
            _id,
            lockDirection: direction,
            characterId: undefined,
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getLocksSuccess = payload => {
    return ({
        type: GET_LOCKS_SUCCESS,
        payload
    })
}

export const getLocksFailure = () => ({
    type: GET_LOCKS_FAILURE
})

export const getLocksAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('lock-instances').find({ paginate: false, query })
        .then((response) => {
            response = response.map(lock => ({ ...lock, lockType: payload.lockTypes.find(type => type._id === lock.lockTypeId) }))
            dispatch(getLocksSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const getTradeLocksSuccess = payload => {
    return ({
        type: GET_TRADE_LOCKS_SUCCESS,
        payload
    })
}

export const getTradeLocksFailure = () => ({
    type: GET_TRADE_LOCKS_FAILURE
})

export const getTradeLocksAsync = payload => dispatch => {
    return client.service('lock-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(lock => ({ ...lock, lockType: payload.lockTypes.find(type => type._id === lock.lockTypeId) }))
            dispatch(getTradeLocksSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            throw new Error(error);
        })
}

export const lockLockAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'lock-instances',
        commandType: 'patch',
        data: {
            _id: payload._id,
            isLocked: true
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const unlockLockAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'lock-instances',
        commandType: 'patch',
        data: {
            _id: payload._id,
            isLocked: false
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addLockToTileAsync = payload => dispatch => {
    const { lockInstance, tileId, tileTypeId } = payload;
    const { lockTypeId, quantity, recipeId, characterId, _id } = lockInstance;

    colyClient.room.send('doAction', {
        serviceName: 'lock-instances',
        commandType: 'create',
        data: {
            lockTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addLockToCharacterAsync = payload => dispatch => {
    const { lockInstance, characterId } = payload;
    const { createdAt, lockTypeId, quantity, recipeId, _id } = lockInstance;

    colyClient.room.send('doSuperAction', {
        serviceName: 'lock-instances',
        name: 'fetch',
        data: {
            lockTypeId,
            quantity,
            tileId: "",
            characterId
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}
export const addLock = payload => {
    return ({
        type: ADD_LOCK,
        payload
    })
}

export const updateLock = payload => {
    return ({
        type: UPDATE_LOCK,
        payload
    })
}

export const removeLock = payload => {
    return ({
        type: REMOVE_LOCK,
        payload
    })
}
