import {
    CREATE_BRAINCHIP_LOADING,
    CREATE_BRAINCHIP_SUCCESS,
    CREATE_BRAINCHIP_FAILURE,
    GET_BRAINCHIPS_LOADING,
    GET_BRAINCHIPS_SUCCESS,
    GET_BRAINCHIPS_FAILURE,
    ADD_BRAINCHIP,
    UPDATE_BRAINCHIP,
    REMOVE_BRAINCHIP,
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';

import { colyClient } from '../../services/Panel-initialisation.service';
import { client } from '../../services/client';

import { moveItemFailure } from './move-item.actions';

export const createBrainchipSuccess = payload => ({
    type: CREATE_BRAINCHIP_SUCCESS,
    payload
})

export const createBrainchipFailure = () => ({
    type: CREATE_BRAINCHIP_FAILURE
})

export const createBrainchipAsync = payload => dispatch => {
    const { _id, brainchipTypeId, characterId, selectedCharacterId, workshopId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'brainchip-instances',
        name: 'create',
        data: {
            brainchipTypeId,
            characterId,
            selectedCharacterId,
            workshopId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getBrainchipsSuccess = payload => {
    return ({
        type: GET_BRAINCHIPS_SUCCESS,
        payload
    })
}

export const getBrainchipsFailure = () => ({
    type: GET_BRAINCHIPS_FAILURE
})

export const getBrainchipsAsync = payload => dispatch => {
    return client.service('brainchip-instances').find({ paginate: false, query: { characterId: payload.characterId } })
        .then((response) => {
            response = response.map(brainchip => ({ ...brainchip, brainchipType: payload.brainchipTypes.find(type => type._id === brainchip.brainchipTypeId) }))

            dispatch(getBrainchipsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const activateBrainchipAsync = payload => dispatch => {
    const { _id } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'brainchip-instances',
        commandType: 'patch',
        data: {
            _id,
            isActive: true
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addBrainchip = payload => {
    return ({
        type: ADD_BRAINCHIP,
        payload
    })
}

export const updateBrainchip = payload => {
    return ({
        type: UPDATE_BRAINCHIP,
        payload
    })
}

export const removeBrainchip = payload => {
    return ({
        type: REMOVE_BRAINCHIP,
        payload
    })
}
