import React from "react";
import { connect } from 'react-redux'

import store from '../../redux/store';
import { getMessagesAsync, getMoreMessagesAsync, createMessageAsync, createNewMessage } from '../../redux/actions/messages.actions';
import { flashCharacterColorAsync } from '../../redux/actions/characters.actions';
import { disableKeyboardMovement, enableKeyboardMovement, showHelp, startLooking, hideAllMenus } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectCharacter,
    selectDiaryEntries
} from '../../redux/selectors';
import { format, formatDistance, formatRelative, subDays } from 'date-fns';

import { client } from '../../services/client';

// import './Diary.css';

class Diary extends React.Component {
    state = {
        navigationHandler: this.navigationHandler.bind(this),
        top: 0
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.state.navigationHandler)
    }

    componentDidMount() {
        document.addEventListener('keydown', this.state.navigationHandler);
    }

    getMoreMessages() {
        if (this.state.isGettingMore) {
            return;
        }

        this.setState({
            isGettingMore: true
        })

        store.dispatch(getMoreMessagesAsync({
            characterId: this.props.character._id,
            skip: this.props.messages.length
        }))
            .finally(() => {
                this.setState({
                    isGettingMore: false
                })
            })
    }

    navigationHandler(event) {
        const STEP_SIZE = 40;

        if (event.key === 'ArrowDown') {
            const eventsContainer = document.getElementsByClassName('events-inner')[0]

            if (this.state.top * -1 > eventsContainer.clientHeight - (2 * STEP_SIZE)) {
                return
            }
            
            this.setState({
                top: this.state.top - STEP_SIZE
            })

            this.getMoreMessages();

            return;
        }

        if (event.key === 'ArrowUp') {
            if (this.state.top >= 0) {
                return;
            }
            // find top element and move it below visible frame
            this.setState({
                top: this.state.top + STEP_SIZE
            })

            return;
        }
    }

    formatDistance(result, number) {
        result = result.replace('Minutes', 'm');
        result = result.replace('Hours', 'h');
        result = result.replace('Days', 'd');
        result = result.replace('Months', 'mo');
        result = result.replace('Years', 'y');

        result = result.replace('lessThan', '<')
        result = result.replace('about', '');
        result = result.replace('over', '>');

        result = result.replace('x', number)
        result = result.replace('X', number)

        return result;
    }

    isActivePreference(preference) {
        return this.state.preferences.find(currentPreference => currentPreference === preference);
    }

    render() {
        const messages = this.props.messages.map((message, index) => {
            const characterName = message.characterName
            let targetCharacterName = message.targetCharacterName;

            if (!targetCharacterName) {
                targetCharacterName = 'Unknown';
            }

            return (
                <div className="event" key={index}>
                    <p>
                        <span className="event-date">
                            &nbsp;{formatDistance(new Date(message.createdAt), new Date(), { locale: { formatDistance: this.formatDistance.bind(this) } })}&nbsp;
                        </span>
                        <span className={(message.type === "TALK_TO_CHARACTER" && message.characterId === message.targetCharacterId)? "" : "hidden"}>
                            {message.text}
                        </span>
                        <span className={(message.type === "TALK_TO_CHARACTER" && message.characterId !== message.targetCharacterId) ? "" : "hidden"}>
                            {characterName} said to {targetCharacterName} <span className="shortcut">"{message.text}"</span>
                        </span>
                        <span className={(message.type === "TALK_TO_WORLD" && characterName) ? "" : "hidden"}>
                            {characterName} said <span className="shortcut">"{message.text}"</span>
                        </span>
                        <span className={(message.type === "TALK_TO_WORLD" && !characterName) ? "" : "hidden"}>
                            <span className="system-message-text">{message.text}</span>
                        </span>
                        <span className={(message.type === "TALK_TO_CHARACTER" || message.type === "TALK_TO_WORLD") ? "hidden" : ""}>
                            {message.promptText}
                            {message.text}
                        </span>
                    </p>
                </div>
            )
        })
        return (
            <div className="events-component">
                <div className="events-inner" style={{'top': this.state.top}}>
                    {messages}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const character = selectCharacter(state)
    const messages = selectDiaryEntries(state);

    return {
        character,
        messages
    }
}

export default connect(
    mapStateToProps,
    { hideAllMenus, getMoreMessagesAsync }
)(Diary);