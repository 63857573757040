import React from "react";
import { connect } from 'react-redux'

import Menu from '../utils/menu/Menu';

import './Help.css';

class Help extends React.Component {

    render() {

        return (
            <div className="equipment">
                <Menu options={[
                        { shortcut: 'WASD', text: ' Move'},
                        { shortcut: '↑←↓→', text: ' Move' },
                        { shortcut: 'SPACE', text: ' Interact' },
                        { shortcut: 'SHIFT + ↑←↓→', text: ' Interact in direction' },
                        { shortcut: 'j', text: ' Talk to town' },
                        { shortcut: 'i', text: ' Inventory' },
                        { shortcut: 'a', text: ' Name location' },
                        // { shortcut: 'p', text: ' Profile' },
                        { shortcut: 'l', text: ' Look' },
                        { shortcut: 'q', text: ' Equipment' },
                        { shortcut: 't', text: ' Character sheet' },
                        { shortcut: 'k', text: ' Workshop' },
                        { shortcut: 'z', text: ' Conversation tree wizard' },
                        { shortcut: 'v', text: ' Events' },
                        // { shortcut: 'y', text: ' Diary' },
                        { shortcut: 'm', text: ' Move' },
                        { shortcut: 'g', text: ' Gather' },
                        { shortcut: 'h', text: ' Haul' },
                        { shortcut: 'n', text: ' Zone' },
                        { shortcut: 'o', text: ' Organisation' },
                        { shortcut: 'u', text: ' Schedule' },
                        { shortcut: 'x', text: ' Change character' },
                        { shortcut: '>', text: ' Walk downstairs' },
                        { shortcut: '<', text: ' Walk upstairs' },
                        { shortcut: 'ESC', text: ' Close Menu' },
                        { shortcut: '/logout [in chat]', text: ' Logout' },
                        { shortcut: '/discord [in chat]', text: ' Open Discord in new tab' },
                        { shortcut: '/wiki [in chat]', text: ' Open Wiki in new tab' },
                    ]}
                    menuContainer="equipment"
                    // optionChosen={this.onOrganisationChosen.bind(this)}
                />
            </div>
        )
    }
}

const mapToStateProps = state => {
    return {}
}

export default connect(
    mapToStateProps,
    {
        
    }
)(Help);