import Phaser from 'phaser';
import GameScene from '../../../scenes/GameScene';
import React from "react";
import { diffWords, diffChars } from 'diff';

import Startup from '../../startup/Startup';
import Actions from '../../actions/Actions';
import Prompt from '../../prompt/Prompt';
import Help from '../../help/Help';
import Looking from '../../side-bar/looking/Looking';
import Crafting from '../../crafting/Crafting';
import InventoryActions from '../../inventory/inventory-actions/InventoryActions';
import TileActions from '../../shared/tile-actions/TileActions';
import LockActions from '../../actions/lock-actions/LockActions';
import Character from '../../side-bar/character/Character';
import Location from '../../side-bar/location/Location';
import SidebarMessages from '../../side-bar/messages/SidebarMessages';
import Messages from '../../messages/Messages';
import ConversationTree from '../../conversation-tree/ConversationTree';
import Dialogue from '../../dialogue/Dialogue';
import PlantActions from '../../side-bar/plant-actions/PlantActions';
import MineralActions from '../../side-bar/mineral-actions/MineralActions';
import FarmActions from '../../side-bar/farm-actions/FarmActions';
import FarmPlotActions from '../../side-bar/farm-plot-actions/FarmPlotActions';
import NeighbouringCharacters from '../../side-bar/neighbouring-characters/NeighbouringCharacters';
import DeadCharacters from '../../side-bar/dead-characters/DeadCharacters';
import MachineList from '../../side-bar/machine-list/MachineList'
import CharacterList from '../../side-bar/character-list/CharacterList'
import ConstructionActions from '../../actions/construction-actions/ConstructionActions';
import FurnitureActions from '../../actions/furniture-actions/FurnitureActions';
import NoCharactersNearbyError from '../../error-messages/NoCharactersNearbyError';
import CraftingError from '../../error-messages/CraftingError';
import MovingError from '../../error-messages/MovingError';
import UnknownError from '../../error-messages/UnknownError';
import Profile from '../../profile/Profile';
import Equipment from '../../equipment/Equipment';
import Sheet from '../../sheet/Sheet';
import Workshop from '../../workshop/Workshop';
import WorkshopSelect from '../../workshop/WorkshopSelect';
import ConversationTreeWizard from '../../conversation-tree-wizard/ConversationTreeWizard';
import Events from '../../events/Events';
import Diary from '../../diary/Diary';
import EditWritingSurface from '../../inventory/edit-writing-surface/EditWritingSurface';
import EditDrawingSurface from '../../edit-drawing-surface/EditDrawingSurface';
import Trade from '../../trade/Trade';
import LevelUp from '../../level-up/LevelUp';
import GroupTerminal from '../../group-terminal/GroupTerminal';
import GuardHouse from '../../guard-house/GuardHouse';
import OnScreenDialogue from '../../on-screen-dialogue/OnScreenDialogue';
import SelectItems from '../../select-items/SelectItems';
import DirectionInput from '../../utils/direction-input/DirectionInput';
import Menu from '../../utils/menu/Menu';
import TextInput from '../../utils/text-input/TextInput';
import ItemDescription from '../../item-description/ItemDescription';
import CharacterDeathPage from '../../character-death-page/CharacterDeathPage';
import ReadWritingSurface from '../../read-writing-surface/ReadWritingSurface';
import Organisation from '../../organisation/Organisation'
import OrganisationList from '../../organisation-list/OrganisationList'
import ZoneList from '../../zone-list/ZoneList';
import Zone from '../../zone/Zone';
import Scheduler from '../../scheduler/Scheduler';

import AudioService from '../../../services/Audio.service';

import {
    enableKeyboardMovement,
    hideCraftingError,
    hideMovingError,
    showUnknownError,
    hideUnknownError,
    hideHelp,
    openInventory,
    hideAllMenus,
    openCrafting,
    showWritingSurface,
    showDrawingSurface,
    hideSelectItems,
    hideWritingSurface,
    hideActionSelectMenu
} from '../../../redux/actions/keyboard-shortcuts.actions';
import { updateVisionRadius } from '../../../redux/actions/clock.actions';
import { createMessageAsync } from '../../../redux/actions/messages.actions';
import { logOutAsync } from '../../../redux/actions/user.actions';
import { createDiaryEntryAsync } from '../../../redux/actions/diary-entries.actions';
import { addCharacterPanelAsync, updateCharacterPanelAsync } from '../../../redux/actions/character-panel.actions';
import {
    createGraffitiAsync,
    createDrawingAsync,
    createNewGraffitiItemAsync,
    createNewDrawingItemAsync
} from '../../../redux/actions/graffiti.actions';
import store from '../../../redux/store';
import { client } from '../../../services/client';
import {
    selectUser,
    selectHighlight,
    selectCharacter,
    selectIsInventoryShowing,
    selectIsCraftingShowing,
    selectIsActionsShowing,
    selectIsHelpShowing,
    selectIsNoCharactersNearbyErrorShowing,
    selectIsCraftingErrorShowing,
    selectIsMovingErrorShowing,
    selectIsUnknownErrorShowing,
    selectErrorMessage,
    selectIsMessagesShowing,
    selectIsCharacterActionsShowing,
    selectFocussedCharacterId,
    selectCharacterById,
    selectIsCharacterProfileShowing,
    selectIsEquipmentShowing,
    selectIsSheetShowing,
    selectIsWorkshopShowing,
    selectIsWorkshopSelectShowing,
    selectIsConversationTreeWizardShowing,
    selectIsEventsShowing,
    selectIsDiaryShowing,
    selectWorkshopTypeId,
    selectWorkshopTypeById,
    selectIsDirectionInputShowing,
    selectIsWritingOnSurface,
    selectIsLevelUpMessageShowing,
    selectIsNearDeathScreenShowing,
    selectWritingSurfaceById,
    selectPaints,
    selectWorkshopByPosition,
    selectIsTerminalShowing,
    selectIsGuardHouseShowing,
    selectIsNewTownMessageShowing,
    selectIsSelectItemsShowing,
    selectIsCharacterDeathPageShowing,
    selectIsTableShowing,
    selectIsActionSelectShowing,
    selectConnectionState,
    selectCurrentPrompt,
    selectIsNewOrganisationShowing,
    selectIsOrganisationListShowing,
    selectIsZoneListShowing,
    selectIsZoneDetailsShowing,
    selectIsZoneShowing,
    selectSelectedCharacterId,
    selectIsSchedulerShowing,
    selectIsNameLocationShowingShowing,
    selectPanel,
    selectIsCharacterSelectShowing
} from '../../../redux/selectors';
import { editWritingSurfaceAsync } from '../../../redux/actions/writing-surface.actions';

import { connect } from 'react-redux'

import './Game.css';

export const config = {
    type: Phaser.WEBGL,
    pixelArt: true,
    scene: [GameScene],
    scale: {
        mode: Phaser.Scale.FIT,
        parent: 'main',
        width: 768,
        height: 600
    },
    physics: {
        default: 'arcade',
        arcade: {
            gravity: { y: 0 }
        }
    },
    audio: {
        disableWebAudio: false  // Ensure Web Audio is enabled
    }
};

class Game extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDragging: false,
            startPos: 0,
            mouseX: 0,
            initialSize: 0,
            showingSidebar: 'info',
            characterAction: 'dialogue',
            interval: undefined
        }

        window.onresize = () => {
            const canvas = window.document.getElementsByTagName('canvas')[0];
            this.setState({ initialSize: Number(canvas.style['width'].split('px')[0])})
        }

        window.onfocus = ((event) => {
            console.log('focus has been lost!')
        });

        window.onblur = ((event) => {
            console.log('focus has been lost!')
        });
    }

    componentDidMount() {
        this.game = new Phaser.Game(config);

        this.game.audioService = new AudioService();

        if (!this.state.initialSize) {
            const canvas = window.document.getElementsByTagName('canvas')[0];
            this.setState({ initialSize: Number(canvas.style['width'].split('px')[0])})
        }

        // TODO - calculate or find out when these happen and time them cleverly, rather than just recalculating all the time...
        // We don't need to do it every second right?!
        const interval = setInterval(() => {
            this.props.updateVisionRadius(new Date().getTime());
        }, 5000)

        this.setState({
            interval
        })
    }

    componentWillUnmount() {
        this.game.destroy()

        clearInterval(this.state.interval);
    }

    componentDidCatch(error, info) {
        console.error('Game.jsx error :', error, info);
        // showUnknownError();
    }


    hideErrorMessages() {
        store.dispatch(hideCraftingError());
        store.dispatch(hideMovingError());
        store.dispatch(hideUnknownError());

        if (this.props.isHelpShowing) {
            store.dispatch(hideHelp());
        }

        if (!this.props.isCraftingShowing && !this.props.isInventoryShowing && this.props.isUnknownErrorShowing) {
            store.dispatch(hideAllMenus());
        }
    }

    blurActiveElement() {
        document.activeElement.blur();
    }

    onSaveWritingSurface(text) {
        if (this.props.writingOnSurface.isLooking) {
            return;
        }

        if (this.props.writingOnSurface.item) {
            store.dispatch(createNewGraffitiItemAsync({
                ...this.props.writingOnSurface,
                selectedCharacterId: this.props.selectedCharacterId,
                text
            })).then(() => {
                setTimeout(() => {
                    store.dispatch(hideWritingSurface())
                    store.dispatch(hideAllMenus())
                })
            })
            return;
        }

        store.dispatch(createGraffitiAsync({
            ...this.props.writingOnSurface,
            text
        })).then(() => (store.dispatch(hideWritingSurface())))
    }

    onCloseWritingSurface() {
        // store.dispatch(hideWritingSurface())
    }

    handleChosenWritingType(option) {
        if (option.text === 'Writing') {
            setTimeout(() => {
                this.props.showWritingSurface({
                    surfaceService: this.props.writingOnSurface.surfaceService,
                    surfaceId: this.props.writingOnSurface.surfaceId,
                    isEditWritingSurfaceShowing: true,
                    isEditDrawingSurfaceShowing: false,
                    item: this.props.writingOnSurface.item
                })
            })
        }

        if (option.text === 'Drawing') {
            setTimeout(() => {
                this.props.showDrawingSurface({
                    surfaceService: this.props.writingOnSurface.surfaceService,
                    surfaceId: this.props.writingOnSurface.surfaceId,
                    isEditWritingSurfaceShowing: false,
                    isEditDrawingSurfaceShowing: true,
                    item: this.props.writingOnSurface.item
                })
            })
        }
    }

    onSaveDrawingSurface({ pixelsArray }) {
        if (!pixelsArray) {
            return;
        }

        if (this.props.writingOnSurface.item) {
            store.dispatch(createNewDrawingItemAsync({
                ...this.props.writingOnSurface,
                pixelsArray
            }))
            // .then(() => (store.dispatch(hideAllMenus())))
            return;
        }

        store.dispatch(createDrawingAsync({
            ...this.props.writingOnSurface,
            pixelsArray
        }))
    }

    onNewTownMessageSubmit(text) {
        if (text.toLowerCase() === '/logout') {
            localStorage['feathers-jwt'] = null;
            this.props.logOutAsync();
            window.location.reload();
            return;
        }

        if (text.toLowerCase() === '/discord') {
            window.open("https://discord.gg/NmXZFdWh");
            return;
        }

        if (text.toLowerCase() === '/wiki') {
            window.open("https://wiki.settle-gliese.com");
            return;
        }

        if (this.props.focussedCharacter?._id === this.props.character._id) {
            this.props.createDiaryEntryAsync({
                createdAt: new Date().getTime(),
                promptText: this.props.isPromptShowing?.text,
                text,
                characterId: this.props.character._id,
                subjectServiceName: this.props.isPromptShowing?.subjectServiceName,
                subjectId: this.props.isPromptShowing?.subjectId,
            })
            return;
        }

        this.props.createMessageAsync({
            text,
            targetCharacterId: this.props.focussedCharacter?._id,
            targetCharacterName: this.props.focussedCharacter?.name,
            characterName: this.props.character.name
        }).then(() => (store.dispatch(hideAllMenus())));
    }

    onNameLocationSubmit(text) {
        if (this.props.panel.characterPanel) {
            store.dispatch(updateCharacterPanelAsync({
                _id: this.props.panel.characterPanel._id,
                locationName: text
            }))
        } else {
            store.dispatch(addCharacterPanelAsync({
                locationName: text,
                characterId: this.props.character._id,
                panelId: this.props.character.panelId
            }));
        }

        store.dispatch(hideAllMenus())
    }
    
    onEditExit() {
        store.dispatch(hideAllMenus())
    }

    onSelectItemsClosed() {
        store.dispatch(hideSelectItems())
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.isInventoryShowing) {
            state = {
                ...state,
                selectedItem: undefined
            }
        }

        if (!props.isCharacterActionsShowing) {
            state = {
                ...state,
                characterAction: 'dialogue'
            }
        }

        return state
    }

    render() {
        let gameScreen;

        if (!this.props.isInventoryShowing && !this.props.isCraftingShowing && !this.props.isMessagesShowing) {
            gameScreen = (
                <div className="main-bar">
                    <Character />
                    <Location />
                    <div className={this.state.showingSidebar === 'info' ? "info-sidebar" : "info-sidebar--hidden"}>
                    </div>
                    <SidebarMessages />
                </div>
            );
        }

        if (this.props.isInventoryShowing) {
            gameScreen = (
                <div className="inventory-actions">
                    <div className="top-ting">
                        <InventoryActions onSelectedItem={(selectedItem) => (this.setState({ selectedItem }))}/>
                        <TileActions onSelectedItem={(selectedItem) => (this.setState({ selectedItem }))}/>
                    </div>
                    <ItemDescription item={this.state.selectedItem} />
                </div>
            )
        }

        if (this.props.isSchedulerShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Scheduled Tasks</span> ]&nbsp;</p>
                    <div>
                        <Scheduler />
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.closeCrafting())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.isCraftingShowing) {
            gameScreen = (
                <Crafting />
            )
        }

        if (this.props.isMessagesShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="messages-title">&nbsp;[ <span className="title">Messages</span> ]&nbsp;</p>
                    <div>

                        <Messages />
                        <div className="crafting-close-button"
                            onClick={() => (this.props.closeCrafting())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if(this.props.isHelpShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Help</span> ]&nbsp;</p>
                    <div>
                        <Help />
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.isTableShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Table Dialogue</span> ]&nbsp;</p>
                    <div>
                        <Dialogue/>
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.isNewOrganisationShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Organisation</span> ]&nbsp;</p>
                    <div>
                        <Organisation/>
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.isOrganisationListShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Organisation List</span> ]&nbsp;</p>
                    <div>
                        <OrganisationList/>
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.isZoneListShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Zone List</span> ]&nbsp;</p>
                    <div>
                        <ZoneList/>
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.isZoneShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Zone Detail</span> ]&nbsp;</p>
                    <div>
                        <Zone/>
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if(this.props.isCharacterActionsShowing) {
            gameScreen = (
                this.state.characterAction === 'dialogue' ? (
                    <div className="full-messages-container">
                        <p className="dialogue-title">&nbsp;[ <span className="title">{this.props.focussedCharacter.name}'s Dialogue</span> ]&nbsp;</p>
                        <div>
                            <Dialogue
                                showLeft={() => (this.setState({ characterAction: 'characterSheet' }))}
                                showRight={() => (this.setState({ characterAction: 'conversationTree' }))}
                                leftString={'Character Profile'}
                                rightString={'Conversation Tree'}
                            />
                            <div className="dialogue-close-button"
                                onClick={() => (this.props.hideAllMenus())}>
                                <span className="shortcut">ESC</span> to exit
                            </div>
                        </div>
                    </div>
                ) : this.state.characterAction === 'conversationTree' ? (
                    <div className="full-messages-container">
                        <p className="dialogue-title">&nbsp;[ <span className="title">{this.props.focussedCharacter.name}'s Conversation Tree</span> ]&nbsp;</p>
                        <div>
                            <ConversationTree
                                showLeft={() => (this.setState({ characterAction: 'dialogue' }))}
                                showRight={() => (this.setState({ characterAction: 'trade' }))}
                                leftString={'Dialogue'}
                                rightString={'Trade Inventory'}
                            />
                            <div className="dialogue-close-button"
                                onClick={() => (this.props.hideAllMenus())}>
                                <span className="shortcut">ESC</span> to exit
                            </div>
                        </div>
                    </div>
                ) : this.state.characterAction === 'trade' ? (
                    <div className="full-messages-container">
                        <p className="dialogue-title"></p>
                        <div>
                            <Trade
                                showLeft={() => (this.setState({ characterAction: 'conversationTree' }))}
                                showRight={() => (this.setState({ characterAction: 'characterSheet' }))}
                                leftString={'Convesation Tree'}
                                rightString={'Character Sheet'}
                            />
                            <div className="dialogue-close-button"
                                onClick={() => (this.props.hideAllMenus())}>
                                <span className="shortcut">ESC</span> to exit
                            </div>
                        </div>
                    </div>
                ) : this.state.characterAction === 'characterSheet' ? (
                    <div className="full-messages-container">
                        <p className="dialogue-title">&nbsp;[ <span className="title">{this.props.focussedCharacter.name}'s Character Profile</span> ]&nbsp;</p>
                        <div>
                            <Profile
                                showLeft={() => (this.setState({ characterAction: 'trade' }))}
                                showRight={() => (this.setState({ characterAction: 'dialogue' }))}
                                leftString={'Trade Inventory'}
                                rightString={'Dialogue'}
                            />
                            <div className="dialogue-close-button"
                                onClick={() => (this.props.hideAllMenus())}>
                                <span className="shortcut">ESC</span> to exit
                            </div>
                        </div>
                    </div>
                ) : ('')
            )
        }

        if(this.props.isCharacterProfileShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Profile</span> ]&nbsp;</p>
                    <div>
                        <Profile />
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if(this.props.isEquipmentShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Equipment</span> ]&nbsp;</p>
                    <div>
                        <Equipment />
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if(this.props.isSheetShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Character Sheet</span> ]&nbsp;</p>
                    <div>
                        <Sheet />
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.isWorkshopSelectShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Select Workshop</span> ]&nbsp;</p>
                    <div>
                        <WorkshopSelect />
                        
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if(this.props.isWorkshopShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">{ this.props.workshopType ? this.props.workshopType.name : 'Building'} Workshop</span> ]&nbsp;</p>
                    <div>
                        <Workshop />
                        
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if(this.props.isConversationTreeWizardShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Character Responses</span> ]&nbsp;</p>
                    <div>
                        <ConversationTreeWizard />
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if(this.props.isEventsShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Events</span> ]&nbsp;</p>
                    <div className="events">
                        <Events />
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if(this.props.isDiaryShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Diary</span> ]&nbsp;</p>
                    <div className="events">
                        <Diary />
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.writingOnSurface?.isEditWritingSurfaceShowing && !this.props.writingOnSurface.isLooking) {
            const defaultPaints = [
                {
                    materialType: {
                        colourHex: 'var(--white-darker)',
                        shortcut: 'd'
                    }
                }
            ]

            const SMALLEST_WIDTH = 22;
            const SMALLEST_HEIGHT = 6;

            const writingSurfaceWidth = this.props.writingSurface?.width || SMALLEST_WIDTH;
            const writingSurfaceHeight = this.props.writingSurface?.height || SMALLEST_HEIGHT;

            gameScreen = (
                <div className="full-messages-container fuller-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">{this.props.writingOnSurface?.surfaceService === 'writing-surface-instances' ? 'Writing' : 'Decorating' }</span> ]&nbsp;</p>
                    <div>
                        <EditWritingSurface
                            text={this.props.writingSurface?.graffiti ? this.props.writingSurface?.graffiti : [{
                              type: 'paragraph',
                              children: [{ text: '' }],
                            }]}
                            maxLength={writingSurfaceWidth * writingSurfaceHeight}
                            width={`${writingSurfaceWidth}.1ch`}
                            height={`calc(${writingSurfaceHeight} * 2.1ch)`}
                            paints={this.props.paints.length > 0 ? this.props.paints : defaultPaints}
                            isReadOnly={this.props.writingOnSurface.isLooking}
                            onSave={this.onSaveWritingSurface.bind(this)}
                            onClose={this.onCloseWritingSurface.bind(this)}
                        />
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.writingOnSurface?.isEditWritingSurfaceShowing && this.props.writingOnSurface.isLooking) {
            const SMALLEST_WIDTH = 22;
            const SMALLEST_HEIGHT = 6;

            const writingSurfaceWidth = this.props.writingSurface?.width || SMALLEST_WIDTH;
            const writingSurfaceHeight = this.props.writingSurface?.height || SMALLEST_HEIGHT;

            gameScreen = (
                <div className="full-messages-container fuller-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">{this.props.writingOnSurface?.surfaceService === 'writing-surface-instances' ? 'Reading' : 'Looking' }</span> ]&nbsp;</p>
                    <div className="read-writing-surface">
                        <ReadWritingSurface
                            writingSurface={this.props.writingSurface}
                            maxLength={writingSurfaceWidth * writingSurfaceHeight}
                            width={`${writingSurfaceWidth}.1ch`}
                            height={`calc(${writingSurfaceHeight} * 2.1ch)`}
                        />
                        <div className="dialogue-close-button"
                            onClick={() => (this.props.hideAllMenus())}>
                            <span className="shortcut">ESC</span> to exit
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.writingOnSurface?.isEditDrawingSurfaceShowing) {

            const SMALLEST_WIDTH = 22;
            const SMALLEST_HEIGHT = 6;

            const writingSurfaceWidth = this.props.writingSurface?.width || SMALLEST_WIDTH;
            const writingSurfaceHeight = (this.props.writingSurface?.height || SMALLEST_HEIGHT) * 2;

            gameScreen = (
                <div className="full-messages-container fuller-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">{this.props.writingOnSurface?.surfaceService === 'writing-surface-instances' ? 'Writing' : 'Decorating' }</span> ]&nbsp;</p>
                    <div>
                        <EditDrawingSurface
                            width={writingSurfaceWidth}
                            height={writingSurfaceHeight}
                            paints={this.props.paints}
                            pixelsArray={this.props.writingSurface?.pixelsArray}
                            onSave={this.onSaveDrawingSurface.bind(this)}
                            // onClose={() => (this.props.hideAllMenus())}
                            isReadOnly={this.props.writingOnSurface?.isLooking}
                            mask={this.props.writingOnSurface?.item?.type?.name}
                        />
                    </div>
                </div>
            )
        }

        if (this.props.isTerminalShowing) {
            gameScreen = (
                <GroupTerminal />
            )
        }

        if (this.props.isGuardHouseShowing) {
            gameScreen = (
                <div className="full-messages-container">
                    <p className="dialogue-title">&nbsp;[ <span className="title">Guard House</span> ]&nbsp;</p>
                    <GuardHouse />
                    <div className="crafting-close-button"
                        onClick={() => (this.props.closeCrafting())}>
                        <span className="shortcut">ESC</span> to exit
                    </div>
                </div>
            )
        }

        if (this.props.isCharacterDeathPageShowing) {
            gameScreen = (
                <CharacterDeathPage character={this.props.character}/>
            )
        }

        if (this.props.isCharacterSelectShowing) {
            gameScreen = (
                <div>
                    <Startup />
                </div>
            )
        }

        const isWritingSurfaceTypeMenuShowing = !this.props.writingOnSurface?.isEditDrawingSurfaceShowing && !this.props.writingOnSurface?.isEditWritingSurfaceShowing && this.props.writingOnSurface.surfaceService;

        return (
            <div className="container" onClick={() => this.hideErrorMessages()}>
                <div id="main" className={(
                    this.props.isInventoryShowing ||
                    this.props.isCraftingShowing ||
                    this.props.isMessagesShowing ||
                    this.props.isHelpShowing ||
                    this.props.isCharacterActionsShowing ||
                    this.props.isCharacterProfileShowing ||
                    this.props.isEquipmentShowing ||
                    this.props.isSheetShowing ||
                    this.props.isWorkshopShowing ||
                    this.props.isWorkshopSelectShowing ||
                    this.props.isConversationTreeWizardShowing ||
                    this.props.isEventsShowing ||
                    this.props.isDiaryShowing ||
                    this.props.writingOnSurface?.isEditWritingSurfaceShowing ||
                    this.props.writingOnSurface?.isEditDrawingSurfaceShowing ||
                    this.props.isTerminalShowing ||
                    this.props.isGuardHouseShowing || 
                    this.props.isCharacterDeathPageShowing ||
                    this.props.isTableShowing ||
                    this.props.isNewOrganisationShowing ||
                    this.props.isOrganisationListShowing ||
                    this.props.isZoneListShowing || 
                    this.props.isZoneShowing ||
                    this.props.isSchedulerShowing ||
                    this.props.isCharacterSelectShowing
                ) ? 'hidden' : ''}
                    onMouseDown={event => {
                      event.preventDefault()
                      if (document.getElementsByClassName('surface')[0]) {
                        document.getElementsByClassName('surface')[0].focus();
                      }
                    }}
                >
                    {gameScreen}
                </div>
                <div id="prompt" className={this.props.isPromptShowing ? '' : 'hidden'}>
                    <Prompt />
                </div>
                <div id="looking" className={this.props.isLooking ? '' : 'hidden'}>
                    <Looking />
                </div>
                <div id="on-screen-dialogue">
                    <OnScreenDialogue />
                </div>
                <div id="actions" className={this.props.isActionsShowing ? '' : 'hidden'}>
                    <Actions />
                </div>
                <div id="error" className={this.props.isNoCharactersNearbyErrorShowing ? '' : 'hidden'}>
                    <NoCharactersNearbyError />
                </div>
                <div id="error" className={this.props.isCraftingErrorShowing ? '' : 'hidden'}>
                    <CraftingError />
                </div>
                <div id="error" className={this.props.isMovingErrorShowing ? '' : 'hidden'}>
                    <MovingError />
                </div>
                <div id="error" className={this.props.isDirectionInputShowing ? '' : 'hidden'}>
                    <DirectionInput text={"Side of the door which is 'outside':"}/>
                </div>
                <div id="error" className={this.props.isUnknownErrorShowing ? '' : 'hidden'}>
                    <UnknownError errorMessage={this.props.errorMessage} />
                </div>

                {this.props.isActionSelectShowing ? (
                    <div id="error">
                        <div className="select-items">
                            <p className="conversation-wizard-exit"><span className="action">ESC</span> to exit</p>
                            <p className="select-items-title">Select an action</p>
                            <Menu options={this.props.isActionSelectShowing} optionChosen={(option) => {
                                option.action()
                                setTimeout(() => (this.props.hideActionSelectMenu()));
                            }}/>
                        </div>
                    </div>
                ) : ('')}

                {this.props.isSelectItemsShowing ? (
                    <div id="error">
                        <SelectItems onSelectItemsClosed={this.onSelectItemsClosed.bind(this)} />
                    </div>
                ): ('')}

                {this.props.isNearDeathScreenShowing ? (
                    <div  id="error" className="near-death-screen">
                        <div className="character-death-screen">
                            <p>Gosh, it appears you are in the near-death state. In this state, you will be unable to move or take any actions, but you will be able to talk.</p>
                            <p>A doctor can heal you from this state at a Doctor's Workshop.</p>
                            <p>If you would like to give up and start anew, press <span className="action">SHIFT</span> + <span className="action">g</span> to walk into the light</p>
                            <p className="hint">OOC: If you do this your current character will die and you won't be able to view or play as them</p>
                        </div>
                    </div>
                ) : ('')}

                {this.props.isNewTownMessageShowing ? (
                    <div id="error" className={this.props.isNewTownMessageShowing ? 'new-message-container' : 'hidden'}>
                        <p className="conversation-wizard-exit"><span className="action">ESC</span> to exit</p>
                        {this.props.focussedCharacter?._id ? (
                            <div className="talk-to-character-in-world"><span>{this.props.focussedCharacter.name}</span></div>
                        ) : ('')}
                        <TextInput onTextSubmit={this.onNewTownMessageSubmit.bind(this)} text={''} exitKey="Escape" onExitKey={this.onEditExit.bind(this)} />
                    </div>
                ) : ('')}

                {this.props.isNameLocationShowing && (
                    <div id="test-words-go-her error" className={'new-message-container'}>
                        <p className="conversation-wizard-exit"><span className="action">ESC</span> to exit</p>
                        <div className="talk-to-character-in-world"><span>Enter Location Name</span></div>
                        <TextInput onTextSubmit={this.onNameLocationSubmit.bind(this)} text={''} exitKey="Escape" onExitKey={this.onEditExit.bind(this)} />
                    </div>
                )}

                <div className={this.props.connectionState === 'CONNECTED' ? "hidden" : "connection-state"}>{this.props.connectionState}</div>

                <div id="error" className={this.props.isLevelUpMessageShowing ? 'level-up-container' : 'hidden'}>
                    <LevelUp />
                </div>

                {
                isWritingSurfaceTypeMenuShowing ? (
                    <div id="error" className={isWritingSurfaceTypeMenuShowing ? "writing-type-selection" : "hidden"}>
                        <p className="dialogue-title">&nbsp;[ <span className="title">Choose decoration type</span> ]&nbsp;</p>
                        <Menu
                            options={[
                                { text: 'Writing' },
                                { text: 'Drawing' },
                            ]}

                            keyPressed={(option) => this.handleChosenWritingType(option)}
                        />
                    </div>
                ) : ('')
                }
            </div>
        );
    }
}

const mapToStateProps = state => {
    const character = selectCharacter(state);
    const panel = selectPanel(state);
    const isInventoryShowing = selectIsInventoryShowing(state);
    const isCraftingShowing = selectIsCraftingShowing(state);
    const isActionsShowing = selectIsActionsShowing(state);
    const isHelpShowing = selectIsHelpShowing(state);
    const isNoCharactersNearbyErrorShowing = selectIsNoCharactersNearbyErrorShowing(state);
    const isCraftingErrorShowing = selectIsCraftingErrorShowing(state);
    const isMovingErrorShowing = selectIsMovingErrorShowing(state);
    const isUnknownErrorShowing = selectIsUnknownErrorShowing(state);
    const errorMessage = selectErrorMessage(state);
    const isMessagesShowing = selectIsMessagesShowing(state);
    const isCharacterActionsShowing = selectIsCharacterActionsShowing(state);
    const isCharacterProfileShowing = selectIsCharacterProfileShowing(state);
    const isEquipmentShowing = selectIsEquipmentShowing(state);
    const isSheetShowing = selectIsSheetShowing(state);
    const isWorkshopShowing = selectIsWorkshopShowing(state);
    const isWorkshopSelectShowing = selectIsWorkshopSelectShowing(state);
    const isConversationTreeWizardShowing = selectIsConversationTreeWizardShowing(state);
    const isEventsShowing = selectIsEventsShowing(state);
    const isDiaryShowing = selectIsDiaryShowing(state);
    const isDirectionInputShowing = selectIsDirectionInputShowing(state);
    const isLooking = selectHighlight(state);
    const isLevelUpMessageShowing = selectIsLevelUpMessageShowing(state);
    const isTerminalShowing = selectIsTerminalShowing(state);
    const isGuardHouseShowing = selectIsGuardHouseShowing(state);
    const isNewTownMessageShowing = selectIsNewTownMessageShowing(state);
    const isSelectItemsShowing = selectIsSelectItemsShowing(state);
    const isNearDeathScreenShowing = selectIsNearDeathScreenShowing(state);
    const writingOnSurface = selectIsWritingOnSurface(state);
    const currentWorkshop = selectWorkshopByPosition(state, character.position);
    const isTableShowing = selectIsTableShowing(state);
    const isActionSelectShowing = selectIsActionSelectShowing(state);
    const isNewOrganisationShowing = selectIsNewOrganisationShowing(state);
    const isOrganisationListShowing = selectIsOrganisationListShowing(state);
    const isZoneListShowing = selectIsZoneListShowing(state);
    const isZoneShowing = selectIsZoneShowing(state);
    const selectedCharacterId = selectSelectedCharacterId(state);
    const isSchedulerShowing = selectIsSchedulerShowing(state);
    const isNameLocationShowing = selectIsNameLocationShowingShowing(state);
    const isCharacterSelectShowing = selectIsCharacterSelectShowing(state);

    const isPromptShowing = (selectCurrentPrompt(state) && !(
        isInventoryShowing ||
        isCraftingShowing ||
        isMessagesShowing ||
        isHelpShowing ||
        isCharacterActionsShowing ||
        isCharacterProfileShowing ||
        isEquipmentShowing ||
        isSheetShowing ||
        isWorkshopShowing ||
        isWorkshopSelectShowing ||
        isConversationTreeWizardShowing ||
        isEventsShowing ||
        writingOnSurface?.isEditWritingSurfaceShowing ||
        writingOnSurface?.isEditDrawingSurfaceShowing ||
        isTerminalShowing ||
        isGuardHouseShowing || 
        isCharacterDeathPageShowing ||
        isTableShowing ||
        isNameLocationShowing
    )) ? selectCurrentPrompt(state) : undefined;

    let writingSurface;
    if (writingOnSurface.surfaceService) {
        writingSurface = selectWritingSurfaceById(state, writingOnSurface);
    }

    const paints = selectPaints(state);

    const focussedCharacterId = selectFocussedCharacterId(state);
    const focussedCharacter = selectCharacterById(state, focussedCharacterId)

    const workshopTypeId = selectWorkshopTypeId(state);
    let workshopType;

    if (workshopTypeId) {
        workshopType = selectWorkshopTypeById(state, workshopTypeId);
    }

    const isCharacterDeathPageShowing = selectIsCharacterDeathPageShowing(state);

    const connectionState = selectConnectionState(state);

    return {
        character,
        panel,
        isInventoryShowing,
        isCraftingShowing,
        isActionsShowing,
        isHelpShowing,
        isNoCharactersNearbyErrorShowing,
        isCraftingErrorShowing,
        isMovingErrorShowing,
        isUnknownErrorShowing,
        errorMessage,
        isMessagesShowing,
        isCharacterActionsShowing,
        isCharacterProfileShowing,
        isEquipmentShowing,
        isSheetShowing,
        focussedCharacter,
        isWorkshopShowing,
        isWorkshopSelectShowing,
        isConversationTreeWizardShowing,
        isEventsShowing,
        isDiaryShowing,
        isDirectionInputShowing,
        workshopType,
        isLooking,
        isLevelUpMessageShowing,
        isTerminalShowing,
        isGuardHouseShowing,
        writingOnSurface,
        writingSurface,
        paints,
        currentWorkshop,
        isNewTownMessageShowing,
        isSelectItemsShowing,
        isCharacterDeathPageShowing,
        hideWritingSurface,
        isTableShowing,
        isActionSelectShowing,
        connectionState,
        isNearDeathScreenShowing,
        isPromptShowing,
        isNewOrganisationShowing,
        isOrganisationListShowing,
        isZoneListShowing,
        isZoneShowing,
        selectedCharacterId,
        isSchedulerShowing,
        isNameLocationShowing,
        isCharacterSelectShowing
    }
}

export default connect(
    mapToStateProps,
    {
        logOutAsync,
        updateVisionRadius,
        hideAllMenus,
        createMessageAsync,
        createDrawingAsync,
        createGraffitiAsync,
        showWritingSurface,
        showDrawingSurface,
        hideActionSelectMenu,
        createDiaryEntryAsync
    }
)(Game);