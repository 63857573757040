export function getTentBoundries(position, tentDirection, tentType) {
	const currentPosition = { ...position }

    if (!tentType || !tentType.boundries) {
        return [];
    }

    const oldBoundries = [];

    if (typeof tentType.boundries === 'string') {
        tentType.boundries = JSON.parse(tentType.boundries);
    }

    tentType.boundries.forEach(({x, y, tileKey, type}) => {
        oldBoundries.push({
            x,
            y,
            tileKey,
            type
        })
    })

    let largestBoundryX = 0;
    let largestBoundryY = 0;

    oldBoundries.forEach(boundry => {
        if (boundry.x > largestBoundryX) {
            largestBoundryX = boundry.x
        }

        if (boundry.y > largestBoundryY) {
            largestBoundryY = boundry.y
        }
    })

    const newBoundries = oldBoundries.map(boundry => {

        if (tentDirection === 'left') {
            boundry.x = boundry.x - (1 + largestBoundryX);
        }

        if (tentDirection === 'down') {
            boundry.x = boundry.x - Math.round(largestBoundryX / 2);
            boundry.y = boundry.y + largestBoundryY + 1;
        }

        if (tentDirection === 'up') {
            boundry.x = boundry.x - Math.round(largestBoundryX / 2);
            boundry.y = boundry.y - largestBoundryY - 1;
        }

        return {
            x: currentPosition.x + boundry.x,
            y: currentPosition.y + boundry.y,
            tileKey: boundry.tileKey,
            type: boundry.type
        }
    })

    return newBoundries;
}