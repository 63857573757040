import {
    CREATE_WRITING_SURFACE_LOADING,
    CREATE_WRITING_SURFACE_SUCCESS,
    CREATE_WRITING_SURFACE_FAILURE,
    GET_WRITING_SURFACES_LOADING,
    GET_WRITING_SURFACES_SUCCESS,
    GET_WRITING_SURFACES_FAILURE,
    ADD_WRITING_SURFACE,
    UPDATE_WRITING_SURFACE,
    REMOVE_WRITING_SURFACE,
    GET_TRADE_WRITING_SURFACES_SUCCESS,
    GET_TRADE_WRITING_SURFACES_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createWritingSurfaceSuccess = payload => ({
    type: CREATE_WRITING_SURFACE_SUCCESS,
    payload
})

export const createWritingSurfaceFailure = () => ({
    type: CREATE_WRITING_SURFACE_FAILURE
})

export const createWritingSurfaceAsync = payload => dispatch => {
    const { _id, writingSurfaceTypeId, selectedCharacterId, quantity, workshopId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'writing-surface-instances',
        name: 'create',
        data: {
            writingSurfaceTypeId,
            selectedCharacterId,
            quantity,
            workshopId
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getWritingSurfacesSuccess = payload => {
    return ({
        type: GET_WRITING_SURFACES_SUCCESS,
        payload
    })
}

export const getWritingSurfacesFailure = () => ({
    type: GET_WRITING_SURFACES_FAILURE
})

export const getWritingSurfacesAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('writing-surface-instances').find({ query })
        .then((response) => {
            response = response.map(writingSurface => ({ ...writingSurface, writingSurfaceType: payload.writingSurfaceTypes.find(type => type._id === writingSurface.writingSurfaceTypeId) }))
            dispatch(getWritingSurfacesSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const getTradeWritingSurfacesSuccess = payload => {
    return ({
        type: GET_TRADE_WRITING_SURFACES_SUCCESS,
        payload
    })
}

export const getTradeWritingSurfacesFailure = () => ({
    type: GET_TRADE_WRITING_SURFACES_FAILURE
})

export const getTradeWritingSurfacesAsync = payload => dispatch => {
    return client.service('writing-surface-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(writingSurface => ({ ...writingSurface, writingSurfaceType: payload.writingSurfaceTypes.find(type => type._id === writingSurface.writingSurfaceTypeId) }))
            dispatch(getTradeWritingSurfacesSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addWritingSurfaceToTileAsync = payload => dispatch => {
    const { writingSurfaceInstance, tileId, tileTypeId } = payload;
    const { writingSurfaceTypeId, quantity, recipeId, characterId, _id, text, graffiti, pixelsArray } = writingSurfaceInstance;

    colyClient.room.send('doAction', {
        serviceName: 'writing-surface-instances',
        commandType: 'create',
        data: {
            writingSurfaceTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: "",
            text,
            graffiti,
            pixelsArray
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWritingSurfaceToCharacterAsync = payload => dispatch => {
    const { writingSurfaceInstances, characterId } = payload;
    const { createdAt, writingSurfaceTypeId, quantity, recipeId, _id, text, graffiti, pixelsArray } = writingSurfaceInstances[0];

    colyClient.room.send('doSuperAction', {
        serviceName: 'writing-surface-instances',
        name: 'fetch',
        data: {
            writingSurfaceTypeId,
            quantity,
            tileId: "",
            characterId,
            text,
            graffiti,
            pixelsArray,
            instanceIds: writingSurfaceInstances.map(inst => inst._id)
        },
        params: {
            query: {
                movementFrom: {
                    _id: undefined
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWritingSurface = payload => {
    return ({
        type: ADD_WRITING_SURFACE,
        payload
    })
}

export const updateWritingSurface = payload => {
    return ({
        type: UPDATE_WRITING_SURFACE,
        payload
    })
}

export const removeWritingSurface = payload => {
    return ({
        type: REMOVE_WRITING_SURFACE,
        payload
    })
}

export const copyWritingSurfaceAsync = payload => dispatch => {
    const { writingSurfaceInstance, characterId } = payload;
    const { writingSurfaceTypeId, _id, graffiti } = writingSurfaceInstance;

    colyClient.room.send('doAction', {
        serviceName: 'writing-surface-instances',
        commandType: 'create',
        data: {
            writingSurfaceTypeId,
            graffiti
        },
    })

    return new Promise((resolve, reject) => (resolve()))
}