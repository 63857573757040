import store from '../redux/store';
import * as bres from 'bresenham-line-algorithm';
import seedrandom from 'seedrandom';

import { Map } from 'rot-js';
import { createMazeFn } from './maze'

import { adaptiveWait } from './Panel-initialisation.service';

import {
    selectAnimals,
    selectVisionRadius,
    selectCharacter,
    selectPlants,
    selectCharacters,
    selectDeadCharacters,
    selectWorkshopTypeById,
    selectWorkshops,
    selectForegroundConstructions,
    selectWagons,
    selectBoats,
    selectItemTileIcons,
    selectFurniture,
    selectStairs,
    selectMineWalls,
    selectMinerals,
    selectCurrentTool,
    selectServerTimeOffset,
    selectBrainchipTypeByIds,
    selectBrainchipTypes,
    selectPanel,
    selectCharacterWorkshopBoundries,
    selectBackgroundConstructions,
    selectIsInitialisingApp,
    selectIsInitialisingPanel,
    selectItemTileIconsForTile,
    selectAnimalsByPosition,
} from '../redux/selectors';
import {
    WORLD_WIDTH,
    WORLD_HEIGHT,
    drawTundraClouds,
    drawMistyTundra,
    drawInglenook,
    drawWoneyed,
    drawMountains,
    drawSands,
    drawWastes,
    drawGumboRumblings,
    drawPricklelands,
    drawHeaths,
    drawSteppes,
    drawDots,
    drawTundra,
    drawTundraFern,
    drawTundraThorn,
    drawPits,
    drawPitsAndPlants,
    drawNeedlewoodForest,
    drawIcyWastes,
    drawMazeScrub,
    drawPolarWastes,
    drawPrimeordeal,
    drawPuzzlebox,
    drawThistlewoodForest,
    drawSlabs,
    drawNeighbours,
    getNeighbours,
    getDiagonalNeighbours,
    isEmptyIndex,
    drawCoolTemperateDesertRiver,
    drawWarmTemperateDesertRiver,
    drawSubtropicalDesertRiver,
    drawTropicalDesertRiver,
    drawPainForest,
    drawFurForest,
    drawEvergreenForest,
    drawCrunchyForest,
    drawCrystalisedForest,
    drawDeepestJungle,
    drawDiscordantForest,
    drawFoetalRainforest,
    drawFossilisedForest,
    drawLusciousRainforest,
    drawOldGrowth,
    drawOvergrownFur,
    drawPrimeval,
    drawSwampyForest,
    drawHighSeas,
    drawSlimes,
    drawRuins,
    drawAlienCity,
    drawHamlet,
    drawMansion
} from './biome-geography';
import { BALANCE } from './balance-config';
import { differenceInCalendarDays } from 'date-fns';
import tileMap from './tile-map';
import { PANEL_WIDTH, PANEL_HEIGHT, randomNumberBetween } from './geography'
import { drawFieldOfView, isWithinUserVision } from './night-radius';
import { getTentBoundries } from './tent-helpers';

import { calculateLightSources, BRAINCHIP_TYPE_NAMES } from './Tilemap.service';

import client from './client';

const TILE_WIDTH = 16;
const TILE_HEIGHT = 24;

const movementTiles = [tileMap[`MOVING_1`], tileMap[`MOVING_2`], tileMap[`MOVING_3`], tileMap[`MOVING_4`]]
const teleportingTiles = [tileMap[`TELEPORTING_1`],tileMap[`TELEPORTING_2`], tileMap[`TELEPORTING_3`], tileMap[`TELEPORTING_4`],tileMap[`TELEPORTING_5`], tileMap[`TELEPORTING_6`], tileMap[`TELEPORTING_7`], tileMap[`TELEPORTING_8`], tileMap[`TELEPORTING_9`]];

function getMockLayer() {
    const mockLayerData = [];

    for (var y=0; y < PANEL_HEIGHT; y++) {
        mockLayerData[y] = []
        for (var x=0; x < PANEL_WIDTH; x++) {
            mockLayerData[y][x] = []
        }
    }

    return mockLayerData;
}

const groupBiomes = {
    0: 'DESERT',
    1: 'SWAMP',
    2: 'TAIGA',
    3: 'JUNGLE',
    4: 'ARID',
    5: 'OLD-GROWTH',
    6: 'RIVER',
    7: 'POLAR',
}

const grassyBiomes = [
    'BOREAL_MOIST_FOREST',
    'BOREAL_WET_FOREST',
    'BOREAL_RAIN_FOREST',
    'COOL_TEMPERATE_MOIST_FOREST',
    'COOL_TEMPERATE_WET_FOREST',
    'COOL_TEMPERATE_RAIN_FOREST',
    'COOL_TEMPERATE_DESERT_SCRUB',
    'WARM_TEMPERATE_MOIST_FOREST',
    'WARM_TEMPERATE_WET_FOREST',
    'WARM_TEMPERATE_RAIN_FOREST',
    'WARM_TEMPERATE_THORN_SCRUB',
    'WARM_TEMPERATE_DESERT_SCRUB',
    'SUBTROPICAL_DRY_FOREST',
    'SUBTROPICAL_MOIST_FOREST',
    'SUBTROPICAL_WET_FOREST',
    'SUBTROPICAL_RAIN_FOREST',
    'TROPICAL_DRY_FOREST',
    'TROPICAL_VERY_DRY_FOREST',
    'TROPCIAL_MOIST_FOREST',
    'TROPICAL_WET_FOREST',
    'TROPICAL_RAIN_FOREST',
]

const coloursMap = {
    'var(--white-darker)': 0xb4b3d1,
    'var(--sg-teal)':  0x468692,
    'var(--sg-yellow)':  0xf2c359,
    'var(--sg-pale-pink)':  0xe9a4b7,
    'var(--sg-blue)':  0x2a3d62,
    'var(--sg-pale-green)':  0x4da781,
    'var(--sg-white)':  0xf3f2e0,
    'var(--sg-dark-green)':  0x1a3f38,
    'var(--sg-pink)':  0xd4799c,
    'var(--sg-pale-yellow)':  0xfffbae,
    'var(--sg-pale-blue)': 0xb0ecf6,
    'var(--sg-sunflow-orange)':  0xffa300,
    'var(--sg-mauve)':  0x7e2553,
    'var(--sg-slate)':  0x676172,
    'var(--sg-grey)':  0xc2c3c7,
    'var(--sg-sky-blue)':  0x29adff,
    'var(--sg-green)':  0x008751,
    'var(--sg-orange)':  0xf27f41,
    'var(--sg-purple)':  0x4a5ba0,
    'var(--sg-red)':  0xa0240b,
    'var(--sg-brown)':  0x936451,
}

export default class ForegroundTilemapService {
    foregroundLayer;
    tilemapService;
    backgroundTilemapService;

    storeSubscription;
    layer;
    character;
    visionRadius;

    deadCharacters;
    animals = [];
    plants = [];
    characters = [];
    deadCharacters = [];
    character;
    workshops = [];
    constructions = [];
    wagons = [];
    boats = [];
    iconTiles = [];
    furniture = [];
    stairs = [];
    minerals = [];
    brainchipTypes = [];
    currentActiveBrainchips = [];
    currentTool = {}
    panel = {};
    maze;
    intervals = [];
    backgroundConstructions = []
    memoryTiles = []
    isInitialising = true;
    visibleTiles = [];

    floorLayerLength = 0;

    copyInterval;

    timeTeleportationBegan;

    characterSprites = [];
    itemDictionary = {};
    multiItemTiles = [];
    multiItemTileIndex = 0;

    constructor(foregroundLayer, scene, cloudLayer, isCutscene, backgroundTilemapService, tilemapService) {
        this.scene = scene;
        this.foregroundLayer = foregroundLayer;
        this.cloudLayer = cloudLayer;
        this.layer = getMockLayer();
        this.isCutscene = isCutscene;
        this.tilemapService = tilemapService
        this.backgroundTilemapService = backgroundTilemapService;
        this.character = selectCharacter(store.getState());

        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))

        setInterval(() => {
            this.multiItemTileIndex++;

            // If the number gets too high we just set it back to zero so things don't get too crazy :) 
            if (this.multiItemTileIndex > 1000) {
                this.multiItemTileIndex = 0
            }

            this.multiItemTiles.forEach(tile => {
                if (this.visibleTiles.find(visibleTile => (visibleTile.x === tile.x && visibleTile.y === tile.y))) {
                    this.renderTile(this.layer[tile.y][tile.x], tile.y, tile.x, rememberedTiles)
                }
            })

            if (this.multiItemTiles && this.panel.biome === 'TROPICAL_VERY_DRY_FOREST' || this.panel.biome === 'TROPICAL_DRY_FOREST') {
                const state = store.getState();
                const updatedCharacters = state.characters.characters;

                this.animateWaterDroplets(this.characters, updatedCharacters);
                this.characters = updatedCharacters;
            }

            this.addToMemory(this.character?.panelId, this.character?.z, this.memoryTiles)
        }, 400)
    }

    shadeTiles(updatedCharacter, state, visibleTiles) {
        if (!state) {
            state = store.getState();
        }

        let updatedCharacters = selectCharacters(state);

        if (!updatedCharacter) {
            // TODO - support for if the updated character is not us (but is still holding a torch or whathaveyou)
            updatedCharacter = selectCharacter(state);
        } else {
            updatedCharacters = [ ...updatedCharacters.filter(character => (character._id !== updatedCharacter._id)), { ...updatedCharacter } ]
        }

        let updatedPlants = selectPlants(state);
        updatedPlants = Object.values(updatedPlants.byId)
        let updatedConstructions = selectForegroundConstructions(state);
        updatedConstructions = Object.values(updatedConstructions.byId);

        if (!visibleTiles) {
            visibleTiles = this.tilemapService.calculateVisibleTiles(updatedCharacter, updatedCharacters, updatedPlants, updatedConstructions)
        }

        const newlyVisibleTiles = visibleTiles.filter(tile => (!this.visibleTiles.find(visibleTile => (visibleTile.x === tile.x && visibleTile.y === tile.y))))
        const newlyHiddenTiles = this.visibleTiles.filter(tile => (!visibleTiles.find(visibleTile => (visibleTile.x === tile.x && visibleTile.y === tile.y))))

        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))

        this.visibleTiles = visibleTiles;

        newlyVisibleTiles.forEach(tile => {
            const newCharacters = updatedCharacters.filter(character => (character.position.x === tile.x && character.position.y === tile.y));

            if (newCharacters) {
                newCharacters.forEach(character => (this.addCharacter(character)));
            }

            this.renderTile(this.layer[tile.y][tile.x], tile.y, tile.x, rememberedTiles)
        })

        newlyHiddenTiles.forEach(tile => {
            const removedCharacters = updatedCharacters.filter(character => (character.position.x === tile.x && character.position.y === tile.y));

            if (removedCharacters) {
                removedCharacters.forEach(character => (this.removeCharacter(character)));
            }

            this.renderTile(this.layer[tile.y][tile.x], tile.y, tile.x, rememberedTiles)
        })
    }

    async initialDraw(state, visibleTiles) {
        this.layer = getMockLayer();
        this.visibleTiles = visibleTiles;

        const updatedCharacter = selectCharacter(state);
        this.currentTool = selectCurrentTool(state);
        this.brainchipTypes = selectBrainchipTypes(state);
        this.currentActiveBrainchips = selectBrainchipTypeByIds(state, updatedCharacter.activeBrainchipTypeIds)
        const updatedPanel = selectPanel(state);

        const isInitialisingApp = selectIsInitialisingApp(state);
        const isInitialisingPanel = selectIsInitialisingPanel(state);

        this.isInitialising = isInitialisingApp || isInitialisingPanel;

        this.visionRadius = this.isCutscene ? 100 : selectVisionRadius(state)

        this.timeOffset = selectServerTimeOffset(state);

        // BEGIN

        // PLANTS
        let updatedPlants = selectPlants(state);
        updatedPlants = Object.values(updatedPlants.byId)

        this.removeItems(this.plants);


        if (updatedCharacter?.z === 0) {
            this.addItems(updatedPlants);
        }

        // ANIMALS
        const updatedAnimals = selectAnimals(state);

        this.removeItems(this.animals);
        this.addItems(updatedAnimals);

        // DEAD CHARACTERS
        const updatedDeadCharacters = selectDeadCharacters(state);

        this.removeItems(this.deadCharacters);
        this.addItems(updatedDeadCharacters);

        // CONSTRUCTIONS
        const selectedConstructions = selectForegroundConstructions(state);
        const updatedConstructions = Object.values(selectedConstructions.byId);

        this.removeItems(this.constructions);
        this.addItems(updatedConstructions);

        // WAGONS
        const updatedWagons = selectWagons(state);

        this.removeItems(this.wagons);
        this.addItems(updatedWagons);

        // BOATS
        const updatedBoats = selectBoats(state);

        this.removeBoats(this.boats);
        this.addBoats(updatedBoats);

        // ICON TILES
        const selectedTiles = selectItemTileIcons(state);
        let updatedTiles = Object.values(selectedTiles.byId).filter(tile => (tile.position?.x !== null));

        updatedTiles = updatedTiles
            .filter(tile => {
                const invisibleTiles = [
                    'Sledge',
                    'Wagon',
                    'Minecart',
                    'Seed Drill',
                    'Hand Cart',
                    'Horse cart',
                    'Freight Wagon',
                    'Passenger Wagon',
                    'Plough',
                    'Chariot',
                    'Ketch',
                    'Schooner',
                    'Barge',
                    'Brig',
                    'Brigantine',
                    'Galleon',
                    'Longboat',
                    'Outrigger canoe',
                    'Canoe',
                    'Kayak',
                    'Sloop',
                    'Cutter',
                    'Small sail boat',
                    'Raft',
                    'Sail canoe',
                    'Rowing boat',
                    'Caravel',
                    'Galley',
                    'IRRIGATION_CHANNEL'
                ];

                return invisibleTiles.indexOf(tile.tileType?.name) === -1
            })

        this.removeItems(this.iconTiles);
        this.addItems(updatedTiles);

        // STAIRS
        const updatedStairs = selectStairs(state).map(stair => ({ ...stair, id: new Date().getTime() }));

        this.removeItems(this.stairs);
        this.addItems(updatedStairs);

        // WORKSHOPS
        const updatedCharacterWorkshopType = updatedCharacter?.workshopType
        const updatedWorkshops = selectWorkshops(state);

        this.previousCharacterWorkshopType = { ...this.characterWorkshopType };
        this.characterWorkshopType = updatedCharacterWorkshopType;

        const characterWorkshopBoundries = selectCharacterWorkshopBoundries(state)

        if (updatedCharacter?.workshopDirection !== this.character?.workshopDirection) {
            this.drawWorkshopBoundries(characterWorkshopBoundries, updatedCharacter?.workshopDirection, this.character?.workshopDirection);
        }

        this.removeWorkshops(this.workshops);
        this.addWorkshops(updatedWorkshops)

        // FURNITURE

        const updatedFurniture = selectFurniture(state);

        this.removeItems(this.furniture);
        this.addItems(updatedFurniture);

        // MINERALS
        const updatedMinerals = selectMinerals(state);

        this.removeItems(this.minerals);
        this.addItems(updatedMinerals);

        // SEAS AND RIVERS,  BIOME DECORATION

        const selectedBackgroundConstructions = selectBackgroundConstructions(state);
        const backgroundConstructions = Object.values(selectedBackgroundConstructions.byId);

        if ((backgroundConstructions.length !== this.backgroundConstructions.length) || (updatedPanel?.id !== this.panel?.id) || (this.character?._id !== updatedCharacter?._id) || (this.character?.z !== updatedCharacter?.z) || (updatedPanel?.floorLayer?.length !== this.floorLayerLength)) {
            this.clearGrass();

            if (grassyBiomes.indexOf(updatedPanel.biome) > -1 && updatedCharacter?.z === 0) {
                this.addGrass(updatedPanel, backgroundConstructions);
            }

            this.floorLayerLength = updatedPanel.floorLayer?.length;

            this.doRiversOceansAndBiomes(updatedPanel, updatedCharacter)
        }

        // CHARACTERS
        const invisibilityBrainchipType = this.brainchipTypes.find(brainchipType => (brainchipType.name === BRAINCHIP_TYPE_NAMES.INVISIBILITY));
        const isDetectInvisibilityBrainchipActive = this.currentActiveBrainchips.find(brainchipType => (brainchipType.name === BRAINCHIP_TYPE_NAMES.DETECT_INVISIBILITY));
        let updatedCharacters = selectCharacters(state, updatedCharacter);

        if (updatedCharacter?.z) {
            this.removeWavesAndBiomeDecorations();
        }

        // removing invisible characters
        if (!isDetectInvisibilityBrainchipActive) {
            updatedCharacters = updatedCharacters.filter(character => (!character.activeBrainchipTypeIds || character.activeBrainchipTypeIds?.indexOf(invisibilityBrainchipType._id) === -1))
        }

        if (this.currentActiveBrainchips.find(brainchipType => (brainchipType.name === BRAINCHIP_TYPE_NAMES.NIGHT_VISION))) {
            this.foregroundLayer.setPipeline('Grayscale')
            this.foregroundLayer.pipeline.set1f('gray', 1);
        } else {
            this.foregroundLayer.resetPipeline()
        }

        this.removeCharacters();
        this.addCharacters(updatedCharacters);

        // END

        this.plants = updatedPlants;
        this.animals = updatedAnimals;
        this.deadCharacters = updatedDeadCharacters
        this.character = updatedCharacter;
        this.workshops = updatedWorkshops;
        this.constructions = updatedConstructions;
        this.wagons = updatedWagons;
        this.boats = updatedBoats;
        this.iconTiles = updatedTiles;
        this.furniture = updatedFurniture;
        this.stairs = updatedStairs;
        this.minerals = updatedMinerals;
        this.characters = updatedCharacters;
        this.panel = updatedPanel;
        this.backgroundConstructions = backgroundConstructions;

        this.copyLayersOver();
    }

    // TODO - remove this when we're happy it doesnt have anything superior to the tilemap calculateVisibleTiles function
    // calculateVisibleTiles(updatedCharacter, updatedCharacters, updatedPlants, updatedConstructions) {
    //     this.lightBlockingObjects = [];

    //     if (updatedCharacter?.z < 0) {
    //         this.lightBlockingObjects = selectMineWalls(store.getState())
    //     }

    //     const lightSourceCharacters = updatedCharacters.filter(character => {
    //         return character.lightSource?.expiresAt - new Date().getTime() - this.timeOffset > 0
    //     })

    //     let vision = updatedCharacter.z < 0 ? 1 : this.visionRadius;

    //     if (updatedCharacter.blind) {
    //         // check for blindness resist
    //         if (this.currentActiveBrainchips.find(brainchip => (brainchip.name === BRAINCHIP_TYPE_NAMES.BLINDNESS_RESIST))) {
    //             vision = 10;
    //         } else {
    //             vision = 1;
    //         }
    //     }

    //     if (updatedPlants) {
    //         this.lightBlockingObjects = [ ...this.lightBlockingObjects, ...updatedPlants.filter(plant => {
    //             const a = plant.position.x - updatedCharacter.position.x
    //             const b = plant.position.y - updatedCharacter.position.y

    //             const distance = Math.hypot(a, b);

    //             return distance > 2
    //         })  ]
    //     }

    //     this.lightBlockingObjects = [ ...updatedConstructions.filter(construction => (construction.construction?.name.indexOf('Wall') > -1)), ...this.lightBlockingObjects ]

    //     if (this.isCutscene) {
    //         this.lightBlockingObjects = []
    //     }

    //     const _visibleTiles = drawFieldOfView(this.lightBlockingObjects, updatedCharacter?.position, vision);

    //     let visibleTiles = calculateLightSources(_visibleTiles, updatedConstructions, this.lightBlockingObjects, lightSourceCharacters, updatedCharacter);

    //     if (updatedCharacter.blind) {
    //         // check for blindness resist
    //         if (this.currentActiveBrainchips.find(brainchip => (brainchip.name === BRAINCHIP_TYPE_NAMES.BLINDNESS_RESIST))) {
    //         } else {
    //             visibleTiles = []
    //         }
    //     }

    //     return visibleTiles;
    // }

    doRiversOceansAndBiomes(updatedPanel, updatedCharacter) {
        const oldRivers = this.panel.floorLayer?.filter(tile => (tile.tileType.indexOf('WATER') > -1));
        const oldOceanWaves = this.panel.floorLayer?.filter(tile => (tile.tileType.indexOf('OCEAN') > -1 ));

        if (oldRivers) {
            this.removeItems(oldRivers)
        }

        this.removeWavesAndBiomeDecorations();

        this.panel = updatedPanel

        if (updatedCharacter.z !== 0) {
            return;
        }

        const rivers = updatedPanel.floorLayer?.filter(tile => (tile.tileType.indexOf('WATER') > -1 ));

        if (rivers) {
            this.addItems(rivers)
        }

        let oceanWaves = updatedPanel.floorLayer?.filter(tile => (tile.tileType.indexOf('OCEAN') > -1 ));

        if (updatedPanel.isOcean) {
            oceanWaves = [];

            // do all tiles as ocean waves
            for (var y=0; y < PANEL_HEIGHT; y++) {
                for (var x=0; x < PANEL_WIDTH; x++) {
                    oceanWaves.push({
                        x,
                        y,
                    })
                }
            }
        }

        if (oceanWaves) {
            this.addOceanWaves(updatedPanel, oceanWaves);
        }

        const biomeStuff = this.getBiomeDecoration(updatedPanel);
    }

    removeWavesAndBiomeDecorations() {
        this.layer.forEach((row, y) => {
            row.forEach((tiles, x) => {
                if (tiles) {
                    this.layer[y][x] = tiles.filter((tile) => (tile.id !== 'ocean' && tile.id !== 'biome'))
                }
            })
        })
    }

    addOceanWaves(panel, oceanWaves) {
        const rng = seedrandom(panel.id);
        oceanWaves.forEach(wave => {
            const tileRoughness = rng();

            let roughnessString = '';

            if (tileRoughness < 0.75) {
                roughnessString = undefined;
            } else if (tileRoughness < 0.8) {
                roughnessString = '_2';
            } else if (tileRoughness < 0.85) {
                roughnessString = '_3';
            } else if (tileRoughness < 0.9) {
                roughnessString = '_4';
            } else {
                roughnessString = '_5';
            }


            if (roughnessString) {
                this.layer[wave.y][wave.x].push({
                    x: wave.x,
                    y: wave.y,
                    tileKey: `OCEAN${roughnessString}`,
                    id: 'ocean'
                })
            }
        })
    }

    getBiomeDecoration(panel) {
        if (!panel || panel.elevation >= 150) {
            return;
        }

        const mockLayerData = getMockLayer();
        const rng = seedrandom(panel.id);

        function drawNothing() {};

        const biomeMap = {
          'OCEAN': drawHighSeas,
          'SUBPOLAR_MOIST_TUNDRA': drawTundra,
          'SUBPOLAR_WET_TUNDRA': drawTundraFern,
          'SUBPOLAR_DRY_TUNDRA': drawTundraThorn,
          'SUBPOLAR_RAIN_TUNDRA': drawMistyTundra,
          'BOREAL_DESERT': drawPits,
          'BOREAL_DRY_SCRUB': drawPitsAndPlants,
          'BOREAL_MOIST_FOREST': drawNothing,
          'BOREAL_WET_FOREST': drawNothing,
          'BOREAL_RAIN_FOREST': drawNothing,
          'COOL_TEMPERATE_MOIST_FOREST': drawNothing,
          'COOL_TEMPERATE_WET_FOREST': drawNothing,
          'COOL_TEMPERATE_RAIN_FOREST': drawSwampyForest, //TODO - fix generation of rivers and willow trees and reeds
          'COOL_TEMPERATE_STEPPES': drawNothing,
          'COOL_TEMPERATE_DESERT_SCRUB': drawNothing,
          'COOL_TEMPERATE_DESERT': drawSlimes,
          'WARM_TEMPERATE_DRY_FOREST': drawNothing,
          'WARM_TEMPERATE_MOIST_FOREST': drawNothing,
          'WARM_TEMPERATE_WET_FOREST': drawNothing,
          'WARM_TEMPERATE_RAIN_FOREST': drawNothing,
          'WARM_TEMPERATE_THORN_SCRUB': drawNothing,
          'WARM_TEMPERATE_DESERT_SCRUB': drawNothing,
          'WARM_TEMPERATE_DESERT': drawWastes,
          'SUBTROPICAL_DRY_FOREST': drawNothing,
          'SUBTROPICAL_MOIST_FOREST': drawNothing,
          'SUBTROPICAL_WET_FOREST': drawNothing,
          'SUBTROPICAL_RAIN_FOREST': drawNothing,
          'SUBTROPICAL_THORN_WOODLAND': drawNothing,
          'SUBTROPICAL_DESERT_SCRUB': drawNothing,
          'SUBTROPICAL_DESERT': drawSlabs,
          'TROPICAL_DRY_FOREST': drawNothing,
          'TROPICAL_VERY_DRY_FOREST': drawNothing,
          'TROPICAL_THORN_WOODLAND': drawNothing,
          'TROPICAL_DESERT_SCRUB': drawNothing,
          'TROPCIAL_DESERT': drawSands,
          'TROPCIAL_MOIST_FOREST': drawNothing,
          'TROPICAL_WET_FOREST': drawNothing,
          'TROPICAL_RAIN_FOREST': drawNothing,
          'POLAR_ICE': drawIcyWastes,
          'POLAR_DESERT': drawPolarWastes,
          'MOUNTAIN': drawMountains,
          'EMPTY': drawNothing,
          'RUIN': drawRuins,
          'ALIEN_CITY': drawAlienCity,
          'HAMLET': drawHamlet,
          'MANSION': drawMansion
        }

        if (!biomeMap[panel.biome]) {
            return;
        }

        const interval = biomeMap[panel.biome](mockLayerData, mockLayerData, panel, rng, undefined, undefined);
        // DEV
        // const interval = biomeMap['MANSION'](mockLayerData, mockLayerData, panel, rng, undefined, undefined);
        // DEV

        if (interval) {
            this.intervals.push(interval)
        }

        mockLayerData.forEach((row, y) => {
            row.forEach((tile, x) => {
                const index = tile.index;

                if (tile.index === -1 || tile.index === tileMap['EMPTY'] || tile.index === undefined) {
                    return;
                }

                this.layer[y][x].push({
                    x,
                    y,
                    index,
                    id: 'biome'
                })
            })
        })

        if (panel.biome !== 'SUBPOLAR_RAIN_TUNDRA' && panel.biome !== 'TROPCIAL_DESERT') {
            if (this.intervals) {
                this.intervals.forEach(interval => clearInterval(interval))

                this.cloudLayer?.layer?.data.forEach((row, columnIndex) => {
                    row.forEach((tile, rowIndex) => {
                        if (tile) {
                            tile.index = -1;
                        }
                    })
                })
            }
            return;
        }

        if (panel.biome === 'SUBPOLAR_RAIN_TUNDRA') {
            // BIOME CLOUDS
            const cloudInterval = drawTundraClouds(this.cloudLayer, panel, rng);

            if (cloudInterval) {
                this.intervals.push(cloudInterval)
            }
        }
    }

    animateWaterDroplets(oldCharacters, updatedCharacters) {
        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))

        const puddleAnimationTiles = [
            'CRUNCHY.MONSOON.PUDDLE-WEIRD-1',
            'CRUNCHY.MONSOON.PUDDLE-WEIRD-2',
            'CRUNCHY.MONSOON.PUDDLE-POINK-4',
            'CRUNCHY.MONSOON.PUDDLE-POINK-3',
            'CRUNCHY.MONSOON.PUDDLE-POINK-2',
            'CRUNCHY.MONSOON.PUDDLE-POINK-1',
        ]
        //characters that moved:

        const movedCharacters = oldCharacters.filter(character => {
            const newCharacter = updatedCharacters.find(updatedCharacter => (updatedCharacter._id === character._id));

            if (newCharacter.position?.x !== character.position?.x || newCharacter.position?.y !== character.position?.y) {
                return true;
            }
        })

        movedCharacters.forEach(movedCharacter => {
            const position = movedCharacter.position;

            this.layer[position.y][position.x].push({
                tileKey: 'CRUNCHY.MONSOON.PUDDLE-POINK-4'
            })

            this.renderTile(this.layer[position.y][position.x], position.y, position.x, rememberedTiles)

            setTimeout(() => {
                this.layer[position.y][position.x] = this.layer[position.y][position.x].filter(item => (item.tileKey !== 'CRUNCHY.MONSOON.PUDDLE-POINK-4'))

                this.layer[position.y][position.x].push({
                    tileKey: 'CRUNCHY.MONSOON.PUDDLE-POINK-3'
                })

                this.renderTile(this.layer[position.y][position.x], position.y, position.x, rememberedTiles)

                setTimeout(() => {
                    this.layer[position.y][position.x] = this.layer[position.y][position.x].filter(item => (item.tileKey !== 'CRUNCHY.MONSOON.PUDDLE-POINK-3'))

                    this.layer[position.y][position.x].push({
                        tileKey: 'CRUNCHY.MONSOON.PUDDLE-POINK-2'
                    })

                    this.renderTile(this.layer[position.y][position.x], position.y, position.x, rememberedTiles)

                    setTimeout(() => {
                        this.layer[position.y][position.x] = this.layer[position.y][position.x].filter(item => (item.tileKey !== 'CRUNCHY.MONSOON.PUDDLE-POINK-2'))

                        this.layer[position.y][position.x].push({
                            tileKey: 'CRUNCHY.MONSOON.PUDDLE-POINK-1'
                        })

                        this.renderTile(this.layer[position.y][position.x], position.y, position.x, rememberedTiles)

                        setTimeout(() => {
                            this.layer[position.y][position.x] = this.layer[position.y][position.x].filter(item => (item.tileKey !== 'CRUNCHY.MONSOON.PUDDLE-POINK-1'))
                            this.renderTile(this.layer[position.y][position.x], position.y, position.x, rememberedTiles)
                        }, 100)
                    }, 100)
                }, 100)
            }, 100)
        })

        if (!this.layer || !this.layer[0] || !this.layer[0][0]) {
            return;
        }

        const randomX = randomNumberBetween(PANEL_WIDTH) - 1;
        const randomY = randomNumberBetween(PANEL_HEIGHT) - 1;

        this.layer[randomY][randomX].push({
            tileKey: 'CRUNCHY.MONSOON.PUDDLE-WEIRD-1'
        })
        this.renderTile(this.layer[randomY][randomX], randomY, randomX, rememberedTiles)

        setTimeout(() => {
            this.layer[randomY][randomX] = this.layer[randomY][randomX].filter(item => (item.tileKey !== 'CRUNCHY.MONSOON.PUDDLE-WEIRD-1'))

            this.layer[randomY][randomX].push({
                tileKey: 'CRUNCHY.MONSOON.PUDDLE-WEIRD-2'
            })

            this.renderTile(this.layer[randomY][randomX], randomY, randomX, rememberedTiles)

            setTimeout(() => {
                this.layer[randomY][randomX] = this.layer[randomY][randomX].filter(item => (item.tileKey !== 'CRUNCHY.MONSOON.PUDDLE-WEIRD-2'))

                this.renderTile(this.layer[randomY][randomX], randomY, randomX, rememberedTiles)
            }, 100)
        }, 100)
    }

    removeItems(items) {
        items.forEach(({ z, position, id, _id, x, y }) => {
            if (x !== undefined && y !== undefined && !position) {
                position = { x, y }
            }

            if (!position || position.x === undefined || position.x === null || position.y === null || position.y < 0 || position.x < 0 || position.y >= PANEL_HEIGHT || position.x >= PANEL_WIDTH) {
                return;
            }

            this.layer[position.y][position.x] = this.layer[position.y][position.x].filter(item => {
                if (item.setAlpha) {
                    return true;
                }

                if (item._id && _id) {
                    return item._id !== _id
                }

                if (item.id && id) {
                    return item.id !== id
                }

                if (item._id && id) {
                    return true;
                }

                if (item.id && _id) {
                    return true;
                }
            });
        })
    }

    addItems(items) {
        if (!items) {
            return;
        }

        items.forEach((item) => {
            if (item.x !== undefined) {
                item.position = {
                    x: item.x,
                    y: item.y
                }
            }

            if (!item
                || !item.position
                || item.position.x === undefined
                || isNaN(item.position.x)
                || item.position.x === null
                || item.position.y === null
                || isNaN(item.position.y)
                || item.position.y < 0
                || item.position.x < 0
                || item.position.y >= PANEL_HEIGHT
                || item.position.x >= PANEL_WIDTH
            ) {
                return;
            }

            if (item?.z && item?.z !== this.character?.z) {
                return;
            }

            this.layer[item.position.y][item.position.x].push({ ...item });

            // TODO - minerals, animals, and plants MAY share an id... super unlikely though i hope!
            if (item.id && item.animalTypeId) {
                this.itemDictionary[item._id || item.id] = { x: item.position.x, y: item.position.y }
            }
        })
    }

    async addNewItem(item) {
        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))

        if (item.tileId && item.tileId !== 'false') {
            // Wait for the state.tiles to populate :)
            setTimeout(() => {
                const state = store.getState();

                const tile = state.tiles.byId[item.tileId];
                const updatedTileInLayer = selectItemTileIconsForTile(state, tile)

                if (tile) {
                    this.layer[tile.position.y][tile.position.x] = this.layer[tile.position.y][tile.position.x].filter(layerItem => (!(layerItem?._id) || layerItem._id !== item.tileId))
                    this.layer[tile.position.y][tile.position.x].push(updatedTileInLayer)

                     // TODO - when you drop an item it doesn't show until you step off it... good enough imho but if you want...
                    this.renderTile(this.layer[tile.position.y][tile.position.x], tile.position.y, tile.position.x, rememberedTiles)
                }
            }, 1000)

            return;
        }

        if (!item) {
            return;
        }

        if (item.x !== undefined) {
            item.position = {
                x: item.x,
                y: item.y
            }
        }

        if (!item.position) {
            return;
        }

        if (item.position?.y === undefined || item.position?.x === undefined || this.layer === undefined) {
            try {
                item.position = JSON.parse(item.position);
            } catch(e) {
                // console.log('err wat?', item, this.layer.length);
            }
        }

        if (!item
            || !item.position
            || item.position.x === undefined
            || isNaN(item.position.x)
            || item.position.x === null
            || item.position.y === null
            || isNaN(item.position.y)
            || item.position.y < 0
            || item.position.x < 0
            || item.position.y >= PANEL_HEIGHT
            || item.position.x >= PANEL_WIDTH
        ) {
            console.log('nope coulnt do it')
            return;
        }

        if (item?.z && item?.z !== this.character?.z) {
            console.log('nope wrong z');
            return;
        }

        this.layer[item.position.y][item.position.x].push({ ...item });

        // TODO - minerals, animals, and plants MAY share an id... super unlikely though i hope!
        if (item.id && item.animalTypeId) {
            this.itemDictionary[item._id || item.id] = { x: item.position.x, y: item.position.y }
        }

        this.renderTile(this.layer[item.position.y][item.position.x], item.position.y, item.position.x, rememberedTiles)

        if (item.expiresAt) {
            // TODO - come up with some callbacks for this!!
            this.shadeTiles();
        }
    }

    removeNewItem(removedItem) {
        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))

        const item = this.itemDictionary[removedItem._id || removedItem.id] || removedItem;

        if (!item) {
            return;
        }

        if ((item.tileId && item.tileId !== 'false') || (item.constructionId && item.constructionId !== 'false')) {
            return;
        }

        if (item.x !== undefined) {
            item.position = {
                x: item.x,
                y: item.y
            }
        }

        if (item.position?.y === undefined || item.position?.x === undefined || this.layer === undefined) {
            try {
                item.position = JSON.parse(item.position);
            } catch(e) {
                // console.log('err wat?', item, this.layer.length);
            }
        }

        if (!item.position) {
            return;
        }

        this.layer[item.position.y][item.position.x] = this.layer[item.position.y][item.position.x].filter(filteredItem => {
            if (!filteredItem) {
                return true;
            }

            if (!filteredItem.id && !filteredItem._id) {
                return true;
            }

            if ((filteredItem.id && filteredItem.id !== removedItem.id) || (filteredItem._id && filteredItem._id !== removedItem._id)) {
                return true;
            }

            return false;
        })

        this.renderTile(this.layer[item.position.y][item.position.x], item.position.y, item.position.x, rememberedTiles)
    }

    removeCharacters() {

        this.characterSprites.forEach(({ sprite }) => {
            const position = this.getSpritePosition(sprite)

            if (!position || position.y < 0 || position.x < 0 || position.y > PANEL_HEIGHT || position.x > PANEL_WIDTH) {
                return;
            }

            if (!this.layer[position.y] || !this.layer[position.y][position.x]) {
                return;
            }

            this.layer[position.y][position.x] = this.layer[position.y][position.x].filter(item => (item !== sprite));

            sprite.destroy();
        })

        this.characterSprites = [];
    }

    removeCharacter(character) {
        let position;

        this.characterSprites.forEach(({ sprite, _id }) => {
            if (_id !== character._id) {
                return
            }

            position = this.getSpritePosition(sprite)

            if (!position || position.y < 0 || position.x < 0 || position.y > PANEL_HEIGHT || position.x > PANEL_WIDTH) {
                return;
            }

            if (!this.layer[position.y] || !this.layer[position.y][position.x]) {
                return;
            }

            this.layer[position.y][position.x] = this.layer[position.y][position.x].filter(item => (item !== sprite));

            sprite.destroy();
        })

        return position
    }

    addCharacters(characters) {
        if (!this.scene?.sys?.scene) {
            window.location.reload();
        }

        characters.forEach(character => this.addCharacter(character))
    }

    async addNewCharacter(character, visibleTiles) {
        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))
        this.addCharacter(character)
        this.renderTile(this.layer[character.position.y][character.position.x], character.position.y, character.position.x, rememberedTiles)

        this.shadeTiles(character, undefined, visibleTiles);
    }

    removeNewCharacter(character) {
        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))
        const position = this.removeCharacter(character)

        if (!position) {
            return;
        }

        this.renderTile(this.layer[position.y][position.x], position.y, position.x, rememberedTiles)
    }

    addCharacter({ _id, z, hunger, fire, position, armourType, weaponType, armourId, weaponId, clothingItems, characterSprite, color, isColorShowing, isTeleporting, armourGroupBiome, weaponGroupBiome }) {
        let addedCharacterSprites = [];

        if (!this.isWithinLightSourceVision({ x: position?.x, y: position?.y })) {
            return;
        }

        const isWalking = false

        // if (hunger >= BALANCE.HUNGER_IMPACTS_VALUE) {
        //     const timeNow = new Date().getTime();
        //     const timeToShow = 2000
        //     const totalTimeToShow = 2 * timeToShow;
        //     const numberBetweenZeroAndTotalTimeToShow = timeNow % totalTimeToShow
        //     const currentlyShowing = Math.floor(numberBetweenZeroAndTotalTimeToShow / totalTimeToShow * 4)

        //     if (currentlyShowing === 1 || currentlyShowing === 3) {
        //         let rectangleColor = Number(`0x${'#fffbae'.split('#')[1]}`);
        //         const rectangle = this.scene.add.rectangle((TILE_WIDTH * position?.x) + (TILE_WIDTH/2), (TILE_HEIGHT * position?.y) + (TILE_HEIGHT/2), TILE_WIDTH, TILE_HEIGHT, rectangleColor)
        //         this.characterSprites.push({
        //             sprite: rectangle,
        //             _id
        //         });
        //         addedCharacterSprites.push(rectangle)
        //     }
        // }

        const { x, y } = position

        // if (fire) {
        //     const timeNow = new Date().getTime();
        //     const timeToShow = 250
        //     const totalTimeToShow = 4 * timeToShow;
        //     const numberBetweenZeroAndTotalTimeToShow = timeNow % totalTimeToShow
        //     const currentlyShowing = Math.floor(numberBetweenZeroAndTotalTimeToShow / totalTimeToShow * 4)

        //     if (currentlyShowing === 1 || currentlyShowing === 3) {
        //         let rectangleColor = Number(`0x${'#a0240b'.split('#')[1]}`);
        //         const rectangle = this.scene.add.rectangle((TILE_WIDTH * position?.x) + (TILE_WIDTH/2), (TILE_HEIGHT * position?.y) + (TILE_HEIGHT/2), TILE_WIDTH, TILE_HEIGHT, rectangleColor)
        //         this.characterSprites.push({
        //             sprite: rectangle,
        //             _id
        //         });
        //         addedCharacterSprites.push(rectangle)
        //     }

        //     if (currentlyShowing === 0) {
        //         let rectangleColor = Number(`0x${'#f2c359'.split('#')[1]}`);
        //         const rectangle = this.scene.add.rectangle((TILE_WIDTH * position?.x) + (TILE_WIDTH/2), (TILE_HEIGHT * position?.y) + (TILE_HEIGHT/2), TILE_WIDTH, TILE_HEIGHT, rectangleColor)
        //         this.characterSprites.push({
        //             sprite: rectangle,
        //             _id
        //         });
        //         addedCharacterSprites.push(rectangle)
        //     }
        // }

        // if (isColorShowing) {
        //     let rectangleColor;

        //     if (color) {
        //         rectangleColor = Number(`0x${color.split('#')[1]}`);
        //     } else {
        //         rectangleColor = Number(`0x${'#D6F5FF'.split('#')[1]}`);
        //     }

        //     const rectangle = this.scene.add.rectangle((TILE_WIDTH * position?.x) + (TILE_WIDTH/2), (TILE_HEIGHT * position?.y) + (TILE_HEIGHT/2), TILE_WIDTH, TILE_HEIGHT, rectangleColor)
        //     this.characterSprites.push({
        //         sprite: rectangle,
        //         _id
        //     });
        //     addedCharacterSprites.push(rectangle)
        // }

        const sprite = this.scene.add.sprite((TILE_WIDTH * position?.x) + (TILE_WIDTH/2), (TILE_HEIGHT * position?.y) + (TILE_HEIGHT/2), 'humans')
        const characterIndex = characterSprite;
        sprite.setFrame(tileMap[`CHARACTER_${characterIndex}`]);

        // if (isTeleporting) {
        //     const numberOfTiles = 9;
        //     const timeToShow = 125;
        //     const timeNow = new Date().getTime()
        //     if (!this.timeTeleportationBegan) {
        //         this.timeTeleportationBegan = timeNow;
        //     }

        //     const totalTimeToShow = numberOfTiles * timeToShow;
        //     const currentlyShowing = Math.floor((timeNow - this.timeTeleportationBegan) / timeToShow)
        //     sprite.setFrame(teleportingTiles[currentlyShowing]);
        //     sprite.setAlpha(1)

        //     if (currentlyShowing >= numberOfTiles) {
        //         sprite.setAlpha(0);
        //     }
        // }

        // if (!isTeleporting && _id === this.character?._id && this.character?.isTeleporting) {
        //     const numberOfTiles = 9;
        //     const timeToShow = 125;
        //     const timeNow = new Date().getTime()
        //     if (!this.timeTeleportationBegan) {
        //         this.timeTeleportationBegan = timeNow;
        //     }

        //     const totalTimeToShow = numberOfTiles * timeToShow;
        //     const currentlyShowing = numberOfTiles - (Math.floor((timeNow - this.timeTeleportationBegan) / timeToShow))
        //     sprite.setFrame(teleportingTiles[currentlyShowing]);
        //     sprite.setAlpha(1)

        //     if (currentlyShowing === -1) {
        //         sprite.setFrame(tileMap[`CHARACTER_${characterIndex}`])
        //     }
        // }

        // sprite.setPipeline('Outline')
        // sprite.pipeline.set2f('uResolution', sprite.texture.getSourceImage().width, sprite.texture.getSourceImage().height);

        if (this.currentActiveBrainchips.find(brainchipType => (brainchipType.name === BRAINCHIP_TYPE_NAMES.NIGHT_VISION))) {
            sprite.setPipeline('Grayscale')
            sprite.pipeline.set1f('gray', 1);
        }

        this.characterSprites.push({
            sprite,
            _id
        });
        addedCharacterSprites.push(sprite)


        if (!isTeleporting && clothingItems) {
            clothingItems.map(clothingItem => {
                if (clothingItem.itemType === 'jewellery') {
                    return;
                }

                const clothing = this.scene.add.sprite((TILE_WIDTH * position?.x) + (TILE_WIDTH/2), (TILE_HEIGHT * position?.y) + (TILE_HEIGHT/2), 'humans');

                // HACK START
                if (!clothingItem.groupBiome) {
                    clothingItem.groupBiome = 'DESERT'
                }
                // HACK END

                if (!clothingItem.type) {
                    // console.error('item not found', clothingItem)
                } else {
                    if (tileMap[`${clothingItem.type.name}.Equipped`]) {
                        clothing.setFrame(tileMap[`${clothingItem.type.name}.Equipped`]);
                    } else if (tileMap[`${clothingItem.type.name}.${clothingItem.groupBiome}.Equipped`]) {
                        clothing.setFrame(tileMap[`${clothingItem.type.name}.${clothingItem.groupBiome}.Equipped`]);
                    } else {
                        if (clothingItem.type.bodyPart === "body") {
                            clothing.setFrame(tileMap[`Chestplate.${clothingItem.groupBiome}.Equipped`]);
                        }

                        if (clothingItem.type.bodyPart === "arms") {
                            clothing.setFrame(tileMap[`Guantlets.${clothingItem.groupBiome}.Equipped`]);
                        }

                        if (clothingItem.type.bodyPart === "head") {
                            clothing.setFrame(tileMap[`Helmet.${clothingItem.groupBiome}.Equipped`]);
                        }

                        if (clothingItem.type.bodyPart === "feet") {
                            clothing.setFrame(tileMap[`Space shoes.Equipped`]);
                        }

                        if (clothingItem.type.bodyPart === "legs") {
                            clothing.setFrame(tileMap[`Grieves.${clothingItem.groupBiome}.Equipped`]);
                        }
                    }
                }

                this.characterSprites.push({
                    sprite: clothing,
                    _id
                });
                addedCharacterSprites.push(clothing)

                // clothing.tint = coloursMap['var(--sg-red)']
            })
        }

        if (!isTeleporting && armourId && armourType) {
            const clothing = this.scene.add.sprite((TILE_WIDTH * position?.x) + (TILE_WIDTH/2), (TILE_HEIGHT * position?.y) + (TILE_HEIGHT/2), 'humans');

            if (armourGroupBiome) {
                clothing.setFrame(tileMap[`${armourType.name}.${armourGroupBiome}.Equipped`]);
            } else {
                clothing.setFrame(tileMap[`${armourType.name}.Equipped`]);
            }

            this.characterSprites.push({
                sprite: clothing,
                _id
            });
            addedCharacterSprites.push(clothing)
        }

        if (!isTeleporting && weaponId && weaponType) {
            const clothing = this.scene.add.sprite((TILE_WIDTH * position?.x) + (TILE_WIDTH/2), (TILE_HEIGHT * position?.y) + (TILE_HEIGHT/2), 'humans');

            if (weaponGroupBiome) {
                if (weaponType?.name === 'Pointy Stick') {
                    clothing.setFrame(tileMap[`Pointy Stick.Equipped`]);
                } else {
                    clothing.setFrame(tileMap[`${weaponType.type}.${weaponGroupBiome}.Equipped`]);
                }
            } else {
                if (weaponType?.name === 'Pointy Stick') {
                    clothing.setFrame(tileMap[`Pointy Stick.Equipped`]);
                } else {
                    clothing.setFrame(tileMap[`${weaponType.type}.Equipped`]);
                }
            }

            this.characterSprites.push({
                sprite: clothing,
                _id
            });
            addedCharacterSprites.push(clothing)
        }

        addedCharacterSprites.forEach(_sprite => {
            this.layer[position?.y][position?.x].push(_sprite)
        })
    }

    getSpritePosition(sprite) {
        return {
            x: Math.floor(sprite.x / TILE_WIDTH),
            y: Math.floor(sprite.y / TILE_HEIGHT)
        }
    }

    removeWorkshops(workshops) {
        workshops.forEach(workshop => {
            const nextBoundries = getTentBoundries(workshop.position, 'right', workshop.workshopType);
            nextBoundries.forEach(point => {
                this.layer[point.y][point.x] = this.layer[point.y][point.x].filter(item => (item?._id !== 'workshop'))
            })
        })
    }

    removeNewWorkshop(removedItem) {
        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))

        const item = this.itemDictionary[removedItem._id || removedItem.id];

        if (!item) {
            return;
        }

        if (item.x !== undefined) {
            item.position = {
                x: item.x,
                y: item.y
            }
        }

        const nextBoundries = getTentBoundries(workshop.position, 'right', workshop.workshopType);

        nextBoundries.forEach(point => {
            this.layer[point.y][point.x] = this.layer[point.y][point.x].filter(item => (item?._id !== 'workshop'))
            this.renderTile(this.layer[point.y][point.x], point.y, point.x, rememberedTiles)
        })
    }

    removeNewBoat(removedItem) {
        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))

        const item = this.itemDictionary[removedItem._id || removedItem.id];

        if (!item) {
            return;
        }

        if (item.x !== undefined) {
            item.position = {
                x: item.x,
                y: item.y
            }
        }

        if (!item.boatType?.horizontalBoundries) {
            return;
        }

        item.boatType.boundries = [ ...item.boatType.horizontalBoundries ]

        const nextBoundries = getTentBoundries(item.position, 'right', item.boatType);

        nextBoundries.forEach(point => {
            this.layer[point.y][point.x] = this.layer[point.y][point.x].filter(item => (item?._id !== 'boat'))
            this.renderTile(this.layer[point.y][point.x], point.y, point.x, rememberedTiles)
        })
    }

    addWorkshops(workshops) {
        workshops.forEach(workshop => {
            this.addWorkshop(workshop)
        })
    }

    addWorkshop(workshop) {
        const nextBoundries = getTentBoundries(workshop.position, 'right', workshop.workshopType);

        if (!workshop.position || workshop.position.x === undefined || workshop.position.y === undefined) {
            console.error('No workshop position!', workshop);

            return [];
        }

        nextBoundries.forEach(point => {
            if (point.tileKey === 'EMPTY') {
                return;
            }

            this.layer[point.y][point.x].push({
                ...point,
                _id: 'workshop',
                panelId: workshop.panelId
            })
        })

        return nextBoundries
    }

    removeBoats(boats) {
        if (!boats || !boats?.length) {
            return;
        }

        boats.forEach(boat => {
            if (!boat.boatType?.horizontalBoundries) {
                return;
            }
            
            boat.boatType.boundries = [ ...boat.boatType.horizontalBoundries ]

            const nextBoundries = getTentBoundries(boat.position, 'right', boat.boatType);

            nextBoundries.forEach(point => {
                if (!this.layer[point.y] || !this.layer[point.y][point.x]) {
                    return;
                }

                this.layer[point.y][point.x] = this.layer[point.y][point.x].filter(item => (item?._id !== 'boat'))
            })
        })
    }

    addBoats(boats) {
        boats.forEach(boat => {
            this.addBoat(boat)
        })
    }

    addBoat(boat) {
        if (!boat.boatType?.horizontalBoundries) {
            return;
        }

        boat.boatType.boundries = [ ...boat.boatType.horizontalBoundries ]

        const nextBoundries = getTentBoundries(boat.position, 'right', boat.boatType);

        nextBoundries.forEach(point => {
            if (point.tileKey === 'EMPTY') {
                return;
            }

            if (!this.layer[point.y] || !this.layer[point.y][point.x]) {
                return;
            }

            this.layer[point.y][point.x].push({
                ...point,
                _id: 'boat'
            })
        })

        return nextBoundries;
    }

    addNewBoat(boat) {
        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))
        const points = this.addBoat(boat)

        points.forEach(point => {
            if (point.tileKey === 'EMPTY') {
                return;
            }

            if (!this.layer[point.y] || !this.layer[point.y][point.x]) {
                return;
            }

            this.renderTile(this.layer[point.y][point.x], point.y, point.x, rememberedTiles)
        })
    }

    addNewWorkshop(workshop) {
        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))
        const points = this.addWorkshop(workshop)

        points.forEach(point => {
            if (point.tileKey === 'EMPTY') {
                return;
            }

            if (!this.layer[point.y] || !this.layer[point.y][point.x]) {
                return;
            }

            this.renderTile(this.layer[point.y][point.x], point.y, point.x, rememberedTiles)
        })
    }

    drawWorkshopBoundries(currentWorkshopBoundries, currentTentDirection, previousTentDirection) {
        if (!this.character) {
            return;
        }

        if (previousTentDirection) {
            currentWorkshopBoundries.forEach(point => {
                this.layer[point.y][point.x] = this.layer[point.y][point.x].filter(item => (item.tileKey !== point.tileKey))
            })
        }

        if (currentWorkshopBoundries && currentTentDirection) {
            currentWorkshopBoundries.forEach(point => {
                this.layer[point.y][point.x].push({
                    ...point,
                    _id: 'workshop'
                })
            })
        }
    }

    clearGrass() {
        this.layer.forEach((row, y) => {
            row.forEach((items, x) => {
                this.layer[y][x] = this.layer[y][x].filter(item => (item.id !== 'grass'))
            })
        })
    }

    addGrass(panel, backgroundConstructions) {
        const grassSeed = seedrandom('grass' + panel.id * 1000);

        this.layer.forEach((row, y) => {
            row.forEach((items, x) => {
                if (items.length > 0) {
                    return;
                }

                if (panel.floorLayer.find(tile => (tile.x === x && tile.y === y))) {
                    return;
                }

                if (backgroundConstructions.find(construction => (construction.position.x === x && construction.position.y === y))) {
                    return;
                }

                const grassType = grassSeed();

                if (grassType < 0.8) {
                    return;
                }

                if (grassType < 0.85) {
                    items.push({
                        id: 'grass',
                        tileKey: 'Grass.1',
                        x,
                        y
                    })
                    return;
                }

                if (grassType < 0.9) {
                    items.push({
                        id: 'grass',
                        tileKey: 'Grass.2',
                        x,
                        y
                    })
                    return;
                }

                if (grassType < 0.95) {
                    items.push({
                        id: 'grass',
                        tileKey: 'Grass.3',
                        x,
                        y
                    })
                    return;
                }

                items.push({
                    id: 'grass',
                    tileKey: 'Grass.4',
                    x,
                    y
                })
            })
        });
    }

    copyLayersOver() {
        if (!this.foregroundLayer.layer) {
            return;
        }

        this.foregroundLayer.layer.data.forEach((row, y) => {
            row.forEach((tile, x) => {
                tile.index = -1;
            })
        })

        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))

        this.layer.forEach((row, y) => {
            row.forEach((items, x) => {
                this.renderTile(items, y, x, rememberedTiles)
            })
        })

        this.addToMemory(this.character?.panelId, this.character?.z, this.memoryTiles)
    }

    renderTile(items, y, x, rememberedTiles) {
        if (!this.foregroundLayer.layer) {
            return;
        }

        items = items.filter((item) => (!!item))

        const originalItems = [ ...items ]
        let rememberedTile;
        let isRememberedTile = false;

        if (items.length > 1) {
            items = items.filter(item => (item?.id !== 'grass'))
        }

        if (items.length > 1) {
            items = items.filter(item => (item?.id !== 'biome'))
        }

        if (items.length > 1) {
            items = items.filter(item => (item?.id !== 'river'))
        }

        if (items.length > 1) {
            items = items.filter(item => (item?.id !== 'space-fill'))
        }

        if (items.find(item => (item?._id === 'boat'))) {
            items = items.filter(item => (item?.id !== 'ocean'))
        }

        if (!this.currentActiveBrainchips.find(brainchipType => (brainchipType.name === BRAINCHIP_TYPE_NAMES.NIGHT_VISION))) {
            if (!this.isWithinLightSourceVision({ x, y })) {
                rememberedTile = rememberedTiles.filter(tile => ((tile.x === x && tile.y === y)));

                if (!rememberedTile || rememberedTile.length === 0) {
                    this.foregroundLayer.layer.data[y][x].index = -1
                    return;
                } else {
                    isRememberedTile = true;
                    items = [ ...rememberedTile ]
                }
            }
        }

        if (!items || items.length === 0) {
            if (!isRememberedTile) {
                this.memoryTiles.push({
                    x,
                    y,
                    i: 0
                })

                this.foregroundLayer.layer.data[y][x].index = -1
            }
            return;
        }

        const timeNow = new Date().getTime();
        let numberOfTiles = items.length;

        let uniqueCharacterIds = [];

        this.characterSprites.forEach(({ _id, sprite }) => {
            if (uniqueCharacterIds.indexOf(_id) > -1) {
                return false;
            }

            if (items.indexOf(sprite) === -1) {
                return false;
            }

            uniqueCharacterIds.push(_id);
            return true;
        });

        const tileCharacterSprites = items.filter(item => (this.characterSprites.find(spriteObj => (spriteObj?.sprite === item))));

        if (tileCharacterSprites.length) {
            numberOfTiles = numberOfTiles - (tileCharacterSprites.length - uniqueCharacterIds.length);
        }

        const currentlyShowing = this.multiItemTileIndex % numberOfTiles

        const numberOfNonCharacterTiles = numberOfTiles - uniqueCharacterIds.length;
        const characterId = uniqueCharacterIds[currentlyShowing - numberOfNonCharacterTiles];

        if (this.character?.position?.x === x && this.character?.position?.y === y) {
            if (this.character.isTeleporting) {
                this.foregroundLayer.putTileAt(tileMap[this.findItemTileKey(this.character)], x, y)
                return;
            }
        }

        if (numberOfTiles > 1) {
            if (!this.multiItemTiles.find(tile => (tile.x === x && tile.y === y))) {
                // TODO - how to reset this on move?!?
                this.multiItemTiles.push({ x, y });
            }
        } else {
            this.multiItemTiles = this.multiItemTiles.filter(tile => (!(tile.x === x && tile.y === y)))
        }

        items.forEach((item, index) => {
            if (item.timeOfCreation) {
                const timeToShow = 100;
                const timeNow = new Date().getTime();

                this.foregroundLayer.layer.data[y][x].setAlpha(1 - ((timeNow - item.timeOfCreation) / timeToShow))
            }

            if (item.setAlpha && !characterId) {
                item.setAlpha(0.4)
            } else if (item.setAlpha) {
                item.setAlpha(0)
            }


            // MEMORY BANKS
            if (!isRememberedTile && item.constructionTypeId || item._id === 'workshop' || item.id === 'river' || item.id === 'ocean' || item.plantTypeId || item.furnitureTypeId || (item.tileType && item.tileType?.name !== 'ITEM')) {
                this.memoryTiles.push({
                    x: item.position?.x !== undefined ? item.position?.x : item.x,
                    y: item.position?.y !== undefined ? item.position?.y : item.y,
                    i: item.index || tileMap[this.findItemTileKey(item)]
                })
            } else if (!isRememberedTile) {
                this.memoryTiles.push({
                    x,
                    y,
                    i: 0
                })
            }

            if (currentlyShowing === index || this.characterSprites.find(({ sprite, _id }) => ( characterId === _id && sprite === item ))) {
                if (item.floorColour) {
                    const rectangleColor = Number(`0x${'#D6F5FF'.split('#')[1]}`);
                    const rectangle = this.scene.add.rectangle((TILE_WIDTH * x) + (TILE_WIDTH/2), (TILE_HEIGHT * y) + (TILE_HEIGHT/2), TILE_WIDTH, TILE_HEIGHT, rectangleColor)
                    rectangle.setAlpha(0.2)
                    this.characterSprites.push({
                        sprite: rectangle,
                        _id: new Date().getTime()
                    })
                }

                if (item.graffiti || item.pixelsArray) {
                    const colour = item.graffiti && item.graffiti[0] && item.graffiti[0]?.children && item.graffiti[0].children[0]?.color;
                    this.foregroundLayer.layer.data[y][x].tint = coloursMap[colour] || coloursMap['var(--white-darker)']
                } else if (item.itemColour) {
                    this.foregroundLayer.layer.data[y][x].tint = coloursMap[item.itemColour]
                } else {
                    this.foregroundLayer.layer.data[y][x].tint = 0xffffff
                }

                if (item.setAlpha) {
                    item.setAlpha(1)
                }

                if (isRememberedTile) {
                    this.foregroundLayer.layer.data[y][x].setAlpha(0.4)
                } else {
                    this.foregroundLayer.layer.data[y][x].setAlpha(1)
                }

                if (item.index) {
                    this.foregroundLayer.putTileAt(item.index, x, y)
                } else {
                    this.foregroundLayer.putTileAt(tileMap[this.findItemTileKey(item)], x, y)
                }

                let isNotBoatTile = originalItems.find(item => (item.id === 'river'))

                if (!isNotBoatTile) {
                    isNotBoatTile = originalItems.find(item => (item._id !== 'boat'))
                }

                if (item._id === 'boat' && isNotBoatTile && this.backgroundTilemapService) {
                    this.backgroundTilemapService.backgroundLayer.putTileAt(tileMap[isNotBoatTile.tileKey], x, y)
                } else if (isNotBoatTile && this.backgroundTilemapService && this.backgroundTilemapService.backgroundLayer && x !== undefined && y !== undefined) {
                    this.backgroundTilemapService.backgroundLayer.putTileAt(undefined, x, y)
                }

                const position = this.character.position;
                const isWalking = false

                if (isWalking && characterId === this.character._id) {
                    if (isWalking) {
                        item.setAlpha(0.7);
                    }
                }
            }
        })
    }

    addToMemory(panelId, z, memoryTiles) {
        let memory = JSON.parse(localStorage.getItem(`foreground-panel-${panelId}-z-${z}`)) || []

        memoryTiles.forEach(tile => {
            memory = memory.filter(storedTile => (!(storedTile.x === tile.x && storedTile.y === tile.y)))
        })

        memory = [ ...memory, ...memoryTiles ].filter(tile => (tile.i > 0))
        
        try {
            localStorage.setItem(`foreground-panel-${panelId}-z-${z}`, JSON.stringify(memory))
        } catch(e) {
            console.error('you have run out of space for local storage');
        }

        this.memoryTiles = []
    }

    animateShooting(message) {
        let source;
        let rememberedTiles = JSON.parse(localStorage.getItem(`foreground-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))

        let tileType = 'FIRE-PROJECTILE';

        if (message.text)

        if (message.attackerType === 'animal') {
            source = this.animals?.find(animal => (animal.id === message.attackerId))
        } else {
            source = this.characters?.find(character => (character._id === message.characterId));
        }

        const target = this.characters?.find(character => (character._id === message.targetCharacterId || character._id === message.characterId));

        if (!source) {
            return;
        }

        const sourcePosition = source.position;
        const targetPosition = target?.position || message.targetPosition;

        const x1 = sourcePosition.x;
        const x2 = targetPosition.x;
        const y1 = sourcePosition.y;
        const y2 = targetPosition.y;

        const points = bres.bresenhamLinePoints(x1, y1, x2, y2);
        let index = 1;

        const interval = setInterval(() => {
            if (index > 0) {
                const previousPoint = points[index - 1];

                if (previousPoint) {
                    setTimeout(() => {
                        this.layer[previousPoint.y][previousPoint.x] = this.layer[previousPoint.y][previousPoint.x].filter(item => (item.tileKey !== 'FIRE-PROJECTILE'))
                        this.renderTile(this.layer[previousPoint.y][previousPoint.x], previousPoint.y, previousPoint.x, rememberedTiles)
                    }, 100)
                }
            }

            if (index === points.length) {
                clearInterval(interval);
            }

            const currentPoint = points[index];

            if (currentPoint) {
                this.layer[currentPoint.y][currentPoint.x].push({
                    ...currentPoint,
                    tileKey: 'FIRE-PROJECTILE',
                    timeOfCreation: new Date().getTime(),
                    _id: 'fire-bullet'
                })
            }

            this.renderTile(this.layer[currentPoint.y][currentPoint.x], currentPoint.y, currentPoint.x, rememberedTiles)

            index++;
        }, 20)
    }

    isWithinLightSourceVision({ x, y }) {
        if (!this.visibleTiles || this.visibleTiles.length === 0) {
            return false;
        }

        return this.visibleTiles.find(wall => wall.x === x && wall.y === y && wall.visibility);
    }

    onDestroy() {
        clearInterval(this.copyInterval);
    }

    findItemTileKey(item) {
        if (item.tileIcons && item.tileIcons.length > 0) {
            const numberOfTiles = item.tileIcons.length;
            const currentlyShowing = this.multiItemTileIndex % numberOfTiles

            // For performance reasons, we just display one tile if there are many items in the same tile now.
            // if (item.tileIcons.length > 1) {
            //     if (!this.multiItemTiles.find(tile => (tile.x === item.position.x && tile.y === item.position.y))) {
            //         this.multiItemTiles.push({ x: item.position.x, y: item.position.y })
            //     }
                
            // }

            return item.tileIcons[currentlyShowing];
        }

        if (item.plant?.maxTendingCount) {
            if (!item.tileKey) {
                item.tileKey = item.plant?.name.toUpperCase();
            }

            if (item.tendedCount / item.plant?.maxTendingCount < 0.25) {
                return item.tileKey.split('.')[0] + '.SEED';
            }

            if (item.tendedCount / item.plant?.maxTendingCount < 0.5) {
                return item.tileKey.split('.')[0] + '.YOUNG';
            }

            if (item.tendedCount / item.plant?.maxTendingCount < 0.75) {
                return item.tileKey.split('.')[0] + '.OLD';
            }

            return item.tileKey.split('.')[0] + '.HARVEST'
        }

        if (item.tileKey) {
            return item.tileKey;
        }

        if (item.animal?.name) {
            const TEN_DAYS = 1000 * 60 * 60 * 24 * 10;
            if (new Date().getTime() - item.createdAt < TEN_DAYS) {

                if (tileMap[item.animal?.name + '.CUB']) {
                    return item.animal?.name + '.CUB'
                } else {
                    return item.animal?.name
                }
            }
            return item.animal?.name;
        }

        if (item.construction?.name) {
            const desertStyleBiomes = [
                'TAIGA',
                'JUNGLE',
                'ARID',
                'OLD-GROWTH'
            ]

            const swampStyleBiomes = [
                'RIVER',
                'POLAR',
            ]

            if (!item.groupBiome) {
                item.groupBiome = 'DESERT'
            }

            if (item.neighbours === 'NONE') {
                item.neighbours = 'NONE.'
            }
            if (item.construction?.name.indexOf('Space Wall') > -1) {
                return 'SPACE.' + item.neighbours;
            }
            if (item.construction?.name === 'Space Door') {
                return 'SPACE.DOOR'
            }
            if (item.construction?.name.indexOf('fence') > -1) {
                return `${item.construction?.name}.${item.neighbours}`;
            }
            if (item.construction?.name.toLowerCase().indexOf('bridge') > -1) {
                return `${item.construction?.name}.${item.neighbours}${item.groupBiome}`;
            }
            if (item.construction?.name.indexOf('Wall') > -1) {
                if (item.progress < item.construction.minimumProgressForFunctionality) {
                    if (item.progress < (item.construction.minimumProgressForFunctionality / 2)) {
                        return `Destroyed Wall.${item.groupBiome}`;
                    } else {
                        return `Broken Wall.${item.groupBiome}`;
                    }
                } else if (item.progress < 100) {
                    return `Damaged Wall.${item.groupBiome}`;
                }

                if (item.groupBiome !== undefined) {
                    if (tileMap[`${item.construction.name}.${item.neighbours}${item.groupBiome}`]) {
                        return `${item.construction.name}.${item.neighbours}${item.groupBiome}`;
                    } else if (desertStyleBiomes.indexOf(item.groupBiome) > -1) {
                        return `${item.construction.name}.${item.neighbours}DESERT`;
                    } else {
                        return `${item.construction.name}.${item.neighbours}SWAMP`;
                    }
                }
                return `${item.construction.name}.${item.neighbours}`;
            }

            if (item.construction?.name.indexOf('Door') > -1 || item.construction?.name.indexOf('Window') > -1) {
                return `${item.construction.name}.${item.groupBiome}`
            }

            if (new Date().getTime() - this.timeOffset > item.expiresAt) {
                if (tileMap[`${item.construction?.name}.${item.groupBiome}.OFF`]) {
                    return `${item.construction?.name}.${item.groupBiome}.OFF`
                }
                return item.construction?.name + '.OFF';
            }

            if (item.expiresAt !== undefined && new Date().getTime() - this.timeOffset < item.expiresAt) {
                if (tileMap[`${item.construction?.name}.${item.groupBiome}.ON`]) {
                    return `${item.construction?.name}.${item.groupBiome}.ON`
                }

                if (!this.multiItemTiles.find(tile => (tile.x === item.position.x && tile.y === item.position.y))) {
                    this.multiItemTiles.push({ x: item.position.x, y: item.position.y })
                }
                return this.animateFirePit();
            }

            return item.construction?.name;
        }

        if (item.tileType?.name) {
            if (tileMap[`${item.tileType?.name}.${item.groupBiome}`]) {
                return `${item.tileType?.name}.${item.groupBiome}`
            }

            if (item.tileType.name.toLowerCase().indexOf("pot") > -1) {
                return `Special Pot.${item.groupBiome}`
            }

            return item.tileType?.name;
        }

        if (item.tileType) {
            return item.tileType;
        }

        if (item.wagonType?.name) {
            if (item.wagonType?.name === 'Plough') {
                return 'Plough'
            }

            if (item.wagonType.name === 'Seed Drill') {
                return 'Seed Drill'
            }
            if (tileMap[`Wagon.${item.groupBiome}`]) {
                return `Wagon.${item.groupBiome}`
            } else {
                return 'Wagon'
            }
        }

        if (item.furnitureType?.name) {
            if (tileMap[`${item.furnitureType?.name}.${item.groupBiome}`]) {
                return `${item.furnitureType?.name}.${item.groupBiome}`
            }

            if (item.furnitureType?.name === "Plant Pot" || item.furnitureType?.name === "Planter") {
                const random = seedrandom(item._id)()

                if (random < 0.25) {
                    return `${item.furnitureType?.name}.2`;
                }

                if (random < 0.5) {
                    return `${item.furnitureType?.name}.3`;
                }

                if (random < 0.75) {
                    return `${item.furnitureType?.name}.4`;
                }

                return `${item.furnitureType?.name}`;
            }
            return item.furnitureType?.name;
        }

        if (item.mineralType?.name) {
            if (item.panelId) {
                return `${item.mineralType?.name}.Unmined`
            }
            return item.mineralType?.name;
        }

        if (item.causeOfDeath) {
            return 'DEAD_CHARACTER';
        }

        if (item.boatType?.name) {
            return `${item.boatType?.name}.Horizontal.A1`
        }
    }

    animateFirePit() {
        const tiles = [
            'Fire pit.1',
            'Fire pit.2',
            'Fire pit.3',
            'Fire pit.4',
            'Fire pit.5',
            'Fire pit.6',
            'Fire pit.7',
            'Fire pit.8',
            'Fire pit.9',
        ]
        const timeNow = new Date().getTime();
        const numberOfTiles = 9;
        const timeToShow = 100
        const totalTimeToShow = numberOfTiles * timeToShow;
        const numberBetweenZeroAndTotalTimeToShow = timeNow % totalTimeToShow
        const currentlyShowing = Math.floor(numberBetweenZeroAndTotalTimeToShow / totalTimeToShow * numberOfTiles)

        return tiles[currentlyShowing]
    }
}