import {
    NEW_CHARACTER_LOADING,
    NEW_CHARACTER_SUCCESS,
    NEW_CHARACTER_FAILURE,

    GET_CHARACTER_LOADING,
    GET_CHARACTER_SUCCESS,
    GET_CHARACTER_FAILURE,
    CHANGE_CHARACTER,

    BEGIN_WALK_DOWNSTAIRS,
    BEGIN_WALK_UPSTAIRS,

    BEGIN_UPDATE_POSITION,
    UPDATE_POSITION_LOADING,
    UPDATE_POSITION_SUCCESS,
    UPDATE_CUTSCENE_POSITION_SUCCESS,
    UPDATE_POSITION_FAILURE,

    UPDATE_PANEL_LOADING,
    UPDATE_PANEL_SUCCESS,
    UPDATE_PANEL_FAILURE,

    FIGHT_CHARACTER_LOADING,
    FIGHT_CHARACTER_SUCCESS,
    FIGHT_CHARACTER_FAILURE,

    REMOVE_CHARACTER,
    REMOVE_POSSIBLE_CHARACTER,
    ADD_CHARACTER,
    UPDATE_CHARACTER,

    EQUIP_TENT_LOCALLY,
    UNEQUIP_TENT_LOCALLY,
    SET_TENT_DIRECTION,

    EQUIP_WORKSHOP_LOCALLY,
    UNEQUIP_WORKSHOP_LOCALLY,
    SET_WORKSHOP_DIRECTION,

    UPDATE_CHARACTER_TOOL,
} from '../actionTypes'
import store from '../store';
import { showCharacterSelect, showUnknownError } from './keyboard-shortcuts.actions';
import { addClothingTypes } from './characters.actions';
import { updateBoatFailed } from './boat.actions'
import { updateAnimalFailed } from './animal.actions';
import { updateWagonFailed } from './wagon.actions';

import { colyClient } from '../../services/Panel-initialisation.service';

import { client } from '../../services/client';

export const dragItemAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: payload.characterId,
            draggingId: payload.itemId,
            draggingServiceName: payload.itemType
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const stopDragItemAsync = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: payload.characterId,
            draggingId: '',
            draggingServiceName: ''
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const giveUpLife = payload => dispatch => {
    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'remove',
        data: {
            _id: payload._id,
            draggingId: '',
            draggingServiceName: ''
        }
    })

    dispatch(removeCharacter({ ...payload }))
    dispatch(removePossibleCharacter({ ...payload }))
    dispatch(showCharacterSelect())

    return new Promise((resolve, reject) => (resolve()))
}

export const newCharacterAsync = payload => dispatch => {
    const { userId } = payload;

    client.service('characters').create({ userId })
        .then((response) => {
            dispatch(getCharacterSuccess({ character: response }))
        })
        .catch((error) => {
            console.error('error', error);
        })
}

export const getCharacterFailure = payload => ({
    type: GET_CHARACTER_FAILURE,
    payload
});

export const getCharacterSuccess = payload => ({
    type: GET_CHARACTER_SUCCESS,
    payload
});

export const updateCharacterTool = payload => ({
    type: UPDATE_CHARACTER_TOOL,
    payload
})

export const getCharacterAsync = payload => dispatch => {
    const { userId } = payload;

    return client.service('characters').find({
        query: {
            userId
        }
    })
    .then((response) => {
        const currentCharacterId = localStorage.getItem('characterId')

        const currentCharacter = response.find(character => (character._id === currentCharacterId));

        if (currentCharacter) {
            dispatch(getCharacterSuccess({ character: currentCharacter, characters: response }))
        } else {
            dispatch(getCharacterFailure({ characters: response }))
            dispatch(showCharacterSelect())
        }

        return response;
    })
    .catch((error) => {
        console.error('get character failure?', error);
        dispatch(getCharacterFailure({ characters: [] }))
    });
}

export const changeCharacter = () => ({
    type: CHANGE_CHARACTER
})

export const beginUpdatePosition = (payload) => ({
    type: BEGIN_UPDATE_POSITION,
    payload
})

export const updatePositionSuccess = payload => ({
    type: UPDATE_POSITION_SUCCESS,
    payload
})

export const updateCutscenePositionSuccess = payload => ({
    type: UPDATE_CUTSCENE_POSITION_SUCCESS,
    payload
})

export const updatePositionFailure = (payload) => ({
    type: UPDATE_POSITION_FAILURE,
    payload
})

export const updatePositionAsync = payload => dispatch => {
    const {
        _id,
        panelId,
        position,
        boat,
        animal,
        wagon
    } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id,
            panelId,
            position
        },
        params: {
            query: {
                isWalk: true
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const attackAnimalAsync = payload => dispatch => {
    const {
        panelId,
        position,
        animalId,
    } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: payload._id,
            panelId,
            position,
        },
        params: {
            query: {
                isAttack: true,
                animalId
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const fightCharacterSuccess = payload => ({
    type: FIGHT_CHARACTER_SUCCESS,
    payload
})

export const fightCharacterFailure = () => ({
    type: FIGHT_CHARACTER_FAILURE
})

export const fightCharacterAsync = payload => dispatch => {
    client.service('characters').patch(payload._id, { otherCharacterId: payload.otherCharacterId }, { query: { isFight: true }})
    .catch((error) => {
        console.error('fight character async error: ', error);
    });
}

export const shootWeaponAsync = payload => dispatch => {
    const { brainchip, character, position } = payload;

    client.service('characters').patch(character._id, { targetPosition: position }, { query: { isShoot: true }})
}

export const goUpstairsAsync = payload => dispatch => {
    const {
        panelId,
        position,
        z
    } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: payload._id,
            panelId,
            position,
            z
        },
        params: {
            query: {
                isWalk: true,
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const beginWalkUpstairs = payload => ({
    type: BEGIN_WALK_UPSTAIRS,
    payload
})

export const beginWalkDownstairs = payload => ({
    type: BEGIN_WALK_DOWNSTAIRS,
    payload
})

export const goDownstairsAsync = payload => dispatch => {
    const {
        panelId,
        position,
        z
    } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: payload._id,
            panelId,
            position,
            z
        },
        params: {
            query: {
                isWalk: true,
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const restOnFurnitureAsync = payload => dispatch => {
    const { _id, characterId } = payload;

    return client.service('characters').patch(characterId, {
        restingFurnitureId: _id
    })
        .catch(() => showUnknownError());
}

export const updateConversationTreeAsync = payload => dispatch => {
    const { _id, conversationTree } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: payload._id,
            conversationTree
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const equipWeaponAsync = payload => dispatch => {
    const { _id, weaponTypeId, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: characterId,
            weaponId: _id || '',
            weaponTypeId: weaponTypeId || ''
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const equipToolAsync = payload => dispatch => {
    const { _id, toolTypeId, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: characterId,
            toolId: _id || '',
            toolTypeId: toolTypeId || ''
        },
        params: {
            query: { isEquippingTool: true }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const equipArmourAsync = payload => dispatch => {
    const { _id, armourTypeId, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: characterId,
            armourId: _id || '',
            armourTypeId: armourTypeId || ''
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const equipClothingAsync = payload => diispatch => {
    let { _id, clothingTypeId, clothingItems, characterId, bodyPart } = payload;

    const armParts = clothingItems.filter(item => item.type?.bodyPart === 'arm');

    if (bodyPart === 'arm') {
        if (armParts.length >= 2) {
            // remove one
            clothingItems = [ ...clothingItems.filter(clothingItem => (clothingItem.itemId !== armParts[0].itemId)) ]
        }
    } else {
        clothingItems = [ ...clothingItems.filter(clothingItem => (clothingItem.type?.bodyPart !== bodyPart)) ]
    }

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: characterId,
            clothingItems: [
                ...clothingItems.map(clothingItem => ({ itemId: clothingItem.itemId, itemType: clothingItem.itemType, itemTypeId: clothingItem.itemTypeId })),
                { itemId: _id, itemType: 'clothing', itemTypeId: clothingTypeId }
            ]
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const unequipClothingAsync = payload => dispatch => {
    const { _id, clothingItems, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: characterId,
            clothingItems: [
                ...clothingItems.filter(clothingItem => (clothingItem.itemId !== _id && clothingItem.itemTypeId !== _id))
            ]
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const equipJewelleryAsync = payload => dispatch => {
    let { _id, jewelleryTypeId, clothingItems, characterId, bodyPart } = payload;

    const armParts = clothingItems.filter(item => item.type?.bodyPart === 'arm');

    if (bodyPart === 'arm') {
        if (armParts.length >= 2) {
            // remove one
            clothingItems = [ ...clothingItems.filter(clothingItem => (clothingItem.itemId !== armParts[0].itemId)) ]
        }
    } else {
        clothingItems = [ ...clothingItems.filter(clothingItem => (clothingItem.type?.bodyPart !== bodyPart)) ]
    }

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: characterId,
            clothingItems: [
                ...clothingItems.map(clothingItem => ({ itemId: clothingItem.itemId, itemType: clothingItem.itemType, itemTypeId: clothingItem.itemTypeId })),
                { itemId: _id, itemType: 'jewellery', itemTypeId: jewelleryTypeId }
            ]
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const unequipJewelleryAsync = payload => dispatch => {
    const { _id, clothingItems, characterId } = payload;

    colyClient.room.send('doAction', {
        serviceName: 'characters',
        commandType: 'patch',
        data: {
            _id: characterId,
            clothingItems: [
                ...clothingItems.filter(clothingItem => clothingItem.itemId !== _id)
            ]
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const removeCharacter = payload => ({
    type: REMOVE_CHARACTER,
    payload
})

export const removePossibleCharacter = payload => ({
    type: REMOVE_POSSIBLE_CHARACTER,
    payload
})

export const addCharacter = payload => ({
    type: ADD_CHARACTER,
    payload
})

export const updateCharacter = payload => {
    return ({
        type: UPDATE_CHARACTER,
        payload
    })
}

export const equipTentLocally = payload => ({
    type: EQUIP_TENT_LOCALLY,
    payload
})

export const unequipTentLocally = payload => ({
    type: UNEQUIP_TENT_LOCALLY,
    payload
})

export const setTentDirection = payload => ({
    type: SET_TENT_DIRECTION,
    payload
})

export const equipWorkshopLocally = payload => ({
    type: EQUIP_WORKSHOP_LOCALLY,
    payload
})

export const unequipWorkshopLocally = payload => ({
    type: UNEQUIP_WORKSHOP_LOCALLY,
    payload
})

export const setWorkshopDirection = payload => ({
    type: SET_WORKSHOP_DIRECTION,
    payload
})