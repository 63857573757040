import {
    CREATE_WRITING_IMPLEMENT_LOADING,
    CREATE_WRITING_IMPLEMENT_SUCCESS,
    CREATE_WRITING_IMPLEMENT_FAILURE,
    GET_WRITING_IMPLEMENTS_LOADING,
    GET_WRITING_IMPLEMENTS_SUCCESS,
    GET_WRITING_IMPLEMENTS_FAILURE,
    ADD_WRITING_IMPLEMENT,
    UPDATE_WRITING_IMPLEMENT,
    REMOVE_WRITING_IMPLEMENT,
    GET_TRADE_WRITING_IMPLEMENTS_SUCCESS,
    GET_TRADE_WRITING_IMPLEMENTS_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createWritingImplementSuccess = payload => ({
    type: CREATE_WRITING_IMPLEMENT_SUCCESS,
    payload
})

export const createWritingImplementFailure = () => ({
    type: CREATE_WRITING_IMPLEMENT_FAILURE
})

export const createWritingImplementAsync = payload => dispatch => {
    const { _id, writingImplementTypeId, selectedCharacterId, workshopId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'writing-implement-instances',
        name: 'create',
        data: {
            writingImplementTypeId,
            selectedCharacterId,
            workshopId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getWritingImplementsSuccess = payload => {
    return ({
        type: GET_WRITING_IMPLEMENTS_SUCCESS,
        payload
    })
}

export const getWritingImplementsFailure = () => ({
    type: GET_WRITING_IMPLEMENTS_FAILURE
})

export const getWritingImplementsAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('writing-implement-instances').find({ query })
        .then((response) => {
            response = response.map(writingImplement => ({ ...writingImplement, writingImplementType: payload.writingImplementTypes.find(type => type._id === writingImplement.writingImplementTypeId) }))
            dispatch(getWritingImplementsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const getTradeWritingImplementsSuccess = payload => {
    return ({
        type: GET_TRADE_WRITING_IMPLEMENTS_SUCCESS,
        payload
    })
}

export const getTradeWritingImplementsFailure = () => ({
    type: GET_TRADE_WRITING_IMPLEMENTS_FAILURE
})

export const getTradeWritingImplementsAsync = payload => dispatch => {
    return client.service('writing-implement-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(writingImplement => ({ ...writingImplement, writingImplementType: payload.writingImplementTypes.find(type => type._id === writingImplement.writingImplementTypeId) }))
            dispatch(getTradeWritingImplementsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addWritingImplementToTileAsync = payload => dispatch => {
    const { writingImplementInstance, tileId, tileTypeId } = payload;
    const { writingImplementTypeId, quantity, recipeId, characterId, _id } = writingImplementInstance;

    colyClient.room.send('doAction', {
        serviceName: 'writing-implement-instances',
        commandType: 'create',
        data: {
            writingImplementTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWritingImplementToCharacterAsync = payload => dispatch => {
    const { writingImplementInstances, characterId } = payload;
    const { createdAt, writingImplementTypeId, quantity, recipeId, _id } = writingImplementInstances[0];

    colyClient.room.send('doSuperAction', {
        serviceName: 'writing-implement-instances',
        name: 'fetch',
        data: {
            writingImplementTypeId,
            quantity,
            tileId: "",
            characterId,
            instanceIds: writingImplementInstances.map(inst => inst._id)
        },
        params: {
            query: {
                movementFrom: {
                    _id: undefined
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addWritingImplement = payload => {
    return ({
        type: ADD_WRITING_IMPLEMENT,
        payload
    })
}

export const updateWritingImplement = payload => {
    return ({
        type: UPDATE_WRITING_IMPLEMENT,
        payload
    })
}

export const removeWritingImplement = payload => {
    return ({
        type: REMOVE_WRITING_IMPLEMENT,
        payload
    })
}
