import React from "react";
import { connect } from 'react-redux'

import store from '../../../redux/store';
import { disableKeyboardMovement, enableKeyboardMovement } from '../../../redux/actions/keyboard-shortcuts.actions';
import { selectMessages, selectCharacter } from '../../../redux/selectors';

import { client } from '../../../services/client';

import './QuantityInput.css';

class QuantityInput extends React.Component {
    submitHandler = (event) => {
        if (this.props.isDisabled) {
            return;
        }

        if (Number(this.state.quantity) < 1) {
            console.log('no numbers less than 1 allowed', this.state.quantity);
            return;
        }

        if (Number.isInteger(Number(this.state.quantity)) === false) {
            console.log('no floating numbers allowed', this.state.quantity);
            return;
        }

        if (event.key === '\\') {
            this.closeQuantity();
            return;
        }

        if (event.key === 'Enter') {
            this.setQuantity();
            return;
        }
    };

    constructor() {
        super();
        this.state = { quantity: "" };

        document.addEventListener('keydown', this.submitHandler)
    }

    componentDidMount() {
        if (typeof this.props.quantity === 'number') {
            this.setState({
                quantity: this.props.quantity
            })
        }

        setTimeout(() => {
            this.quantityInput.select();
        })
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.submitHandler);
    }

    setQuantity = () => {
        if (this.props.onQuantitySupplied) {
            this.props.onQuantitySupplied(this.state.quantity);
        }
    };

    closeQuantity = () => {
        if (this.props.onQuantityClosed) {
            this.props.onQuantityClosed()
        }
    }

    updateInput(key, value) {
        this.setState({ [key]: value });
    }

    render() {
        return (
            <div className="quantity-input">
                <p className="conversation-wizard-exit"><span className="action">\</span> to exit</p>
                <div className="quantity-input-content">
                    <p className="quantity-input-explanation">How many:</p>
                    <input
                        ref={inputEl => (this.quantityInput = inputEl)} 
                        className="input"
                        onChange={e => this.updateInput('quantity', e.target.value)}
                        value={this.state.quantity}
                        type="input"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const messages = selectMessages(state)
    const character = selectCharacter(state)

    return { messages, character }
}

export default connect(
    mapStateToProps,
    { }
)(QuantityInput);