import {
    GET_COINS_LOADING,
    GET_COINS_SUCCESS,
    GET_COINS_FAILURE,
    CREATE_COIN_SUCCESS,
    CREATE_COIN_FAILURE,
    REMOVE_COIN_SUCCESS,
    UPDATE_COIN_SUCCESS,
} from '../actionTypes'
import store from '../store';

import { client } from '../../services/client';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { moveItemFailure } from './move-item.actions';
import { colyClient } from '../../services/Panel-initialisation.service';

export const getCoinsSuccess = payload => {
    return ({
        type: GET_COINS_SUCCESS,
        payload
    })
}

export const getCoinsFailure = () => ({
    type: GET_COINS_FAILURE
})

export const getCoinsAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('coin-instances').find({ paginate: false, query })
        .then((response) => {
            // response = response.map(coin => ({ ...coin, coinType: payload.coinTypes.find(type => type._id === coin.coinTypeId) }))
            dispatch(getCoinsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const createCoinSuccess = payload => ({
    type: CREATE_COIN_SUCCESS,
    payload
})

export const createCoinFailure = () => ({
    type: CREATE_COIN_FAILURE
})

export const createCoinAsync = payload => dispatch => {
    const { _id, coinTypeId, currencyId, characterId, selectedCharacterId, quantity, workshopId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'coin-instances',
        name: 'create',
        data: {
            coinTypeId,
            currencyId,
            characterId,
            selectedCharacterId,
            quantity,
            workshopId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const removeCoinSuccess = payload => ({
    type: REMOVE_COIN_SUCCESS,
    payload
})

export const updateCoinSuccess = payload => ({
    type: UPDATE_COIN_SUCCESS,
    payload
})

export const addCoinToCharacterAsync = payload => dispatch => {
    const { coinInstance, characterId, quantity } = payload;
    const { _id, coinTypeId } = coinInstance;

    colyClient.room.send('doAction', {
        serviceName: 'coin-instances',
        commandType: 'create',
        data: {
            characterId,
            quantity,
            coinTypeId,
            panelId: undefined,
            tileId: undefined,
            position: null
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}