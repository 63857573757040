import store from '../redux/store';
import Phaser from 'phaser';
import * as bres from 'bresenham-line-algorithm';

import { clearPlotAsync, tileTypes, createTileAsync, digIrrigationChannelAsync } from '../redux/actions/tile.actions';
import { plantSeedAsync, cultivatePlantAsync, harvestPlantAsync } from '../redux/actions/plant.actions';
import {
    openInventory,
    openCrafting,
    openActions,
    chooseSeedToPlant,
    chooseFoodToEat,
    showNoCharactersNearby,
    chooseCharacterToFight,
    showFightConfirmation,
    closeActions,
    showHelp,
    newOrganisation,
    showOrganisation,
    showOrganisationList,
    showZoneList,
    showZone,
    hideAllMenus,
    hideQuantityInput,
    hideCraftingError,
    hideMovingError,
    hideUnknownError,
    showMessages,
    newTownMessage,
    showUnknownError,
    showSuccess,
    hideSuccess,
    startLooking,
    stopLooking,
    startAiming,
    stopAiming,
    startMoving,
    stopMoving,
    startAreaSelect,
    stopAreaSelect,
    showCharacterActions,
    showCharacterProfile,
    showEquipment,
    showSheet,
    showWorkshop,
    showConversationTreeWizard,
    showEvents,
    showDiary,
    showWritingSurface,
    showGroupTerminal,
    showGuardHouse,
    showSelectItems,
    showScheduler,
    hideWritingSurface,
    hideDrawingSurface,
    showTable,
    showActionSelectMenu,
    showNearDeathScreen,
    nameLocation,
    showCharacterSelect,
} from '../redux/actions/keyboard-shortcuts.actions';
import {
    goDownstairsAsync,
    goUpstairsAsync,
    beginWalkUpstairs,
    beginWalkDownstairs,
    attackAnimalAsync,
    updatePositionAsync,
    fightCharacterAsync,
    unequipTentLocally,
    unequipWorkshopLocally,
    shootWeaponAsync,
    dragItemAsync,
    stopDragItemAsync,
    giveUpLife,
    changeCharacter,
} from '../redux/actions/character.actions';
import { startNewGame } from '../redux/actions/user.actions';
import { erectTentAsync, dismantleTentAsync } from '../redux/actions/tent.actions'
import { destroyWorkshop, erectWorkshopAsync } from '../redux/actions/workshop.actions';
import { gatherMineralAsync } from '../redux/actions/mineral.actions';
import { progressConstructionAsync, topUpFirePit } from '../redux/actions/construction.actions';
import { buryDeadCharacterAsync } from '../redux/actions/dead-characters.actions';
import { activateBrainchipAsync } from '../redux/actions/brainchip.actions';
import { BehaviourTypes, bridleAnimalAsync, unbridleAnimalAsync, saddleAnimalAsync, unsaddleAnimalAsync, reinAnimalAsync, unreinAnimalAsync } from '../redux/actions/animal.actions';
import { addDriverToBoatAsync, addPassengerToBoatAsync } from '../redux/actions/boat.actions';
import { addFurnitureToCharacterAsync, addFurnitureToPanelAsync } from '../redux/actions/furniture.actions';
import { unlockLockAsync, lockLockAsync } from '../redux/actions/lock.actions';
import { playInstrumentAsync } from '../redux/actions/messages.actions';
import { dismissCurrentPrompt } from '../redux/actions/prompt.actions';
import { toolTypeNames } from '../components/workshop/tool-type-names';
import { colyClient } from './Panel-initialisation.service';
import {
    selectCharacter,
    selectCharacters,
    selectToolTypes,
    selectWorkshopTypes,
    selectIsKeyboardMovementEnabled,
    selectIsActionsShowing,
    selectPlantInstanceIdAtTile,
    selectMineralAtPosition,
    selectFurnitureAtPosition,
    selectTileTypeByName,
    selectNearbyCharacters,
    selectNearbyAnimals,
    selectNearbyPlants,
    selectNeighbouringAnimals,
    selectNeighbouringWagons,
    selectDefendingCharacterId,
    selectIsQuantityInputOpen,
    selectIsCraftingErrorShowing,
    selectIsMovingErrorShowing,
    selectIsUnknownErrorShowing,
    selectLastCraft,
    selectIsCraftingShowing,
    selectIsInventoryShowing,
    selectStairs,
    selectNeighbouringBoats,
    selectNeighbouringTents,
    selectIsCharacterActionsShowing,
    selectTentById,
    selectWorkshopByPosition,
    selectFurnitureAtCharacter,
    selectNeighbouringPlants,
    selectNearbyConstructions,
    selectLocks,
    selectNearbyFarmPlot,
    selectCharacterSeeds,
    selectCharacterWorkshopBoundries,
    selectMinerals,
    selectServerTimeOffset,
    selectCurrentTiles,
    selectTileInventory,
    selectTileFood,
    selectTileMinerals,
    selectTileTools,
    selectTileMetals,
    selectTileKeys,
    selectTileWritingSurfaces,
    selectTileWritingImplements,
    selectTileWeapons,
    selectTileArmour,
    selectTileClothing,
    selectTileJewellery,
    selectTileTents,
    selectTileLocks,
    selectBoats,
    selectIsWritingSurfaceShowing,
    selectIsDrawingSurfaceShowing,
    selectAiming,
    selectMoving,
    selectAreaSelect,
    selectBrainchipTypeByIds,
    selectConstructions,
    selectBrainchips,
    selectNearbyFurniture,
    selectDeadCharactersByPosition,
    selectHighlight,
    selectCurrentPrompt,
    selectOpenOrganisation,
    selectIsZoneShowing,
    selectZones,
    selectOpenZone,
    selectSelectedCharacterId,
    selectAnimals,
    selectWorkshopBoundries,
    selectPlants,
    selectPanel,
    selectWorkshops
} from '../redux/selectors';
import { WORLD_WIDTH, WORLD_HEIGHT } from '../services/biome-geography'
import { PANEL_WIDTH, PANEL_HEIGHT } from '../services/geography';

import { BRAINCHIP_TYPE_NAMES } from './Tilemap.service';

import tileMap from './tile-map'

class ActionsService {
    character;
    boats;

    toolTypes;
    isInputListenerActive;
    plantInstanceIdAtTile;
    mineralInstanceAtTile;
    irrigationChannelTileType;
    nearbyCharacters;
    nearbyAnimals;
    neighbouringAnimals;
    lastCraft;
    stairs;
    tileContents;

    currentActiveBrainchips = [];

    isCutscene = false;
    isEmbarkWaitingRoom = false;

    audioService;

    constructor(scene, options, audioService) {
        this.isCutscene = options?.isCutscene;
        this.isEmbarkWaitingRoom = options?.isEmbarkWaitingRoom;

        this.audioService = audioService

        window.audioService = audioService;
    }

    getStoreValues() {
        const state = store.getState();

        this.character = selectCharacter(state);
        this.characters = selectCharacters(state);
        this.boats = selectBoats(state);
        this.currentActiveBrainchips = selectBrainchipTypeByIds(state, this.character.activeBrainchipTypeIds)
        this.plantInstanceIdAtTile = selectPlantInstanceIdAtTile(state, this.character.position)
        this.mineralInstanceAtTile = selectMineralAtPosition(state, this.character.position)
        this.minerals = selectMinerals(state);
        this.furnitureInstanceAtTile = selectFurnitureAtPosition(state, this.character.position)
        this.furnitureInstanceAtCharacter = selectFurnitureAtCharacter(state)
        this.furnitureNearCharacter = selectNearbyFurniture(state, this.character);
        this.nearbyFarmPlot = selectNearbyFarmPlot(state, this.character);
        this.nearbyCharacters = selectNearbyCharacters(state, this.character);
        this.nearbyAnimals = selectNearbyAnimals(state, this.character);
        this.nearbyPlants = selectNearbyPlants(state, this.character);
        this.nearbyConstructions = selectNearbyConstructions(state, this.character)
        this.neighbouringPlants = selectNeighbouringPlants(state, this.character)
        this.neighbouringAnimals = selectNeighbouringAnimals(state, this.character);
        this.neighbouringWagons = selectNeighbouringWagons(state, this.character);
        this.defendingCharacterId = selectDefendingCharacterId(state);
        this.isQuantityInputOpen = selectIsQuantityInputOpen(state);
        this.isCraftingErrorOpen = selectIsCraftingErrorShowing(state);
        this.isMovingErrorOpen = selectIsMovingErrorShowing(state);
        this.isUnknownErrorOpen = selectIsUnknownErrorShowing(state);
        this.lastCraft = selectLastCraft(state);
        this.stairs = selectStairs(state);
        this.neighbouringBoats = selectNeighbouringBoats(state, this.character);
        this.neighbouringTents = selectNeighbouringTents(state, this.character);
        this.characterTent = selectTentById(state, this.character.tentId);
        this.currentWorkshop = selectWorkshopByPosition(state, this.character.position);
        this.locks = selectLocks(state);
        this.characterSeeds = selectCharacterSeeds(state);
        this.timeOffset = selectServerTimeOffset(state);
        this.isEditWritingSurfaceShowing = selectIsWritingSurfaceShowing(state);
        this.isEditDrawingSurfaceShowing = selectIsDrawingSurfaceShowing(state);
        this.isAiming = selectAiming(state);
        this.isMoving = selectMoving(state);
        this.isAreaSelect = selectAreaSelect(state);
        this.brainchips = selectBrainchips(state)
        this.deadCharacterAtPosition = selectDeadCharactersByPosition(state, this.character.position);
        this.isLooking = selectHighlight(state);
        this.currentPrompt = selectCurrentPrompt(state);
        this.openOrganisation = selectOpenOrganisation(state);
        this.isZoneShowing = selectIsZoneShowing(state);
        this.existingZones = selectZones(state);
        this.currentZone = selectOpenZone(state)
        this.selectedCharacterId = selectSelectedCharacterId(state);
        this.animals = selectAnimals(state);

        const updatedConstructions = selectConstructions(state);
        this.constructions = Object.values(updatedConstructions.byId)

        this.currentWorkshopBoundries = selectCharacterWorkshopBoundries(state);

        this.isCraftingOpen = selectIsCraftingShowing(state);
        this.isInventoryOpen = selectIsInventoryShowing(state);
        this.isCharacterActionsOpen = selectIsCharacterActionsShowing(state);

        this.isInputListenerActive = selectIsKeyboardMovementEnabled(state) || selectIsActionsShowing(state);

        const tiles = selectCurrentTiles(state, this.character.position);

        let tileContents = {};

        tiles.byId.forEach(tile => {
            tileContents[tile._id] = {
                tile,
                tileInventory: [ ...selectTileInventory(state, tile._id) ],
                food: [ ...selectTileFood(state, tile._id) ],
                minerals: [ ...selectTileMinerals(state, tile._id) ],
                tools: [ ...selectTileTools(state, tile._id) ],
                metals: [ ...selectTileMetals(state, tile._id) ],
                keys: [ ...selectTileKeys(state, tile._id) ],
                writingSurfaces: [ ...selectTileWritingSurfaces(state, tile._id) ],
                writingImplements: [ ...selectTileWritingImplements(state, tile._id) ],
                weapons: [ ...selectTileWeapons(state, tile._id) ],
                armour: [ ...selectTileArmour(state, tile._id) ],
                clothing: [ ...selectTileClothing(state, tile._id) ],
                jewellery: [ ...selectTileJewellery(state, tile._id) ],
                tents: [ ...selectTileTents(state, tile._id) ],
                locks: [ ...selectTileLocks(state, tile._id) ],
            }
        })

        this.tileContents = tileContents;
    }

    onDestroy() {
        this.isInputListenerActive = false;
    }

    talkToSomeone() {
        if (!this.isInputListenerActive) {
            return;
        }

        if (this.nearbyCharacters.length === 1) {
            store.dispatch(showMessages({ focussedCharacterId: this.nearbyCharacters[0]._id }));
        }
    }

    isOverlappingExistingZone() {
        const areaWidth = Math.abs(this.isAreaSelect.startingCoords.x - this.isAreaSelect.x);
        const areaHeight = Math.abs(this.isAreaSelect.startingCoords.y - this.isAreaSelect.y);
        const areaStartingX = Math.min(this.isAreaSelect.startingCoords.x, this.isAreaSelect.x);
        const areaStartingY = Math.min(this.isAreaSelect.startingCoords.y, this.isAreaSelect.y);

        return this.existingZones
            .filter(zone => zone.panelId === this.character.panelId)
            .filter(existingZone => (existingZone?._id !== this.currentZone?._id))
            .filter(rectangleB => {
                let { x, y, width, height } = rectangleB.panelAreas[0]

                return Phaser.Geom.Rectangle.Overlaps({
                    x: areaStartingX,
                    y: areaStartingY,
                    right: (areaStartingX) + (areaWidth),
                    bottom: (areaStartingY) + (areaHeight)
                }, {
                    x: x,
                    y: y,
                    right: x + width,
                    bottom: y + height
                })
            })
    }

    isOverlappingConstructionPlantOrCliff({ x, y }) {
        const TILE_WIDTH = 16;
        const TILE_HEIGHT = 24;

        const state = store.getState();
        const workshopBoundries = selectWorkshopBoundries(state);
        const workshops = selectWorkshops(state)

        const areaRectangleA = { x: x-1, y: y-1 }
        const areaRectangleB = { x, y }

        if (workshopBoundries.length === 9) {
            areaRectangleB.x = areaRectangleB.x + 1
            areaRectangleB.y = areaRectangleB.y + 1
        }

        const areaWidth = (Math.abs(areaRectangleA.x - areaRectangleB.x) + 1);
        const areaHeight = (Math.abs(areaRectangleA.y - areaRectangleB.y) + 1);
        const areaStartingX = Math.min(areaRectangleA.x, areaRectangleB.x);
        const areaStartingY = Math.min(areaRectangleA.y, areaRectangleB.y);

        let isOverlapping = this.existingZones
            .filter(existingZone => (existingZone?._id !== this.currentZone?._id))
            .filter(existingZone => (existingZone?.panelId === this.character.panelId))
            .find(rectangleB => {
                let { x, y, width, height } = rectangleB.panelAreas[0]

                return Phaser.Geom.Rectangle.Overlaps({
                    x: areaStartingX,
                    y: areaStartingY,
                    right: (areaStartingX) + (areaWidth),
                    bottom: (areaStartingY) + (areaHeight)
                }, {
                    x: x,
                    y: y,
                    right: x + width,
                    bottom: y + height
                })
            })

        if (!isOverlapping) {
            const plants = selectPlants(state);
            const constructions = selectConstructions(state);
            const panel = selectPanel(state);
            const cliffsAndWater = panel.floorLayer?.filter(tile => (tile.tileType === 'OCEAN' || tile.tileType === 'Cliff' || tile.tileType === 'MOUNTAIN' || tile.tileType  === 'WATER'));

            isOverlapping =  [ ...cliffsAndWater, ...Object.values(plants.byId), ...Object.values(constructions.byId) ].find(tile => {
                if (!tile.position) {
                    console.log('FAIL: ', tile);
                }
                const x = tile.position.x;
                const y = tile.position.y;

                return Phaser.Geom.Rectangle.Overlaps({
                    x: areaStartingX,
                    y: areaStartingY,
                    right: (areaStartingX) + (areaWidth),
                    bottom: (areaStartingY) + (areaHeight)
                }, {
                    x: x,
                    y: y,
                    right: x + 1,
                    bottom: y + 1
                })
            })
        }

        if (!isOverlapping) {
            isOverlapping = workshops.find(workshop => {
                const leftMostX = Math.min(...workshop.workshopBoundries.map(boundry => boundry.x));
                const rightMostX = Math.max(...workshop.workshopBoundries.map(boundry => boundry.x));
                const topMostY = Math.min(...workshop.workshopBoundries.map(boundry => boundry.y));
                const bottomMostY = Math.max(...workshop.workshopBoundries.map(boundry => boundry.y));

                return Phaser.Geom.Rectangle.Overlaps({
                    x: areaStartingX,
                    y: areaStartingY,
                    right: (areaStartingX) + (areaWidth),
                    bottom: (areaStartingY) + (areaHeight)
                }, {
                    x: leftMostX,
                    y: topMostY,
                    right: rightMostX + 1,
                    bottom: bottomMostY + 1
                })
            })
        }

        return isOverlapping;
    }

    createInputListeners(scene) {
        let isShiftPressed = false;

        scene.input.keyboard.on('keydown-TAB', (event) => {
            event.preventDefault()
        })

        scene.input.keyboard.on('keydown-SHIFT', () => {
            isShiftPressed = true;
        })

        scene.input.keyboard.on('keyup-SHIFT', () => {
            isShiftPressed = false;
        })

        scene.input.keyboard.on('keydown', (event) => {
            this.getStoreValues();

            if (!this.character?._id) {
                return;
            }

            if (this.character.healthPoints === 0 && !this.isLooking) {
                // Near Death State screen
                if (event.key === 'g' || event.key === 'G') {
                    store.dispatch(giveUpLife({ ...this.character }));
                    return;
                }

                store.dispatch(showUnknownError({ message: 'Can\'t take actions whilst in near-death state' }))
                return;
            }

            if (!isShiftPressed || !this.isInputListenerActive || this.isCutscene || this.isLooking) {
                return;
            }

            if (event.key === '>') {
                if (this.stairs.find(stair => (stair.position.x === this.character.position.x && stair.position.y === this.character.position.y && (stair.zIndexTop === this.character.z || this.character.z === undefined)))) {
                    store.dispatch(beginWalkDownstairs())

                    setTimeout(() => (store.dispatch(goDownstairsAsync({ ...this.character, z: this.character.z ? this.character.z - 1 : -1 }))), 400)
                    return;
                }

                const construction = this.nearbyConstructions.find(construction => (this.character.position.x === construction.position.x && this.character.position.y === construction.position.y));

                if (construction?.construction?.name.toLowerCase().indexOf('staircase down') > -1) {
                    // walk down some stairs mate
                    store.dispatch(beginWalkDownstairs())

                    setTimeout(() => (store.dispatch(goDownstairsAsync({ ...this.character, z: this.character.z - 1 }))), 400);
                    return;
                }

                return;
            }

            if (event.key === '<') {
                if (this.stairs.find(stair => (stair.position.x === this.character.position.x && stair.position.y === this.character.position.y && stair.zIndexBottom === this.character.z))) {
                    store.dispatch(beginWalkUpstairs())
                    setTimeout(() => (store.dispatch(goUpstairsAsync({ ...this.character, z: this.character.z + 1 }))), 400);
                    return;
                }

                const construction = this.nearbyConstructions.find(construction => (this.character.position.x === construction.position.x && this.character.position.y === construction.position.y && this.character.z === construction.z));
                if (construction?.construction?.name.toLowerCase().indexOf('staircase') > -1 && construction?.construction?.name.toLowerCase().indexOf('down') === -1) {
                    // walk up some stairs mate
                    store.dispatch(beginWalkUpstairs())
                    setTimeout(() => (store.dispatch(goUpstairsAsync({ ...this.character, z: this.character.z + 1 }))), 400);
                    return;
                }
                return;
            }

            function findNearbyThing(thing, character) {
                if (event.key === 'ArrowUp') {
                    return thing.find(animal => (animal.position.x === character.position.x && animal.position.y === character.position.y - 1))
                }

                if (event.key === 'ArrowDown') {
                    return thing.find(animal => (animal.position.x === character.position.x && animal.position.y === character.position.y + 1))
                }

                if (event.key === 'ArrowLeft') {
                    return thing.find(animal => (animal.position.x === character.position.x - 1 && animal.position.y === character.position.y))
                }

                if (event.key === 'ArrowRight') {
                    return thing.find(animal => (animal.position.x === character.position.x + 1 && animal.position.y === character.position.y))
                }
            }

            const animal = findNearbyThing(this.nearbyAnimals, this.character);

            if (animal) {
                store.dispatch(attackAnimalAsync({ animalId: animal.id, position: animal.position, panelId: animal.panelId, _id: this.character._id }))
                return;
            }

            const nearbyConstruction = findNearbyThing(this.nearbyConstructions, this.character);

            if (nearbyConstruction && nearbyConstruction.construction?.name?.indexOf('Door') > -1) {
                const lock = this.locks.find(lock => lock.constructionId === nearbyConstruction._id);

                let isStoodOnCorrectPlace = false;

                if (lock.lockDirection === 'north') {
                    if (nearbyConstruction.position.y < this.character.position.y) {
                        isStoodOnCorrectPlace = true
                    }
                }

                if (lock.lockDirection === 'south') {
                    if (nearbyConstruction.position.y > this.character.position.y) {
                        isStoodOnCorrectPlace = true;
                    }
                }

                if (lock.lockDirection === 'east') {
                    if (nearbyConstruction.position.x < this.character.position.x) {
                        isStoodOnCorrectPlace = true;
                    }
                }

                if (lock.lockDirection === 'west') {
                    if (nearbyConstruction.position.y > this.character.position.y) {
                        isStoodOnCorrectPlace = true;
                    }
                }

                if (!lock.lockDirection) {
                    isStoodOnCorrectPlace = true;
                    // TODO - validate we have the key?
                }

                if (isStoodOnCorrectPlace) {
                    if (lock.isLocked) {
                        store.dispatch(unlockLockAsync({ ...lock }))
                            .then(response => (store.dispatch(showUnknownError({ message: 'Door unlocked' }))))
                        return;
                    }

                    if (!lock.isLocked) {
                        store.dispatch(lockLockAsync({ ...lock }))
                            .then(response => (store.dispatch(showUnknownError({ message: 'Door unlocked' }))))
                        return;
                    }
                }

            }

            const plant = findNearbyThing(this.nearbyPlants, this.character);

            if (plant) {
                const treeNames = [
                    'Discordant',
                    'Palm',
                    'Antiaris tree',
                    'Guinea plum',
                    'Thistlewood tree',
                    'Maze scrub',
                    'Evergreen tree',
                    'Crystalised',
                    'Crusty Tree',
                    'Primeordeal tree',
                    'Fur',
                    'Fossilised tree',
                    'Needlewood',
                    'Mulberry tree',
                    'Ordeal tree',
                    'Overgrown fur tree',
                    'Willow tree',
                    'Old growth tree',
                    'Painforest tree',
                    'Inglenook',
                    'Uapacaa tree',
                    'Primeval tree',
                    'Banana',
                    'Mango'
                ];
                const state = store.getState();
                const toolTypes = selectToolTypes(state);
                const toolType = toolTypes.find(tool => tool._id === this.character.toolTypeId);

                if ((toolType?.name === toolTypeNames.HOE || toolType?.name === toolTypeNames.PRIMITIVE_HOE) && treeNames.indexOf(plant.plant.name) === -1) {
                    store.dispatch(cultivatePlantAsync({ id: plant.id }));
                    return;
                }

                if (treeNames.indexOf(plant.plant.name) === -1) {
                    store.dispatch(harvestPlantAsync({ ...plant }))
                    return;
                }

                if ((toolType?.name === toolTypeNames.AXE || toolType?.name === toolTypeNames.PRIMITIVE_AXE) && treeNames.indexOf(plant.plant.name) > -1) {
                    store.dispatch(harvestPlantAsync({ ...plant }))
                    return;
                }
            }

            const nonFloorConstructions = this.nearbyConstructions.filter(construction => (construction.construction.name.toLowerCase().indexOf('floor') === -1))

            let construction = findNearbyThing(nonFloorConstructions, this.character);

            if (!construction) {
                construction = findNearbyThing(this.nearbyConstructions, this.character);
            }

            const state = store.getState();
            const toolTypes = selectToolTypes(state);
            const toolType = toolTypes.find(tool => tool._id === this.character.toolTypeId);
            if (construction && toolType.name === toolTypeNames.PAINTBRUSH) {
                store.dispatch(showWritingSurface({
                    surfaceService: 'construction-instances',
                    surfaceId: construction._id,
                    isEditWritingSurfaceShowing: false,
                    isEditDrawingSurfaceShowing: false
                }));

                return;
            }

            if (construction && toolType.name === toolTypeNames.HAMMER) {
                store.dispatch(progressConstructionAsync({
                    ...construction,
                    progress: 100
                }))

                return;
            }

            if (construction && (toolType.name === toolTypeNames.SLEDGEHAMMER || toolType.name === toolTypeNames.PRIMITIVE_SLEDGEHAMMER)) {
                store.dispatch(progressConstructionAsync({
                    ...construction,
                    progress: 0
                }))

                return;
            }

            const mineral = findNearbyThing(this.minerals, this.character);
            if (mineral) {
                if (toolType.name === toolTypeNames.PICKAXE || toolType.name === toolTypeNames.PRIMITIVE_PICKAXE) {
                    store.dispatch(gatherMineralAsync({ ...mineral }));
                    return;
                }
            }

            const otherCharacter = findNearbyThing(this.characters, this.character);

            if (otherCharacter) {
                store.dispatch(fightCharacterAsync({ _id: this.character._id, otherCharacterId: otherCharacter._id }));
                return
            }
        })

        // Cancel
        scene.input.keyboard.on('keydown-ESC', (event) => {
            this.audioService.playSound('closeMenu')
            this.getStoreValues();
            if (this.isAreaSelect?.areaSelectType === 'stockpile' && !this.isZoneShowing) {
                store.dispatch(showZone())
            } else if (this.character.tentId) {
                store.dispatch(unequipTentLocally());
            } else if (this.character.workshopId) {
                store.dispatch(unequipWorkshopLocally());
            } else if (this.isMovingErrorOpen) {
                store.dispatch(hideMovingError());
            } else if (this.isQuantityInputOpen) {
                store.dispatch(hideQuantityInput());
            } else if (this.isCraftingErrorOpen) {
                store.dispatch(hideCraftingError());
            } else if (this.isUnknownErrorOpen) {
                store.dispatch(hideAllMenus());
            } else {
                if (this.isEditWritingSurfaceShowing) {
                    store.dispatch(hideWritingSurface())
                    return;
                }
                if (this.isEditDrawingSurfaceShowing) {
                    store.dispatch(hideDrawingSurface())
                    return;
                }
                store.dispatch(hideAllMenus());
                store.dispatch(stopLooking());
                store.dispatch(stopAiming());
                store.dispatch(stopMoving());
                store.dispatch(stopAreaSelect());
            }
        })

        // Name location
        scene.input.keyboard.on('keydown-A', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(nameLocation());
                this.audioService.playSound('openMenu')
            }
        })

        // Change Character
        scene.input.keyboard.on('keydown-X', (event) => {
            this.getStoreValues();

            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showCharacterSelect());
                this.audioService.playSound('openMenu')
            }
        })

        // Inventory
        scene.input.keyboard.on('keydown-I', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(openInventory());
                this.audioService.playSound('openMenu')
            }
        })

        // Looking
        scene.input.keyboard.on('keydown-L', (event) => {
            this.getStoreValues();
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(startLooking({ position: this.character.position }));
            }
        })

        // Moving
        scene.input.keyboard.on('keydown-M', (event) => {
            this.getStoreValues();

            if (this.isMoving) {
                store.dispatch(showSelectItems({ itemsToSelect: 'namedLocations' }))
            }

            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(startMoving({ position: this.character.position }));
            }
        })

        // Fighting
        scene.input.keyboard.on('keydown-F', (event) => {
            if (!this.isInputListenerActive) {
                return;
            }
            this.getStoreValues();

            store.dispatch(showSelectItems({ itemsToSelect: 'nearbyCharactersOrAnimals' }))

            this.audioService.playSound('openMenu')


            // const defenderCharacters = this.characters
            // const defenderAnimals = this.animals
            // if (defenderAnimals.length === 0 && defenderCharacters.length === 0) {
            //     return;
            // }

            // const combined = [ ...defenderCharacters, ...defenderAnimals ]

            // if (combined.length > 1) {
            //     return
            // }

            // const defenderCharacter = defenderCharacters[0]
            // const defenderAnimal = defenderAnimals[0]

            // // we have to get the character or animal at this position my dude.
            // colyClient.room.send('doSuperAction', {
            //     name: 'fight',
            //     data: {
            //         ...this.isMoving,
            //         z: this.character.z,
            //         defenderType: defenderCharacter ? 'characters' : 'animal-instances',
            //         defenderId: defenderCharacter ? defenderCharacter._id : defenderAnimal.id,
            //     }
            // })

            // store.dispatch(hideAllMenus());
            // return;
            // if (this.isInputListenerActive && !this.isCutscene) {
            //     store.dispatch(startMoving({ position: this.character.position, type: 'fight' }));
            // }
        })

        // Gathering
        scene.input.keyboard.on('keydown-G', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showSelectItems({ itemsToSelect: 'resources' }))
                this.audioService.playSound('openMenu')
            }
        })

        // Hauling
        scene.input.keyboard.on('keydown-H', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showSelectItems({ itemsToSelect: 'zones' }))
                this.audioService.playSound('openMenu')
            }
        })

        // Orders List / Scheduler
        scene.input.keyboard.on('keydown-U', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showScheduler())
                this.audioService.playSound('openMenu')
            }
        })

        // Search for item
        scene.input.keyboard.on('keydown-S', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showSelectItems({ itemsToSelect: 'itemSearch' }))
                this.audioService.playSound('openMenu')
            }
        })

        // // Farming
        // scene.input.keyboard.on('keydown-F', (event) => {
        //     if (this.isInputListenerActive && !this.isCutscene) {
        //         store.dispatch(showSelectItems({ itemsToSelect: 'zones' }))
        //     }
        // })

        // Set stockpile or other zone...
        scene.input.keyboard.on('keydown-N', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showZoneList())
                this.audioService.playSound('openMenu')
            }
        })

        // Aiming Shooting
        scene.input.keyboard.on('keydown-F', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                this.getStoreValues();
                if (this.brainchips.find(brainchip => (brainchip.brainchipType.name === BRAINCHIP_TYPE_NAMES.FIRE_BREATH))) {
                    store.dispatch(startAiming({ position: this.character.position }));
                }
            }
        })

        // Near Death State screen
        scene.input.keyboard.on('keydown-B', (event) => {
            this.getStoreValues();
            if (this.isInputListenerActive && !this.isCutscene) {
                if (this.character.healthPoints <= 0) {
                    store.dispatch(showNearDeathScreen());
                    this.audioService.playSound('openMenu')
                }
            }
        })

        // General Input (context menus)
        scene.input.keyboard.on('keydown-SPACE', (event) => {
            this.getStoreValues();
            if (this.isCutscene) {
                return;
            }
            if (!this.isInputListenerActive || !this.character || this.character._id === 'test') {
                return
            }

            let options = [];

            // so what we want to do is collect possible actions and display a menu letting the user pick which one to do :)

            if (this.isAiming) {
                const x = this.isAiming.x;
                const y = this.isAiming.y;

                const a = x - this.character.position.x
                const b = y - this.character.position.y

                const distance = Math.hypot(a, b);

                const MAX_RANGE = 5;
                const points = bres.bresenhamLinePoints(x, y, this.character.position.x, this.character.position.y);

                const blockingTiles = [ ...this.constructions ];

                let isPointBlockingTile = false;

                points.forEach((point) => {
                    const pointX = point.x;
                    const pointY = point.y;

                    if (blockingTiles.find(blockingTile => (blockingTile.position.x === pointX && blockingTile.position.y === pointY))) {
                        isPointBlockingTile = true;
                    }
                })

                if (distance > MAX_RANGE || isPointBlockingTile) {
                    return;
                }

                // shoot!
                if (this.isAiming.type === BRAINCHIP_TYPE_NAMES.FIRE_BREATH) {
                    // shoot fire!!
                    const brainchip = this.brainchips.find(brainchip => (brainchip.brainchipType.name === BRAINCHIP_TYPE_NAMES.FIRE_BREATH))
                    if (!brainchip) {
                        return;
                    }

                    store.dispatch(activateBrainchipAsync({
                        ...brainchip
                    }))

                    store.dispatch(shootWeaponAsync({
                        brainchip: { ...brainchip },
                        character: { ...this.character },
                        position: { ...this.isAiming },
                    }))

                    store.dispatch(hideAllMenus());
                    return;
                }

                return;
            }

            // HI HARRY DELETE THIS COMMENT
            // if (this.isMoving?.type === 'fight') {
            //     const defenderCharacters = this.characters.filter(character => (character.position.x === this.isMoving.x && character.position.y === this.isMoving.y))
            //     const defenderAnimals = this.animals.filter(character => (character.position.x === this.isMoving.x && character.position.y === this.isMoving.y))

            //     if (defenderAnimals.length === 0 && defenderCharacters.length === 0) {
            //         return;
            //     }

            //     const combined = [ ...defenderCharacters, ...defenderAnimals ]

            //     if (combined.length > 1) {
            //         store.dispatch(showSelectItems({ itemsToSelect: 'nearbyCharactersOrAnimals' }))
            //         return
            //     }

            //     const defenderCharacter = defenderCharacters[0]
            //     const defenderAnimal = defenderAnimals[0]

            //     // we have to get the character or animal at this position my dude.
            //     colyClient.room.send('doSuperAction', {
            //         name: 'fight',
            //         data: {
            //             ...this.isMoving,
            //             z: this.character.z,
            //             defenderType: defenderCharacter ? 'characters' : 'animal-instances',
            //             defenderId: defenderCharacter ? defenderCharacter._id : defenderAnimal.id,
            //         }
            //     })

            //     store.dispatch(hideAllMenus());
            //     return;
            // }


            if (this.isMoving) {
                function calculateMovementOrder({ x, y }, startingPanelId) {
                    let panelId;
                    let _x = x;
                    let _y = y;

                    if (x < 0) {
                        // we go one panel to the west for each unit below 0 it is.
                        panelId = startingPanelId - Math.abs(x)
                        _x = PANEL_WIDTH - 1
                    }

                    if (y < 0) {
                        // we go one panel to the north for each unit below 0 it is.
                        panelId = startingPanelId - (WORLD_WIDTH * (Math.abs(y)))
                        _y = PANEL_HEIGHT - 1
                    }

                    if (x >= PANEL_WIDTH) {
                        // we go one panel to the east for each unit above PANEL_WIDTH - 1 it is.
                        panelId = startingPanelId + (x - PANEL_WIDTH + 1)
                        _x = 0
                    }

                    if (y >= PANEL_HEIGHT) {
                        // we go one panel to the east for each unit above PANEL_HEIGHT - 1 it is.
                        panelId = startingPanelId + (WORLD_WIDTH* (y - PANEL_HEIGHT + 1))
                        _y = 0
                    }

                    return {
                        panelId,
                        x: _x,
                        y: _y
                    }
                }

                const movementOrder = calculateMovementOrder(this.isMoving, this.character.panelId);

                // TODO - at the moment this takes you an extra panel east or west,
                // But if i set the x and y to something realistic it doesn't go more than one panel somehow.
                // this.audioService.playSound('step')
                colyClient.room.send('doSuperAction', {
                    name: 'move',
                    data: {
                        ...this.isMoving,
                        ...movementOrder,
                        z: this.character.z
                    }
                })

                store.dispatch(hideAllMenus());
                return;
            }

            if (this.isAreaSelect) {
                if (this.isAreaSelect.areaSelectType === 'workshop') {
                    let isOverlapping = this.isOverlappingConstructionPlantOrCliff(this.isAreaSelect);

                    if (isOverlapping) {
                        return;
                    }

                    store.dispatch(erectWorkshopAsync({
                        _id: this.character.workshopId,
                        workshopType: this.character.workshopType,
                        panelId: this.character.panelId,
                        position: {x: this.isAreaSelect.x, y: this.isAreaSelect.y},
                        z: this.character.z
                    }))
                    store.dispatch(hideAllMenus());
                    return;
                }
                if (this.isAreaSelect.startingCoords) {
                    const overlappingZones = this.isOverlappingExistingZone();
                    const isOverlappingExistingZone = overlappingZones.length > 0;

                    if (this.isAreaSelect.areaSelectType === 'gather') {

                        if (isOverlappingExistingZone) {
                            // TODO _ handle gathering in stockpiles??
                            return;
                        }

                        const actionName = this.isAreaSelect?.zone.name?.toLowerCase() === 'plant seeds' ? 'plant' : 'gather'

                        colyClient.room.send('doSuperAction', {
                            name: actionName,
                            data: {
                                selectedCharacterId: this.selectedCharacterId,
                                resources: this.isAreaSelect?.zone.name?.toLowerCase(),
                                organisationId: this.openOrganisation?._id, // if gathering on behalf of an org, use this
                                characterId: this.openOrganisation ? undefined : this.character?._id, // , // if gathering for yourself, use this
                                panelId: this.character?.panelId,
                                panelAreas: [
                                    {
                                        x: Math.min(this.isAreaSelect.startingCoords.x, this.isAreaSelect.x),
                                        y: Math.min(this.isAreaSelect.startingCoords.y, this.isAreaSelect.y),
                                        width: Math.abs(this.isAreaSelect.startingCoords.x - this.isAreaSelect.x) + 1,
                                        height: Math.abs(this.isAreaSelect.startingCoords.y - this.isAreaSelect.y) + 1
                                    }
                                ],
                                z: this.character.z

                            },
                        })

                        store.dispatch(hideAllMenus());
                        return;
                    }

                    if (this.isAreaSelect.areaSelectType === 'haul') {

                        if (isOverlappingExistingZone) {
                            if (overlappingZones.filter(zone => (zone.characterId === this.isAreaSelect?.zone?.characterId || zone.organisationId === this.isAreaSelect?.zone?.organisationId))) {
                                return;
                            }
                        }

                        colyClient.room.send('doSuperAction', {
                            name: 'haul',
                            data: {
                                selectedCharacterId: this.selectedCharacterId,
                                resources: 'all',//?....
                                organisationId: this.isAreaSelect?.zone?.organisationId, // if hauling on behalf of an org, use this
                                characterId: this.isAreaSelect?.zone?.characterId, // if hauling for yourself, use this
                                zoneId: this.isAreaSelect?.zone?._id, // if hauling for yourself, use this
                                panelId: this.character?.panelId,
                                panelAreas: [
                                    {
                                        x: Math.min(this.isAreaSelect.startingCoords.x, this.isAreaSelect.x),
                                        y: Math.min(this.isAreaSelect.startingCoords.y, this.isAreaSelect.y),
                                        width: Math.abs(this.isAreaSelect.startingCoords.x - this.isAreaSelect.x) + 1,
                                        height: Math.abs(this.isAreaSelect.startingCoords.y - this.isAreaSelect.y) + 1
                                    }
                                ],
                                z: this.character.z

                            },
                        })

                        store.dispatch(hideAllMenus());
                        return;
                    }

                    if (this.isAreaSelect.areaSelectType === 'construction') {
                        colyClient.room.send('doSuperAction', {
                            name: 'build',
                            data: {
                                z: this.character.z,
                                panelId: this.character.panelId,
                                constructionTypeId: this.isAreaSelect.zoneSettings.type._id,
                                characterId: this.character._id,
                                panelAreas: [
                                    {
                                        x: Math.min(this.isAreaSelect.startingCoords.x, this.isAreaSelect.x),
                                        y: Math.min(this.isAreaSelect.startingCoords.y, this.isAreaSelect.y),
                                        width: Math.abs(this.isAreaSelect.startingCoords.x - this.isAreaSelect.x) + 1,
                                        height: Math.abs(this.isAreaSelect.startingCoords.y - this.isAreaSelect.y) + 1
                                    }
                                ],
                            }
                        })

                        store.dispatch(hideAllMenus());

                        return;
                    }

                    if (!isOverlappingExistingZone) {
                        store.dispatch(showZone())
                    }

                    return;
                }
                store.dispatch(startAreaSelect({
                    coords: { x: this.isAreaSelect.x, y: this.isAreaSelect.y },
                    selectedCharacterId: this.selectedCharacterId,
                }))
                return;
            }

            // If placing a workshop
            if (this.character.workshopDirection) {
                // validate if errors are showing...
                if (this.currentWorkshopBoundries.find(boundry => (boundry.tileKey === 'ERROR'))) {
                    return;
                }

                store.dispatch(erectWorkshopAsync({
                    _id: this.character.workshopId,
                    workshopType: this.character.workshopType,
                    panelId: this.character.panelId,
                    position: this.character.position,
                    z: this.character.z
                }))

                store.dispatch(hideAllMenus());
                return;
            }

            // If you are stood on a wagon
            if (this.character.draggingId) {
                const draggingString = this.character.draggingServiceName === 'characters' ? 'character' : 'wagon'
                options.push({
                    text: `Stop dragging ${draggingString}`,
                    action: () => {
                        store.dispatch(stopDragItemAsync({
                            characterId: this.character._id
                        }))
                        return;
                    }
                })
            }

            // Disembarking from a boat you are not stood on takes priority over everything (as otherwise people will be stuck in the ocean)
            const state = store.getState();
            const boats = selectBoats(state);
            const boat = this.boats.find(boat => (boat.driverId === this.character._id && !(boat.position.x === this.character.position.x && boat.position.y === this.character.position.y)))
            if (boat) {
                options.push({
                    text: 'Disembark from boat',
                    action: () => {
                        store.dispatch(addDriverToBoatAsync({
                            characterId: '',
                            boatInstance: boat
                        }))
                        return;
                    }
                })
            }

            if (this.furnitureInstanceAtTile && this.furnitureInstanceAtTile.furnitureType.name === 'Terminal') {
                if (this.furnitureInstanceAtTile.groupId !== this.character.groupId) {
                    // TODO - show access denied message
                    return;
                }
                options.push({
                    text: 'Show terminal',
                    action: () => {
                        setTimeout(() => (store.dispatch(showGroupTerminal())));
                        return;
                    }
                })
            }

            if (this.furnitureInstanceAtTile && (this.furnitureInstanceAtTile.furnitureType.name === 'Town Horn' || this.furnitureInstanceAtTile.furnitureType.name === 'Town Bell')) {
                options.push({
                    text: 'Play instrument',
                    action: () => {
                        store.dispatch(playInstrumentAsync({
                            text: `BWWWWUUUUMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM`,
                            type: 'PLAY_INSTRUMENT'
                        }))
                        return;
                    }
                })
            }

            const table = this.furnitureNearCharacter?.find(furniture => (furniture.furnitureType.name.toLowerCase() === 'table'))
            if (this.furnitureNearCharacter && table) {
                options.push({
                    text: 'Talk at table',
                    action: () => {
                        store.dispatch(showTable({
                            focussedFurnitureId: table._id
                        }))
                        return;
                    }
                })
            }

            // If you are holding an item
            if (this.character.toolId) {
                const state = store.getState();
                const toolTypes = selectToolTypes(state);
                const toolType = toolTypes.find(tool => tool._id === this.character.toolTypeId);

                if (toolType.name === toolTypeNames.BRIDLE) {
                    // if we are stood on an animal, we set it to 'FOLLOWING' if it is a beast of burden :)
                    if (this.nearbyAnimals && this.nearbyAnimals.length > 0) {
                        const animal = this.nearbyAnimals[0]

                        if (animal.currentBehaviour === BehaviourTypes.WAITING) {
                            options.push({
                                text: 'Bridle animal',
                                action: () => {
                                    store.dispatch(bridleAnimalAsync({ id: animal.id, characterId: this.character._id }))
                                    return;
                                }
                            })
                        }

                        if (animal.currentBehaviour === BehaviourTypes.FOLLOWING) {
                            options.push({
                                text: 'Unbridle animal',
                                action: () => {
                                    store.dispatch(unbridleAnimalAsync({ id: animal.id, characterId: this.character._id }))
                                    return;
                                }
                            })
                        }
                    }
                }

                if (toolType.name === toolTypeNames.HANDCUFFS) {
                    if (this.nearbyCharacters.length > 0) {
                        this.nearbyCharacters.forEach(nearbyCharacter => {
                            options.push({
                                text: `Drag ${nearbyCharacter.name}`,
                                action: () => {
                                    store.dispatch(dragItemAsync({
                                        characterId: this.character._id,
                                        itemId: nearbyCharacter._id,
                                        itemType: 'characters',
                                    }))
                                    return;
                                }
                            })
                        })
                    }
                }

                if (toolType.name === toolTypeNames.SADDLE) {
                    // if we are stood on an animal, we set it to 'FOLLOWING' if it is a beast of burden :)
                    if (this.neighbouringAnimals && this.neighbouringAnimals.length > 0) {
                        const animal = this.neighbouringAnimals[0];

                        if (animal.currentBehaviour === BehaviourTypes.WAITING) {
                            options.push({
                                text: 'Saddle animal',
                                action: () => {
                                    store.dispatch(saddleAnimalAsync({ id: animal.id, characterId: this.character._id }))
                                    return;
                                }
                            })
                        }

                        if (animal.currentBehaviour === BehaviourTypes.FOLLOWING) {
                            options.push({
                                text: 'Saddle animal',
                                action: () => {
                                    store.dispatch(unsaddleAnimalAsync({ id: animal.id, characterId: this.character._id }))
                                    return;
                                }
                            })
                        }
                    }
                }

                if (toolType.name === toolTypeNames.REINS) {
                    // if we are stood on a wagon and there's an animal next to it, we set it to 'CARRYING' if it is a beast of burden :)
                    if (this.nearbyAnimals && this.nearbyAnimals.length > 0 && this.neighbouringWagons && this.neighbouringWagons.length > 0) {
                        const animal = this.nearbyAnimals[0];
                        const wagon = this.neighbouringWagons[0]

                        if (!animal.wagonId) {
                            options.push({
                                text: 'Rein animal',
                                action: () => {
                                    store.dispatch(reinAnimalAsync({ id: animal.id, characterId: this.character._id, wagonId: wagon._id }))
                                    return;
                                }
                            })
                        }

                        if (animal.wagonId) {
                            options.push({
                                text: 'Unrein animal',
                                action: () => {
                                    store.dispatch(unreinAnimalAsync({ id: animal.id, characterId: this.character._id }))
                                    return;
                                }
                            })
                        }
                    }
                }

                // If you are stood on a wagon
                if (toolType.name === toolTypeNames.REINS && this.neighbouringWagons && this.neighbouringWagons[0] && !this.character.draggingId) {
                    options.push({
                        text: 'Drag wagon',
                        action: () => {
                            // begin dragging the wagon...
                            store.dispatch(dragItemAsync({
                                itemType: 'wagon-instances',
                                itemId: this.neighbouringWagons[0]._id,
                                characterId: this.character._id
                            }))
                            return;
                        }
                    })
                }

                // if (toolType.name === toolTypeNames.TENT_POLE) {
                //     if (this.neighbouringTents && this.neighbouringTents.length > 0) {
                //         options.push({
                //             text: 'Dismantle tent',
                //             action: () => {
                //                 const tent = this.neighbouringTents[0];
                //                 store.dispatch(dismantleTentAsync({ tentInstance: { ...tent }, characterId: this.character._id }))
                //                 return;
                //             }
                //         })
                //     }
                // }

                if (this.currentWorkshop && (toolType.name === toolTypeNames.SLEDGEHAMMER || toolType.name === toolTypeNames.PRIMITIVE_SLEDGEHAMMER)) {
                    options.push({
                        text: 'Destroy workshop',
                        action: () => {
                            // todo - workshops shouldn't be so easy to destroy :P
                            store.dispatch(destroyWorkshop({ ...this.currentWorkshop }))
                            return;
                        }
                    })
                }

                if (toolType.name === toolTypeNames.HAMMER) {
                    options.push({
                        text: 'Open building menu',
                        action: () => {
                            // do not show building workshop menu if you are stood on a workshop :)
                            if (this.currentWorkshop._id) {
                                return;
                            }

                            const state = store.getState();
                            const workshopTypes = selectWorkshopTypes(state)
                            const buildersYard = workshopTypes.find(type => (type.name === 'Builder\'s Yard'));
                            store.dispatch(showWorkshop({ workshopTypeId: buildersYard._id, selectedCharacterId: this.character._id }));
                            this.audioService.playSound('openMenu');
                            return;
                        }
                    })
                }

                if (toolType.name === toolTypeNames.FURNITURE_MOVER && this.furnitureInstanceAtTile) {
                    options.push({
                        text: 'Pick up furniture',
                        action: () => {
                            store.dispatch(addFurnitureToCharacterAsync({ furnitureInstance: this.furnitureInstanceAtTile, panelId: this.character.panelId, position: this.character.position, characterId: this.character._id }))
                            return;
                        }
                    })
                }

                if (toolType.name === toolTypeNames.FURNITURE_MOVER && this.furnitureInstanceAtCharacter) {
                    options.push({
                        text: 'Put down furniture',
                        action: () => {
                            store.dispatch(addFurnitureToPanelAsync({ furnitureInstance: this.furnitureInstanceAtCharacter, panelId: this.character.panelId, position: this.character.position, z: this.character.z }))
                            return;
                        }
                    })
                }

                if (toolType.name === toolTypeNames.AXE || toolType.name === toolTypeNames.PRIMITIVE_AXE) {
                    if (this.neighbouringPlants.length > 0) {
                        options.push({
                            text: 'Harvest plant',
                            action: () => {
                                store.dispatch(harvestPlantAsync({ ...this.neighbouringPlants[0] }));
                                return;
                            }
                        })
                    }
                }

                if (toolType.name === toolTypeNames.PAINTBRUSH) {
                    const tile = Object.values(this.tileContents)[0].tile;

                    if (tile.tileType?.name !== 'ITEM') {
                        options.push({
                            text: 'Paint storage',
                            action: () => {
                                store.dispatch(showWritingSurface({
                                    surfaceService: 'tile-instances',
                                    surfaceId: tile._id,
                                    isEditWritingSurfaceShowing: false,
                                    isEditDrawingSurfaceShowing: false
                                }));
                                return;
                            }
                        })
                    }

                    if (this.furnitureInstanceAtTile) {
                        options.push({
                            text: 'Paint furniture',
                            action: () => {
                                store.dispatch(showWritingSurface({
                                    surfaceService: 'furniture-instances',
                                    surfaceId: this.furnitureInstanceAtTile._id,
                                    isEditWritingSurfaceShowing: false,
                                    isEditDrawingSurfaceShowing: false
                                }));
                                return;
                            }
                        })
                    }

                    if (this.neighbouringWagons && this.neighbouringWagons.length > 0) {
                        options.push({
                            text: 'Paint wagon',
                            action: () => {
                                const wagon = this.neighbouringWagons[0]
                                store.dispatch(showWritingSurface({
                                    surfaceService: 'wagon-instances',
                                    surfaceId: wagon._id,
                                    isEditWritingSurfaceShowing: false,
                                    isEditDrawingSurfaceShowing: false
                                }));
                                return;
                            }
                        })
                    }

                    if (this.neighbouringBoats && this.neighbouringBoats.length > 0) {
                        options.push({
                            text: 'Paint boat',
                            action: () => {
                                const boat = this.neighbouringBoats[0]
                                store.dispatch(showWritingSurface({
                                    surfaceService: 'boat-instances',
                                    surfaceId: boat._id,
                                    isEditWritingSurfaceShowing: false,
                                    isEditDrawingSurfaceShowing: false
                                }));
                                return;
                            }
                        })
                    }
                }

                if (toolType.name === toolTypeNames.HAND_PLOUGH || toolType.name === toolTypeNames.PRIMITIVE_PLOUGH) {
                    options.push({
                        text: 'Clear farm plot',
                        action: () => {
                            const state = store.getState();
                            const farmPlotTileType = selectTileTypeByName(state, tileTypes.FARM_PLOT)
                            store.dispatch(clearPlotAsync(farmPlotTileType._id))
                            return;
                        }
                    })
                }

                if (toolType.name === toolTypeNames.SPADE) {
                    if (this.deadCharacterAtPosition?.length > 0) {
                        options.push({
                            text: 'Bury body',
                            action: () => {
                                store.dispatch(buryDeadCharacterAsync({ _id: this.deadCharacterAtPosition[0]._id }))
                                return;
                            }
                        })
                    } else {
                        options.push({
                            text: 'Dig irrigation channel',
                            action: () => {
                                const state = store.getState();
                                const irrigationChannelTileType = selectTileTypeByName(state, tileTypes.IRRIGATION_CHANNEL);
                                store.dispatch(clearPlotAsync(irrigationChannelTileType._id))
                                return;
                            }
                        })
                    }

                }

                if (toolType.name === toolTypeNames.PICKAXE || toolType.name === toolTypeNames.PRIMITIVE_PICKAXE) {
                    const mineral = this.mineralInstanceAtTile

                    if (mineral) {
                        options.push({
                            text: 'Gather mineral',
                            action: () => {
                                store.dispatch(gatherMineralAsync({ ...mineral }));;
                                return;
                            }
                        })
                    }
                }

                if (this.plantInstanceIdAtTile) {
                    if (toolType.name === toolTypeNames.SICKLE) {
                        options.push({
                            text: 'Harvest plant',
                            action: () => {
                                store.dispatch(harvestPlantAsync({ id: this.plantInstanceIdAtTile }))
                                return;
                            }
                        })
                    }

                    if (toolType.name === toolTypeNames.HOE || toolType.name === toolTypeNames.PRIMITIVE_HOE) {
                        options.push({
                            text: 'Cultivate plant',
                            action: () => {
                                store.dispatch(cultivatePlantAsync({ id: this.plantInstanceIdAtTile }))
                                return;
                            }
                        })
                    }
                }

                const isStoodOnPlanter = Object.values(this.tileContents).find(tile => (tile.tile?.tileType?.name === 'Planter'));
                const isStoodOnPlantPot = Object.values(this.tileContents).find(tile => (tile.tile?.tileType?.name === 'Plant Pot'));
                if ((this.nearbyFarmPlot && this.nearbyFarmPlot[0] && toolType.name === toolTypeNames.SEED_PLANTER) || isStoodOnPlanter || isStoodOnPlantPot) {
                    if (!this.characterSeeds || this.characterSeeds.length === 0) {
                        return;
                    }
                    options.push({
                        text: 'Plant seed',
                        action: () => {
                            store.dispatch(plantSeedAsync({ ...this.characterSeeds[0] }))
                            return;
                        }
                    })
                }
            }

            this.nearbyCharacters.filter(character => (character.healthPoints <= 0)).forEach(nearbyCharacter => {
                options.push({
                    text: `Drag ${nearbyCharacter.name}`,
                    action: () => {
                        store.dispatch(dragItemAsync({
                            characterId: this.character._id,
                            itemId: nearbyCharacter._id,
                            itemType: 'characters',
                        }))
                        return;
                    }
                })
            })

            // If boat near you
            if (this.neighbouringBoats.length === 1) {
                const boat = this.neighbouringBoats[0];

                if (!boat.driverId || !this.characters.find(character => (character._id === boat.driverId))) {
                    options.push({
                        text: 'Drive boat',
                        action: () => {
                            let passengerIds = boat.passengerIds ? [ ...boat.passengerIds ] : []
                            store.dispatch(addDriverToBoatAsync({
                                characterId: this.character._id,
                                boatInstance: {
                                    ...boat,
                                    passengerIds: passengerIds.filter(id => (id !== this.character._id))
                                }
                            }))
                            return;
                        }
                    })
                }

                if (boat.driverId === this.character._id) {
                    options.push({
                        text: 'Stop driving boat',
                        action: () => {
                            store.dispatch(addDriverToBoatAsync({
                                characterId: '',
                                boatInstance: boat
                            }))
                            return;
                        }
                    })
                }

                if (boat.driverId && boat.driverId !== this.character._id) {

                    if (boat.passengerIds && boat.passengerIds.find(id => (id === this.character._id))) {
                        options.push({
                            text: 'Disembark from boat',
                            action: () => {
                                store.dispatch(addPassengerToBoatAsync({
                                    characterId: this.character._id,
                                    boatInstance: {
                                        ...boat,
                                        passengerIds: boat.passengerIds.filter(id => (id !== this.character._id))
                                    }
                                }))
                                return;
                            }
                        })
                    } else {
                        options.push({
                            text: 'Board boat',
                            action: () => {
                                let passengerIds = boat.passengerIds ? [ ...boat.passengerIds ] : []
                                store.dispatch(addPassengerToBoatAsync({
                                    characterId: this.character._id,
                                    boatInstance: {
                                        ...boat,
                                        passengerIds: [ ...passengerIds, this.character._id ],
                                    }
                                }))
                                return;
                            }
                        })
                    }
                }
            }

            if (this.nearbyCharacters.length > 1) {
                options.push({
                    text: 'Select Character',
                    action: () => {
                        // show characters list and THEN show which one you want.
                        store.dispatch(showSelectItems({ itemsToSelect: 'nearbyCharacters' }))
                        this.audioService.playSound('openMenu');
                        return;
                    }
                })
            }

            // If you are stood on a tile with some items and such
            if (Object.keys(this.tileContents).length > 0) {
                options.push({
                    text: 'Select Items',
                    action: () => {
                        store.dispatch(showSelectItems({ itemsToSelect: 'tile' }))
                        this.audioService.playSound('openMenu');
                        return;
                    }
                })
            }

            // If you are stood on a door
            const door = this.nearbyConstructions.find(construction => (construction.position.x === this.character.position.x && construction.position.y === this.character.position.y && construction.construction?.name?.indexOf('Door') > -1));
            if (door) {
                const lock = this.locks.find(lock => lock.constructionId === door._id);
                if (!lock) {
                    return;
                }


                if (lock?.isLocked) {
                    store.dispatch(unlockLockAsync({ ...lock }))
                        .then(response => (store.dispatch(showUnknownError({ message: 'Door unlocked' }))))
                    return;
                } else {
                    store.dispatch(lockLockAsync({ ...lock }))
                        .then(response => (store.dispatch(showUnknownError({ message: 'Door locked' }))))
                    return;
                }
            }

            // If you are stood on a fire pit
            const firePit = this.nearbyConstructions.find(construction => (construction.position.x === this.character.position.x && construction.position.y === this.character.position.y && (construction.construction?.name?.indexOf('Fire') > -1 || construction.construction?.name?.indexOf('Lamp') > -1)));
            if (firePit) {
                store.dispatch(topUpFirePit({
                    ...firePit,
                    expiresAt: new Date().getTime() - this.timeOffset + firePit.construction?.lightSourceDuration
                }))
                return;
            }

            // If you are stood on a guard house
            const guardHouse = this.nearbyConstructions.find(construction => (construction.position.x === this.character.position.x && construction.position.y === this.character.position.y && construction.construction?.name?.indexOf('Guard House') > -1));
            if (guardHouse) {
                store.dispatch(showGuardHouse({ ...guardHouse }))
                return;
            }

            // If person near you
            if (this.nearbyCharacters.length === 1 && !this.isCharacterActionsOpen) {
                options.push({
                    text: `Interact with ${this.nearbyCharacters[0].name}`,
                    action: () => {
                        store.dispatch(showCharacterActions({ focussedCharacterId: this.nearbyCharacters[0]._id }))
                        return;
                    }
                })
            }

            if (options.length > 1) {
                store.dispatch(showActionSelectMenu({ options }))
                this.audioService.playSound('openMenu');
            }

            if (options.length === 1) {
                options[0].action();
                return;
            }

            // Wait
            if (this.character?._id === 'mockCharacter') {
                return
            }

            if (this.isWaiting) {
                return;
            }

            this.isWaiting = true;

            store.dispatch(updatePositionAsync({ ...this.character }))
                .finally(() => {
                    setTimeout(() => {
                        this.isWaiting = false
                    }, 100)
                })
        })

        // Character Profile
        // scene.input.keyboard.on('keydown-P', (event) => {
        //     if (this.isInputListenerActive && !this.isCutscene) {
        //         store.dispatch(showCharacterProfile());
        //         this.audioService.playSound('openMenu');
        //     }
        // })

        // Character Equipment
        scene.input.keyboard.on('keydown-Q', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showEquipment());
                this.audioService.playSound('openMenu');
            }
        })

        // Character Sheet
        scene.input.keyboard.on('keydown-T', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showSheet());
                this.audioService.playSound('openMenu');
            }
        })

        // Workshop
        scene.input.keyboard.on('keydown-K', (event) => {
            this.getStoreValues();
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showWorkshop({ workshopTypeId: 'workshopSelect', selectedCharacterId: this.character._id }));
                this.audioService.playSound('openMenu');
            }
        })

        // Conversation Tree Wizard
        scene.input.keyboard.on('keydown-Z', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showConversationTreeWizard());
                this.audioService.playSound('openMenu');
            }
        })

        // Events
        scene.input.keyboard.on('keydown-V', (event) => {
            if (this.isInputListenerActive && !this.isCutscene) {
                store.dispatch(showEvents());
                this.audioService.playSound('openMenu');
            }
        })

        // Talk to Town
        scene.input.keyboard.on('keydown-J', async (event) => {
            this.getStoreValues();
            if (this.isInputListenerActive) {
                if (!this.isCutscene && !this.isEmbarkWaitingRoom) {

                    this.audioService.playSound('openMenu');

                    if (this.nearbyCharacters.length > 0) {
                        // choose whether to talk to world or a specific character
                        store.dispatch(showActionSelectMenu({
                            options: [ 
                                {
                                    text: `Talk to location`,
                                    action: () => {
                                        store.dispatch(newTownMessage());
                                        return;
                                    }
                                },
                                ...this.nearbyCharacters.map(character => {
                                    return {
                                        text: `Talk to ${character.name}`,
                                        action: () => {
                                            store.dispatch(newTownMessage({ focussedCharacterId: character._id }));
                                            return;
                                        }
                                    }
                                })
                            ]
                        }))
                    } else {
                        store.dispatch(newTownMessage());
                    }
                }

                if (this.isEmbarkWaitingRoom) {
                    store.dispatch(newTownMessage());
                }
            }
        })

        // Talk to Town
        scene.input.keyboard.on('keydown-C', async (event) => {
            this.getStoreValues();
            if (this.isInputListenerActive) {

                if (!this.isCutscene && !this.isEmbarkWaitingRoom) {

                    if (isShiftPressed) {
                        store.dispatch(dismissCurrentPrompt({ prompt: this.currentPrompt }))
                        return;
                    }


                    store.dispatch(newTownMessage({ focussedCharacterId: this.character._id }));

                    this.audioService.playSound('openMenu');
                    return;
                }
            }
        })

        // Create a new Organisation
        scene.input.keyboard.on('keydown-O', async (event) => {
            if (this.isInputListenerActive) {
                // store.dispatch(newOrganisation());
                store.dispatch(showOrganisationList())
                this.audioService.playSound('openMenu');
                return;
            }
        })

        // Repeat Last Craft
        // scene.input.keyboard.on('keydown-R', (event) => {
        //     if (this.isInputListenerActive) {
        //         this.lastCraft.callback(this.lastCraft.args);
        //     }
        // })

        // Show Help
        scene.input.keyboard.on('keydown-FORWARD_SLASH', (event) => {
            if (this.isInputListenerActive) {
                store.dispatch(showHelp());
                this.audioService.playSound('openMenu');
            }
        })
    }
}

export default ActionsService;
