import {
    CREATE_TENT_LOADING,
    CREATE_TENT_SUCCESS,
    CREATE_TENT_FAILURE,
    GET_TENTS_LOADING,
    GET_TENTS_SUCCESS,
    GET_TENTS_FAILURE,
    ADD_TENT,
    UPDATE_TENT,
    REMOVE_TENT,
    ERECT_TENT_SUCCESS,
    GET_TRADE_TENTS_SUCCESS,
    GET_TRADE_TENTS_FAILURE
} from '../actionTypes'
import store from '../store';
import { showUnknownError } from './keyboard-shortcuts.actions';
import { client } from '../../services/client';
import { getTentBoundries } from '../../services/tent-helpers';
import { colyClient } from '../../services/Panel-initialisation.service';

import { moveItemFailure } from './move-item.actions';

export const createTentSuccess = payload => ({
    type: CREATE_TENT_SUCCESS,
    payload
})

export const createTentFailure = () => ({
    type: CREATE_TENT_FAILURE
})

export const createTentAsync = payload => dispatch => {
    const { _id, tentTypeId, selectedCharacterId, workshopId } = payload;

    colyClient.room.send('doSuperAction', {
        serviceName: 'tent-instances',
        name: 'create',
        data: {
            tentTypeId,
            selectedCharacterId,
            workshopId
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const getTentsSuccess = payload => {
    return ({
        type: GET_TENTS_SUCCESS,
        payload
    })
}

export const getTentsFailure = () => ({
    type: GET_TENTS_FAILURE
})

export const getTentsAsync = payload => dispatch => {
    let query = { $or: [{ panelId: payload.panelId, z: payload.z}, { characterId: payload.characterId}] }

    if (payload.isNotFirstInitialisation) {
        query = { panelId: payload.panelId, z: payload.z}
    }

    return client.service('tent-instances').find({ paginate: false, query })
        .then((response) => {
            response = response.map(tent => ({ ...tent, tentType: payload.tentTypes.find(type => type._id === tent.tentTypeId) }))
            dispatch(getTentsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const getTradeTentsSuccess = payload => {
    return ({
        type: GET_TRADE_TENTS_SUCCESS,
        payload
    })
}

export const getTradeTentsFailure = () => ({
    type: GET_TRADE_TENTS_FAILURE
})

export const getTradeTentsAsync = payload => dispatch => {
    return client.service('tent-instances').find({ query: { characterId: payload.characterId, markedForTradeCount: { $gte: 1 } }})
        .then((response) => {
            response = response.map(tent => ({ ...tent, tentType: payload.tentTypes.find(type => type._id === tent.tentTypeId) }))
            dispatch(getTradeTentsSuccess(response));
        })
        .catch((error) => {
            dispatch(showUnknownError({ message: error.message }));
            console.error('error', error);
            throw new Error(error);
        })
}

export const addTentToTileAsync = payload => dispatch => {
    const { tentInstance, tileId, tileTypeId } = payload;
    const { tentTypeId, quantity, recipeId, characterId, _id } = tentInstance;

    colyClient.room.send('doAction', {
        serviceName: 'tent-instances',
        commandType: 'create',
        data: {
            tentTypeId,
            quantity,
            tileId,
            tileTypeId,
            panelId: "",
            position: {},
            characterId: ""
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addTentToCharacterAsync = payload => dispatch => {
    const { tentInstances, characterId } = payload;
    const { createdAt, tentTypeId, quantity, recipeId, _id } = tentInstances[0];

    colyClient.room.send('doSuperAction', {
        serviceName: 'tent-instances',
        name: 'fetch',
        data: {
            tentTypeId,
            quantity,
            tileId: "",
            characterId,
            instanceIds: tentInstances.map(inst => inst._id)
        },
        params: {
            query: {
                movementFrom: {
                    _id: undefined
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const erectTentAsync = payload => dispatch => {
    const { panelId, characterId, position, z, tentDirection, tentType, _id } = payload;

    if (!tentType) {
        console.log('error with tent type pal');
    }

    const tentBoundries = getTentBoundries(position, tentDirection, tentType);

    console.log('tentBoundries', tentBoundries);

    colyClient.room.send('doAction', {
        serviceName: 'tent-instances',
        commandType: 'patch',
        data: {
            _id,
            panelId,
            characterId: '',
            tentBoundries,
            z
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const erectTentSuccess = payload => {
    return ({
        type: ERECT_TENT_SUCCESS,
        payload
    })
}

export const dismantleTentAsync = payload => dispatch => {
    const { tentInstance, characterId } = payload;
    const { createdAt, tentTypeId, quantity, recipeId, _id } = tentInstance;

    colyClient.room.send('doAction', {
        serviceName: 'tent-instances',
        commandType: 'create',
        data: {
            panelId: '',
            characterId,
            tentTypeId,
            tentBoundries: []
        },
        params: {
            query: {
                movementFrom: {
                    _id
                }
            }
        }
    })

    return new Promise((resolve, reject) => (resolve()))
}

export const addTent = payload => {
    return ({
        type: ADD_TENT,
        payload
    })
}

export const updateTent = payload => {
    return ({
        type: UPDATE_TENT,
        payload
    })
}

export const removeTent = payload => {
    return ({
        type: REMOVE_TENT,
        payload
    })
}
