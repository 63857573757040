import {
  BEGIN_UPDATE_POSITION,
  BEGIN_UPDATE_EMBARK_POSITION,
  GET_CHARACTER_SUCCESS,
  GET_CHARACTER_FAILURE,
  UPDATE_POSITION_SUCCESS,
  UPDATE_EMBARK_POSITION_SUCCESS,
  UPDATE_POSITION_FAILURE,
  UPDATE_EMBARK_POSITION_FAILURE,
  FIGHT_CHARACTER_SUCCESS,
  JOIN_EMBARK_GROUP_SUCCESS,
  EQUIP_TENT_LOCALLY,
  UNEQUIP_TENT_LOCALLY,
  SET_TENT_DIRECTION,
  ERECT_TENT_SUCCESS,
  EQUIP_WORKSHOP_LOCALLY,
  UNEQUIP_WORKSHOP_LOCALLY,
  SET_WORKSHOP_DIRECTION,
  ERECT_WORKSHOP_SUCCESS,
  SOLO_EMBARK_LOADING,
  NEW_EMBARK_GROUPS_SUCCESS,
  UPDATE_CUTSCENE_POSITION_SUCCESS,
  UPDATE_CHARACTER,
  SOLO_EMBARK_SUCCESS,
  BACK_TO_START_PAGE,
  SHOW_CHARACTER_DEATH_PAGE,
  START_NEW_GAME,
  INITIALISING_PANEL_STARTED,
  CHANGE_CHARACTER,
  REMOVE_CHARACTER,
  REMOVE_POSSIBLE_CHARACTER,
} from "../actionTypes";

const initialState = {
};

export default function(state = initialState, action) {
  switch (action.type) {

    case BEGIN_UPDATE_POSITION:
    case BEGIN_UPDATE_EMBARK_POSITION: {
      const character = { ...action.payload };
      const { position } = character;

      return {
        ...state,
        position,
        isWalking: true
      };
    }

    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
        isDead: true,
        possibleCharacters: state.possibleCharacters
      }
    }

    case BACK_TO_START_PAGE: 
    case START_NEW_GAME:
    case SOLO_EMBARK_SUCCESS: {
      return {
        ...initialState,
        possibleCharacters: state.possibleCharacters
      }
    }

    case JOIN_EMBARK_GROUP_SUCCESS:
    case GET_CHARACTER_SUCCESS: {
      const { character , characters } = action.payload;

      const isNewCharacter = !state.possibleCharacters?.find(possChar => (possChar._id === character._id))

      const possibleCharacters = [...(state.possibleCharacters || [])]

      if (isNewCharacter) {
        possibleCharacters.push(character)
      }

      return {
        ...state,
        ...character,
        serverPosition: { ...character.position },
        possibleCharacters: characters || possibleCharacters
      };
    }

    case UPDATE_CHARACTER: {
      if (state._id !== action.payload._id) {
        return {
          ...state
        }
      }

      if (state.isCharacterDead) {
        return {...state}
      }

      return {
        ...state,
        isWalking: false,
        ...action.payload
      };
    }

    case GET_CHARACTER_FAILURE: {
      return {
        ...initialState,
        isCharacterDead: !!state._id,
        possibleCharacters: action.payload.characters
      }
    }

    case CHANGE_CHARACTER: {
      return {
        ...initialState,
        possibleCharacters: state.possibleCharacters
      }
    }

    case FIGHT_CHARACTER_SUCCESS: {
      if (!action.payload.isAlive) {
        return {
          ...initialState,
          possibleCharacters: state.characters
        }
      } else {
        return {
          ...state
        }
      }
    }

  case UPDATE_CUTSCENE_POSITION_SUCCESS: {
    return {
        ...state,
        ...action.payload,
        isWalking: false,
        serverPosition: { ...action.payload.position }
      };
  }

    case UPDATE_POSITION_SUCCESS:
    case UPDATE_EMBARK_POSITION_SUCCESS :{
      return {
        ...state,
        ...action.payload,
        position: action.payload.position,
        isWalking: false,
        serverPosition: { ...action.payload.position }
      };
    }

    case UPDATE_POSITION_FAILURE:
    case UPDATE_EMBARK_POSITION_FAILURE: {
      return {
        ...state,
        ...action.payload,
        isWalking: false,
        position: { ...state.serverPosition }
      };
    }

    case EQUIP_TENT_LOCALLY: {
      return {
        ...state,
        tentId: action.payload._id,
        tentTypeId: action.payload.tentTypeId,
        tentDirection: 'right'
      }
    }

    case EQUIP_WORKSHOP_LOCALLY: {
      return {
        ...state,
        workshopId: action.payload._id,
        workshopType: action.payload.workshopType,
        workshopDirection: 'right'
      }
    }

    case ERECT_TENT_SUCCESS:
    case UNEQUIP_TENT_LOCALLY: {
      return {
        ...state,
        tentId: undefined,
        tentTypeId: undefined,
        tentDirection: undefined
      };
    }

    case ERECT_WORKSHOP_SUCCESS:
    case UNEQUIP_WORKSHOP_LOCALLY: {
      return {
        ...state,
        workshopId: undefined,
        workshopTypeId: undefined,
        workshopDirection: undefined
      };
    }

    case SET_TENT_DIRECTION: {
      return {
        ...state,
        tentDirection: action.payload.tentDirection
      }
    }

    case SET_WORKSHOP_DIRECTION: {
      return {
        ...state,
        workshopDirection: action.payload.workshopDirection
      }
    }

    case SOLO_EMBARK_LOADING: {
      return {
        ...state,
        isTeleporting: true,
      }
    }

    case REMOVE_POSSIBLE_CHARACTER: {
      if (!state.possibleCharacters) {
        return state;
      }

      return {
        ...state,
        possibleCharacters: [ ...state.possibleCharacters.filter(character => (character._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
