import { isBefore } from 'date-fns';
import * as ItemTileIcons from './selectors/item-tile-icons';
import * as Recipes from './selectors/recipes';
import * as Types from './selectors/types';

import { BALANCE } from '../services/balance-config';
import { PANEL_WIDTH, PANEL_HEIGHT } from '../services/geography';

import { getTentBoundries } from '../services/tent-helpers';

export const selectItemTileIcons = ItemTileIcons.selectItemTileIcons;
export const selectItemTileIconsForTile = ItemTileIcons.selectItemTileIconsForTile;

export const selectJewelleryRecipes = Recipes.selectJewelleryRecipes;
export const selectArmourRecipes = Recipes.selectArmourRecipes;
export const selectWeaponRecipes = Recipes.selectWeaponRecipes;
export const selectFurnitureRecipes = Recipes.selectFurnitureRecipes;
export const selectTileRecipes = Recipes.selectTileRecipes;
export const selectWritingImplementRecipes = Recipes.selectWritingImplementRecipes;
export const selectWritingSurfaceRecipes = Recipes.selectWritingSurfaceRecipes;
export const selectLockRecipes = Recipes.selectLockRecipes;
export const selectToolRecipes = Recipes.selectToolRecipes;
export const selectMetalRecipes = Recipes.selectMetalRecipes;
export const selectMaterialRecipes = Recipes.selectMaterialRecipes;
export const selectMachineRecipes = Recipes.selectMachineRecipes;
export const selectFoodRecipes = Recipes.selectFoodRecipes;
export const selectClothingRecipes = Recipes.selectClothingRecipes;
export const selectConstructionRecipes = Recipes.selectConstructionRecipes;
export const selectBoatRecipes = Recipes.selectBoatRecipes;
export const selectBrainchipRecipes = Recipes.selectBrainchipRecipes;
export const selectTentRecipes = Recipes.selectTentRecipes;
export const selectWorkshopRecipes = Recipes.selectWorkshopRecipes;
export const selectWagonRecipes = Recipes.selectWagonRecipes;
export const selectRecipesByWorkshopTypeId = Recipes.selectRecipesByWorkshopTypeId;
export const selectWorkshopUpgradeRecipe = Recipes.selectWorkshopUpgradeRecipe;
export const selectCoinRecipes = Recipes.selectCoinRecipes;
export const selectAllRecipes = Recipes.selectAllRecipes;

export const selectSkills = Types.selectSkills;
export const selectMineralTypes = Types.selectMineralTypes;
export const selectToolTypes = Types.selectToolTypes;
export const selectMetalTypes = Types.selectMetalTypes;
export const selectKeyTypes = Types.selectKeyTypes;
export const selectMachineTypes = Types.selectMachineTypes;
export const selectFurnitureTypes = Types.selectFurnitureTypes;
export const selectWritingSurfaceTypes = Types.selectWritingSurfaceTypes;
export const selectWritingImplementTypes = Types.selectWritingImplementTypes;
export const selectWeaponTypes = Types.selectWeaponTypes;
export const selectArmourTypes = Types.selectArmourTypes;
export const selectClothingTypes = Types.selectClothingTypes;
export const selectJewelleryTypes = Types.selectJewelleryTypes;
export const selectFoodTypes = Types.selectFoodTypes;
export const selectLockTypes = Types.selectLockTypes;
export const selectAnimalTypes = Types.selectAnimalTypes;
export const selectFishTypes = Types.selectFishTypes;
export const selectConstructionTypes = Types.selectConstructionTypes;
export const selectBoatTypes = Types.selectBoatTypes;
export const selectBrainchipTypes = Types.selectBrainchipTypes;
export const selectTentTypes = Types.selectTentTypes;
export const selectWorkshopTypes = Types.selectWorkshopTypes;
export const selectWagonTypes = Types.selectWagonTypes;
export const selectCoinTypes = Types.selectCoinTypes;
export const selectBrainchipTypeByIds = Types.selectBrainchipTypeByIds;

export const selectServerTimeOffset = (store) => {
	return store && store.clock ?
		store.clock.timeOffset
		: 0
}

export const selectConnectionState = (store) => {
	return store && store.connectionState ?
		store.connectionState.connectionState
		: undefined
}

export const selectIsCharacterDeathPageShowing = (store) => {
	return store && store.keyboardShortcuts ?
		store.keyboardShortcuts.isCharacterDeathPageShowing
		: false
}

export const selectIsNameLocationShowingShowing = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isNameLocationShowing
}

export const selectIsOrganisationListShowing = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isOrganisationListShowing
}

export const selectIsZoneListShowing = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isZoneListShowing
}

export const selectIsZoneShowing = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isZoneShowing
}

export const selectIsInitialisingApp = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isInitialisingApp
}

export const selectIsInitialisingPanel = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isInitialisingPanel
}

export const selectIsFirstInitialisation = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isFirstInitialisation
}

export const selectIsLevelUpMessageShowing = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isLevelUpMessageShowing
}

export const selectIsNearDeathScreenShowing = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isNearDeathScreenShowing
}

export const selectIsTerminalShowing = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isTerminalShowing
}

export const selectIsNewTownMessageShowing = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isNewTownMessageShowing
}

export const selectIsGuardHouseShowing = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isGuardHouseShowing
}

export const selectHighlight = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.highlight
}

export const selectAiming = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.aiming
}

export const selectAreaSelect = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.areaSelect
}

export const selectMoving = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.moving
}

export const selectHighlightCoords = (store) => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.coords
}

export const selectCharacter = (store) => {
	return store && store.character && store.character
	    ? {
	    	...store.character,
	    	skills: store.character?.skillIds?.map(({_id}) => store.skillTypes?.find(skill => skill._id === _id) ),
	    	clothingItems: store.character.clothingItems?.map(clothingItem => addClothingTypes(clothingItem, store.clothingTypes, store.jewelleryTypes)),
            armourType: store.armourTypes?.find(armourType => armourType._id === store.character.armourTypeId),
            weaponType: store.weaponTypes?.find(weaponType => weaponType._id === store.character.weaponTypeId)
	    }
	    : {}
};

export const selectInventoryWeight = (store) => {
	const inventory = selectInventory(store)
    const food = selectCharacterFood(store)
    const tiles = selectTiles(store);
    const character = selectCharacter(store);
    const minerals = selectCharacterMinerals(store);

    const tools = selectCharacterTools(store);
    const metals = selectCharacterMetals(store);
    const keys = selectCharacterKeys(store);
    const itemTileType = selectTileTypeByName(store, 'ITEM')
    const writingSurfaces = selectCharacterWritingSurfaces(store);
    const writingImplements = selectCharacterWritingImplements(store);
    const weapons = selectCharacterWeapons(store);
    const armour = selectCharacterArmour(store);
    const clothing = selectCharacterClothing(store);
    const jewellery = selectCharacterJewellery(store);
    const tents = selectCharacterTents(store);
    const locks = selectCharacterLocks(store);

    const isQuantityInputShowing = selectIsQuantityInputOpen(store);
    const isCharacterListShowing = selectIsCharacterListShowing(store);
    const neighbouringCharacters = selectNeighbouringCharacters(store, character);
    const containers = selectCharacterTiles(store);

    const looseTileType = selectTileTypeByName(store, 'ITEMS')

    let maxInventoryWeight = BALANCE.BASE_CHARACTER_WEIGHT;

    if (character.clothingItems) {
        maxInventoryWeight = character.clothingItems.reduce((total, item) => {
            return total + (Number(item.type?.inventoryIncrease) || 0)
        }, BALANCE.BASE_CHARACTER_WEIGHT)
    }

    containers.byId.forEach(tile => {
        tile.inventory =  [ ...selectTileInventory(store, tile._id) ];
        tile.food =  [ ...selectTileFood(store, tile._id) ];
        tile.minerals =  [ ...selectTileMinerals(store, tile._id) ];
        tile.tools =  [ ...selectTileTools(store, tile._id) ];
        tile.metals =  [ ...selectTileMetals(store, tile._id) ];
        tile.keys =  [ ...selectTileKeys(store, tile._id) ];
        tile.writingSurfaces =  [ ...selectTileWritingSurfaces(store, tile._id) ];
        tile.writingImplements =  [ ...selectTileWritingImplements(store, tile._id) ];
        tile.weapons =  [ ...selectTileWeapons(store, tile._id) ];
        tile.armour =  [ ...selectTileArmour(store, tile._id) ];
        tile.clothing =  [ ...selectTileClothing(store, tile._id) ];
        tile.jewellery =  [ ...selectTileJewellery(store, tile._id) ];
        tile.tents =  [ ...selectTileTents(store, tile._id) ];
        tile.locks =  [ ...selectTileLocks(store, tile._id) ];
    })

    containers.byId.push({
        _id: 'loose',
        inventory: inventory.inventory,
        food,
        minerals,
        tools,
        metals,
        keys,
        writingSurfaces,
        writingImplements,
        weapons,
        armour,
        clothing,
        jewellery,
        tents,
        locks,
        coins: []
    })

    let totalWeight = 0;

    containers.byId.forEach(tile => {
    	const itemTypes = [
    		'inventory',
    		'food',
	        'minerals',
	        'tools',
	        'metals',
	        'keys',
	        'writingSurfaces',
	        'writingImplements',
	        'weapons',
	        'armour',
	        'clothing',
	        'jewellery',
	        'tents',
	        'locks',
		]

		itemTypes.forEach(type => {
			tile[type].forEach(item => {
				const typeKey = Object.keys(item).find(item => item.indexOf('Type') > -1 && item.indexOf('TypeId') === -1);

				if (!item[typeKey]) {
					console.error('No item found', typeKey)
					return totalWeight;
				}

				totalWeight += (item.quantity || 1) * item[typeKey].weight;
			})
		})
    })

    return totalWeight;
}

export const selectMaximumInventoryWeight = (store) => {
	let maxInventoryWeight = BALANCE.BASE_CHARACTER_WEIGHT;

	const character = selectCharacter(store);

	if (character.clothingItems) {
	    maxInventoryWeight = character.clothingItems.reduce((total, item) => {
	        return total + (Number(item.type?.inventoryIncrease) || 0)
	    }, BALANCE.BASE_CHARACTER_WEIGHT)
    }

    return maxInventoryWeight;
}

export const selectLocationWeight = (state, position) => {
	const tiles = selectCurrentTiles(state, position);

	let tileContents = {};
    let weight = 0;

    function getWeight(item, itemTypeKey) {
    	if (!item[itemTypeKey]?.weight) {
    		// console.log('no weight found for ', item);
    		return 0;
    	}

        return (item.quantity || 1) * item[itemTypeKey]?.weight;
    }

    tiles.byId.forEach(tile => {
    	weight += getWeight(tile, 'tileType');

    	selectTileInventory(state, tile._id).forEach(item => (weight += getWeight(item, 'materialType')));
    	selectTileFood(state, tile._id).forEach(item => (weight += getWeight(item, 'foodType')));
    	selectTileMinerals(state, tile._id).forEach(item => (weight += getWeight(item, 'mineralType')));
    	selectTileTools(state, tile._id).forEach(item => (weight += getWeight(item, 'toolType')));
    	selectTileMetals(state, tile._id).forEach(item => (weight += getWeight(item, 'metalType')));
    	selectTileKeys(state, tile._id).forEach(item => (weight += getWeight(item, 'keyType')));
    	selectTileWritingSurfaces(state, tile._id).forEach(item => (weight += getWeight(item, 'writingSurfaceType')));
    	selectTileWritingImplements(state, tile._id).forEach(item => (weight += getWeight(item, 'writingImplementType')));
    	selectTileWeapons(state, tile._id).forEach(item => (weight += getWeight(item, 'weaponType')));
    	selectTileArmour(state, tile._id).forEach(item => (weight += getWeight(item, 'armourType')));
    	selectTileClothing(state, tile._id).forEach(item => (weight += getWeight(item, 'clothingType')));
    	selectTileJewellery(state, tile._id).forEach(item => (weight += getWeight(item, 'jewelleryType')));
    	selectTileTents(state, tile._id).forEach(item => (weight += getWeight(item, 'tentType')));
    	selectTileLocks(state, tile._id).forEach(item => (weight += getWeight(item, 'lockType')));
    	selectTileTiles(state, tile._id).forEach(item => (weight += getWeight(item, 'tileType')));
    	selectTileCoins(state, tile._id).forEach(item => (weight += getWeight(item, 'coinType')));
    })

    return weight;
}

export const selectConversationTree = (store, characterConversationTree) => {
	if (!characterConversationTree || characterConversationTree.length === 0) {
		return [];
	}

	const conversationTree = characterConversationTree.map(item => ({ ...item }))

    let depth = 30;

    function findParent(conversationTree, childItem) {
        let childItemIndex;

        for (var i = conversationTree.length - 1; i > -1; i--) {
            const item = conversationTree[i];

            if (childItem === item) {
                childItemIndex = i;
            }

        	// parent is one step up
            if (childItem.indentation % 2 === 0) {
	            if (item.indentation === childItem.indentation - 1 && childItemIndex) {
	                return item;
	            }
            }

            // parent is two steps up
            if (childItem.indentation % 2 === 1) {
            	if (item.indentation === childItem.indentation - 1 && childItemIndex) {
	                return item;
	            }
            }

        }
    }

    while (depth > 0) {
        depth--;

        conversationTree.forEach((item, index) => {
            if (item.indentation === depth) {
                const parent = findParent(conversationTree, item);

                if (!parent) {
                    return;
                }

                if (parent.indentation % 2 === 1) {
                	parent.response = item.text
                	parent.children = item.children;
                } else {
	                if (!parent.children ) {
	                    parent.children = []
	                }

	                parent.children.push({
	                    ...item,
	                    indentation: 0
	                })
                }
            }
        })
    }

    return conversationTree[0].children
}

function addClothingTypes(clothingItem, clothingTypes, jewelleryTypes) {
    return {
        ...clothingItem,
        type: (
            clothingItem.itemType === 'clothing' ?
            clothingTypes.find(item => item._id === clothingItem.itemTypeId)
            : jewelleryTypes.find(item => item._id === clothingItem.itemTypeId)
        )
    }
}

export const selectCharacterById = (store, id) => {
	return store && store.characters && store.characters
	    ? { ...store.characters.characters.find(character => character._id === id) }
	    : {}
};

export const selectNeighbouringCharacters = (store, currentCharacter) => {
	return store && store.characters && store.characters.characters
	    ? [ ...store.characters.characters
	    	.filter(character => (character._id !== currentCharacter?._id))
	    	.filter((character) => isNear(character.position, currentCharacter.position)
	    	)
	   	]
	    : []
};

export const selectLocks = (store) => {
	return store && store.locks && store.locks.locks
	    ? [
	    	...store.locks.locks
	    ]
	    : []
};

export const selectLocksByConstructions = (store, constructions) => {
	return store && store.locks && store.locks.locks
	    ? [
	    	...store.locks.locks.filter(lock => (constructions.find(construction => (construction._id === lock.constructionId))))
	    ]
	    : []
}

export const selectNearbyCharacters = (store, currentCharacter) => {
	return store && store.characters && store.characters.characters
	    ? [ ...store.characters.characters
	    	.filter(character => (character._id !== currentCharacter?._id))
	    	.filter((character) => isNear(currentCharacter.position, character.position))
	   	]
	    : []
};

export const selectNeighbouringAnimals = (store, currentCharacter) => {
	return store && store.animals && store.animals.animals
	    ? [ ...store.animals.animals
	    	.filter((animal) => animal.position?.x === currentCharacter?.position?.x
	    		&& animal.position?.y === currentCharacter?.position?.y
	    	)
	   	]
	    : []
};

export const selectNearbyAnimals = (store, currentCharacter) => {
	return store && store.animals && store.animals.animals
	    ? [ ...store.animals.animals
	    	.filter((animals) => isNear(currentCharacter.position, animals.position))
	   	]
	    : []
};

export const selectNearbyPlants = (store, currentCharacter) => {
	if (!store?.plants?.byId) {
		return []
	}

	const plants = Object.values(store.plants.byId);

	return plants
	    ? [ ...plants
	    	.filter((plants) => isNear(currentCharacter.position, plants.position))
	   	]
	    : []
};

export const selectNeighbouringPlants = (store, currentCharacter) => {
	if (!store?.plants?.byId) {
		return []
	}

	const plants = Object.values(store.plants.byId);

	return plants
	    ? [ ...plants
	    	.filter((plant) => plant.position?.x === currentCharacter?.position?.x
	    		&& plant.position?.y === currentCharacter?.position?.y
	    	)
	   	]
	    : []
};

export const selectNeighbouringWagons = (store, currentCharacter) => {
	return store && store.wagons && store.wagons.wagons
	    ? [ ...store.wagons.wagons
	    	.filter((wagon) => wagon.position?.x === currentCharacter?.position?.x
	    		&& wagon.position?.y === currentCharacter?.position?.y
	    	)
	   	]
	    : []
};

export const selectNeighbouringDeadCharacters = (store, currentCharacter) => {
	return store && store.deadCharacters && store.deadCharacters.deadCharacters
	    ? [ ...store.deadCharacters.deadCharacters
	    	.filter((character) => character.position.x === currentCharacter?.position?.x
	    		&& character.position.y === currentCharacter?.position?.y
	    		&& character._id !== currentCharacter?._id
	    	)
	   	]
	    : []
};

export const selectTileMachines = (store, currentCharacter) => {
	return store && store.machines && store.machines.machines 
		? [ ...store.machines.machines.filter(machine => (machine.position.x === currentCharacter?.position?.x && machine.position.y === currentCharacter?.position?.y)) ]
		: []
}

export const selectUser = (store) => {
	return store && store.user
		? { ...store.user }
		: {}
}

export const selectPanel = store => {
	return store && store.panel
		? { ...store.panel }
		: {}
}

export const selectFloorLayerItemAtPosition = (store, position) => {
	return store && store.panel && store.panel.floorLayer && store.panel?.floorLayer?.find(item => item.x === position.x && item.y === position.y)
		? { ...store.panel?.floorLayer?.find(item => item.x === position.x && item.y === position.y) }
		: undefined
}

export const selectTiles = store => {
	return store && store.tiles
		? { ...store.tiles }
		: {}
}

export const selectTileTiles = (store, tileId) => {
	const byId = Object.values(store.tiles?.byId).filter(tile => (tile.tileId === tileId));

	return [ ...byId ]
}

export const selectCurrentTiles = (store, position) => {
	if (!store?.tiles) {
		return;
	}

	const byId = Object.values(store.tiles?.byId).filter(tile => Number(tile.position?.x) === Number(position?.x) && Number(tile.position?.y) === Number(position?.y));
	const allIds = Object.values(byId).map(tile => tile.tileId);

	return store && store.tiles
		? { allIds, byId }
		: {}
}

export const selectCharacterTiles = (store) => {
	if (!store?.tiles) {
		return;
	}

	const byId = Object.values(store.tiles?.byId).filter(tile => tile.characterId);
	const allIds = Object.values(byId).map(tile => tile.characterId);

	return store && store.tiles
		? { allIds, byId }
		: {}
}

export const selectOurTradeTiles = (store) => {
	if (!store?.tiles) {
		return;
	}

	const byId = Object.values(store.tiles?.byId).filter(tile => tile.characterId && tile.markedForTradeCount);
	const allIds = Object.values(byId).map(tile => tile.characterId && tile.markedForTradeCount);

	return store && store.tiles
		? { allIds, byId }
		: {}
}

export const selectPartnerTradeTiles = (store) => {
	return store && store.tiles && store.tiles.tradeTiles
		? [ ...store.tiles.tradeTiles ]
		: []
}

export const selectInventory = store => {
	return store && store.inventory
		? { ...store.inventory, inventory: [ ...store.inventory.inventory.filter(item => item.characterId)] }
		: {}
}

export const selectPartnerTradeInventory = store => {
	if (!store.inventory?.tradeInventory) {
		return {};
	}

	return store && store.inventory
		? { ...store.inventory, inventory: [ ...store.inventory?.tradeInventory] }
		: {}
}

export const selectOurTradeInventory = store => {
	return store && store.inventory
		? { ...store.inventory, inventory: [ ...store.inventory.inventory.filter(item => item.characterId && item.markedForTradeCount)] }
		: {}
}

export const selectCharacterSeeds = store => {
	return store && store.inventory
		? [ ...store.inventory.inventory.filter(item => item.characterId && item.materialType?.name === 'Seed')]
		: []
}

export const selectTileInventory = (store, tileId) => {
	return store && store.inventory && store.inventory.tileInventory
		? [ ...store.inventory.tileInventory.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectPlants = store => {
	return store && store.plants
		? { ...store.plants }
		: {}
}

export const selectPlantInstanceIdAtTile = (store, position) => {
	if (!position) {
		return;
	}

	if (store && store.plants && Object.entries(store.plants.byId) && Object.entries(store.plants.byId).length > 0) {
		const plantInstanceEntry = Object.entries(store.plants.byId).find(entry => {
			if (!entry || !entry[1] || !entry[1].position) {
				return;
			}

			return entry[1].position.x === position.x && entry[1].position.y === position.y
		});

		return plantInstanceEntry && plantInstanceEntry[0];
	} else {
		return undefined;
	}
}

export const selectPlantById = (store, plantId) => {
	return store && store.plants && store.plants.byId[plantId];
}

export const selectConstructions = store => {
	return store && store.constructions
		? { ...store.constructions }
		: {}
}

export const selectForegroundConstructions = store => {
	return store && store.constructions
		? {
			...store.constructions,
			byId: Object.values(store.constructions.byId).filter(construction => (construction.construction?.name.indexOf('Floor') === -1))
		} : {}
}

export const selectBackgroundConstructions = store => {
	return store && store.constructions
		? {
			...store.constructions,
			byId: Object.values(store.constructions.byId).filter(construction => (construction.construction?.name.indexOf('Floor') > -1))
		} : {}
}

export const selectNearbyConstructions = (store, character) => {
	return store && store.constructions
		? Object.values(store.constructions.byId).filter((construction) => {
			return isNear(construction.position, character.position);
		})
		: []
}

export const selectConstructionByPosition = (store, position) => {
	return store && store.constructions
		? Object.values(store.constructions.byId).filter((construction) => {
			if (construction.position?.x === undefined || position?.x === undefined) {
				console.error('SHIIIT', construction, position)
			}
			return construction.position?.x === position?.x && construction.position?.y === position?.y
		})
		: []
}

export const selectFurnitureByPosition = (store, position) => {
	return store && store.furniture
		? Object.values(store.furniture.furniture).filter((furniture) => {
			return furniture.position.x === position.x && furniture.position.y === position.y;
		})
		: undefined
}

export const selectNearbyFurniture = (store, character) => {
	const furnitureArray = Object.values(store.furniture.furniture).filter((furniture) => {
		return isNear(furniture.position, character.position);
	})

	return furnitureArray;
}

export const selectFurnitureRecipe = (store, furniture) => {
	return store && store.furnitureRecipes
		? [ ...store.furnitureRecipes.filter(furnitureRecipe => furnitureRecipe.furnitureTypeId === furniture.furnitureTypeId) ]
		: []
}

export const selectNearbyContainers = (store, character) => {
	return store && store.tiles
		? Object.values(store.tiles.byId).filter((container) => {
			return (container.position.x === character.position.x && character.position.y === container.position.y)
				&& (container.tileType.name !== 'ITEM' && container.tileType.name !== 'FARM_PLOT');
		})
		: []
}

export const selectTilesByPosition = (store, positions) => {
	return store && store.tiles
		? Object.values(store.tiles.byId).filter((container) => {
			return positions.find(candidatePosition => (candidatePosition.x === container.position.x && candidatePosition.y === container.position.y))
		})
		: []
}

export const selectNearbyFarmPlot = (store, character) => {
	return store && store.tiles
		? Object.values(store.tiles.byId).filter((container) => {
			return (container.position?.x === character.position?.x && character.position?.y === container.position?.y)
				&& (container.tileType?.name === 'FARM_PLOT');
		})
		: []
}

function isNear(itemPosition, characterPosition) {
	const xDelta = itemPosition?.x - characterPosition?.x;
	const yDelta = itemPosition?.y - characterPosition?.y;

	const isXClose = xDelta > -2 && xDelta < 2;
	const isYClose = yDelta > -2 && yDelta < 2;

	return isXClose && isYClose;
}

export const selectCharacterFood = store => {
	return store && store.food && store.food.characterFood
		? [ ...store.food.characterFood ].filter(food => food.characterId)
		: []
}

export const selectOurTradeFood = store => {
	return store && store.food && store.food.characterFood
		? [ ...store.food.characterFood ].filter(food => food.characterId && food.markedForTradeCount)
		: []
}

export const selectPartnerTradeFood = store => {
	return store && store.food && store.food.tradeFood
		? [ ...store.food.tradeFood ]
		: []
}

export const selectTileFood = (store, tileId) => {
	return store && store.food && store.food.tileFood
		? [ ...store.food.tileFood.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectDeadCharacters = (store) => {
	return store && store.deadCharacters && store.deadCharacters.deadCharacters
		? [ ...store.deadCharacters.deadCharacters ]
		: []
}

export const selectDeadCharactersByPosition = (store, position) => {
	return store && store.deadCharacters && store.deadCharacters.deadCharacters
		? [ ...store.deadCharacters.deadCharacters.filter(deadCharacter => (deadCharacter?.position?.x === position?.x && deadCharacter?.position?.y === position?.y)) ]
		: []
}

export const selectCharactersByPosition = (store, position) => {
	return store && store.characters && store.characters.characters
		? [ ...store.characters.characters.filter(character => (character.position?.x === position?.x && character.position?.y === position?.y)) ]
		: []
}

export const selectPossibleCharacters = (store) => {
	return store && store.character && store.character.possibleCharacters 
		? store.character.possibleCharacters 
		: []
}

export const selectCharacters = (store, currentCharacterId) => {
	const currentCharacter = selectCharacter(store);

	return store && store.characters && store.characters.characters
		? [ ...store.characters.characters
				.filter(character => (currentCharacter.z ? character.z === currentCharacter.z : !character.z))
				.map(character => {

			// THIS GETS THE CORRECT SPRITE IN THE EMBARK SCREEN:
			if (character._id === 'mockCharacter') {
				return  {
					...character,
					...store.embarkCharacter,
			    	skills: character?.skillIds?.map(({_id}) => store.skillTypes?.find(skill => skill._id === _id) ),
			    	clothingItems: [
				        {
				            type: {
				                name: 'Space trousers'
				            }
				        },
				        {
				            type: {
				                name: 'Space hat'
				            }
				        },
				        {
				            type: {
				                name: 'Space shirt'
				            }
				        },
				        {
				            type: {
				                name: 'Space shoes'
				            }
				        }
				    ],
		            armourType: store.armourTypes?.find(armourType => armourType._id === character.armourTypeId),
		            weaponType: store.weaponTypes?.find(weaponType => weaponType._id === character.weaponTypeId)
				}
			}
			// 

			return {
		    	...character,
		    	skills: character?.skillIds?.map(({_id}) => store.skillTypes?.find(skill => skill._id === _id) ),
		    	clothingItems: character.clothingItems?.map(clothingItem => {
		    		if (clothingItem._id || clothingItem.itemTypeId) {
		    			return addClothingTypes(clothingItem, store.clothingTypes, store.jewelleryTypes)
		    		}
		    		return clothingItem
		    	}),
	            armourType: store.armourTypes?.find(armourType => armourType._id === character.armourTypeId),
	            weaponType: store.weaponTypes?.find(weaponType => weaponType._id === character.weaponTypeId)
		    }
		}) ]
		: []
}

export const selectLightSourceCharacters = (store, currentCharacterId) => {
	return store && store.characters && store.characters.characters
		? [ ...store.characters.characters
		.filter(character => {
			return character.lightSource?.expiresAt - new Date().getTime() - store.clock.timeOffset > 0
		})
		.map(character => {
			return {
		    	...character,
		    	skills: character?.skillIds?.map(({_id}) => store.skillTypes?.find(skill => skill._id === _id) ),
		    	clothingItems: character.clothingItems?.map(clothingItem => addClothingTypes(clothingItem, store.clothingTypes, store.jewelleryTypes)),
	            armourType: store.armourTypes?.find(armourType => armourType._id === character.armourTypeId),
	            weaponType: store.weaponTypes?.find(weaponType => weaponType._id === character.weaponTypeId)
		    }
		}) ]
		: []
}

export const selectMinerals = (store) => {
	return store && store.minerals && store.minerals.minerals
		? [ ...store.minerals.minerals ]
		: []
}

export const selectOurTradeMinerals = (store) => {
	return store && store.minerals && store.minerals.minerals
		? [ ...store.minerals.minerals.filter(mineral => (mineral.markedForTradeCount)) ]
		: []
}

export const selectPartnerTradeMinerals = (store) => {
	return store && store.minerals && store.minerals.tradeMinerals
		? [ ...store.minerals.tradeMinerals ]
		: []
}

export const selectMachines = (store) => {
	return store && store.machines && store.machines.machines
		? [ ...store.machines.machines ]
		: []
}

export const selectMineralAtPosition = (store, position) => {
	return store && store.minerals && store.minerals.minerals
		&& store.minerals.minerals
				.find(mineralInstance => mineralInstance.position && mineralInstance?.position.x === position?.x && mineralInstance?.position.y === position?.y)
		? { ...store.minerals.minerals.find(mineralInstance => mineralInstance?.position && mineralInstance?.position.x === position?.x && mineralInstance?.position.y === position?.y) }
		: undefined
}

export const selectFurnitureAtPosition = (store, position) => {
	return store && store.furniture && store.furniture.furniture
		&& store.furniture.furniture
				.find(mineralInstance => mineralInstance.position && mineralInstance?.position.x === position?.x && mineralInstance?.position.y === position?.y)
		? { ...store.furniture.furniture.find(mineralInstance => mineralInstance?.position && mineralInstance?.position.x === position?.x && mineralInstance?.position.y === position?.y) }
		: undefined
}

export const selectFurnitureAtCharacter = (store) => {
	return store && store.furniture && store.furniture.furniture
		&& store.furniture.characterFurniture
				.find(mineralInstance => mineralInstance.characterId)
		? { ...store.furniture.characterFurniture.find(mineralInstance => mineralInstance.characterId) }
		: undefined
}

export const selectCharacterMinerals = store => {
	return store && store.minerals && store.minerals.characterMinerals
		? [ ...store.minerals.characterMinerals ]
		: []
}

export const selectTileMinerals = (store, tileId) => {
	return store && store.minerals && store.minerals.tileMinerals
		? [ ...store.minerals.tileMinerals.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectCharacterTools = store => {
	return store && store.tools && store.tools.characterTools
		? [ ...store.tools.characterTools ]
		: []
}

export const selectOurTradeTools = store => {
	return store && store.tools && store.tools.characterTools
		? [ ...store.tools.characterTools.filter(mineral => (mineral.markedForTradeCount)) ]
		: []
}

export const selectPartnerTradeTools = store => {
	return store && store.tools && store.tools.tradeTools
		? [ ...store.tools.tradeTools ]
		: []
}

export const selectTileTools = (store, tileId) => {
	return store && store.tools && store.tools.tileTools
		? [ ...store.tools.tileTools.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectCharacterMetals = store => {
	return store && store.metals && store.metals.characterMetals
		? [ ...store.metals.characterMetals ]
		: []
}

export const selectOurTradeMetals = store => {
	return store && store.metals && store.metals.characterMetals
		? [ ...store.metals.characterMetals.filter(mineral => (mineral.markedForTradeCount)) ]
		: []
}
export const selectPartnerTradeMetals = store => {
	return store && store.metals && store.metals.tradeMetals
		? [ ...store.metals.tradeMetals ]
		: []
}

export const selectTileMetals = (store, tileId) => {
	return store && store.metals && store.metals.tileMetals
		? [ ...store.metals.tileMetals.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectTileTypes = (store, name) => {
	return store && store.tileTypes
		? store.tileTypes
		: {}
}

export const selectTileTypeByName = (store, name) => {
	return store && store.tileTypes
		? store.tileTypes.find(tileType => tileType.name === name)
		: {}
}

export const selectMaterialTypes = (store, id) => {
	return store && store.materialTypes
		? store.materialTypes
		: []
}

export const selectPlantTypes = (store, id) => {
	return store && store.plantTypes
		? store.plantTypes
		: []
}

export const selectMessages = store => {
	return store && store.messages && store.messages.messages
		? store.messages.messages
			.sort((a, b) => {
				const aTime = new Date(a.createdAt);
				const bTime = new Date(b.createdAt);

				if (a.createdAt === b.createdAt) {
					return a._id.localeCompare(b._id)
				}

				return isBefore(aTime, bTime) ? -1 : 1;
			})
			.map(message => {
				message.character = store.characters?.characters?.find(character => (character._id === message.characterId));
				message.targetCharacter = store.characters?.characters?.find(character => (character._id === message.targetCharacterId));

				return {
					...message
				}
			})
		: []
}

export const selectMessageOptionss = store => {
	return store && store.messages && store.messages.messageOptions
		? store.messages.messageOptions
		: []
}

export const selectFocussedCharacterId = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.focussedCharacterId
		? store.keyboardShortcuts.focussedCharacterId
		: undefined
}

export const selectFocussedFurnitureId = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.focussedFurnitureId
		? store.keyboardShortcuts.focussedFurnitureId
		: undefined
}

export const selectIsTableShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isTableShowing
		? store.keyboardShortcuts.isTableShowing
		: false
}

export const selectPrompts = store => {
	return store && store.prompts && store.prompts.prompts
		? store.prompts.prompts
		: false
}

export const selectCurrentPrompt = store => {
	return store && store.prompts && store.prompts.prompts
		? store.prompts.prompts[0]
		: false
}

export const selectIsActionSelectShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isActionSelectShowing
		? store.keyboardShortcuts.isActionSelectShowing
		: false
}

export const selectIsCharacterActionsShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isCharacterActionsShowing
		? store.keyboardShortcuts.isCharacterActionsShowing
		: false
}

export const selectIsDirectionInputShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isDirectionInputShowing
		? store.keyboardShortcuts.isDirectionInputShowing
		: false
}

export const selectIsCharacterProfileShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isCharacterProfileShowing
		? store.keyboardShortcuts.isCharacterProfileShowing
		: false
}

export const selectIsEquipmentShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isEquipmentShowing
		? store.keyboardShortcuts.isEquipmentShowing
		: false
}

export const selectIsSheetShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isSheetShowing
		? store.keyboardShortcuts.isSheetShowing
		: false
}

export const selectIsNewOrganisationShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isNewOrganisationShowing
		? store.keyboardShortcuts.isNewOrganisationShowing
		: false
}

export const selectIsWorkshopSelectShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isWorkshopSelectShowing
		? store.keyboardShortcuts.isWorkshopSelectShowing
		: false
}

export const selectIsWorkshopShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isWorkshopShowing
		? store.keyboardShortcuts.isWorkshopShowing
		: false
}

export const selectIsConversationTreeWizardShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isConversationTreeWizardShowing
		? store.keyboardShortcuts.isConversationTreeWizardShowing
		: false
}

export const selectIsEventsShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isEventsShowing
		? store.keyboardShortcuts.isEventsShowing
		: false
}

export const selectIsDiaryShowing = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.isDiaryShowing
		? store.keyboardShortcuts.isDiaryShowing
		: false
}

export const selectIsWritingOnSurface = store => {
	return store && store.keyboardShortcuts && (store.keyboardShortcuts.surfaceService)
		? {
			isEditWritingSurfaceShowing: store.keyboardShortcuts.isEditWritingSurfaceShowing,
			surfaceService: store.keyboardShortcuts.surfaceService,
			surfaceId: store.keyboardShortcuts.surfaceId,
			isEditDrawingSurfaceShowing: store.keyboardShortcuts.isEditDrawingSurfaceShowing,
			isLooking: store.keyboardShortcuts.isLooking,
			item: store.keyboardShortcuts.item,
			workshopId: store.keyboardShortcuts.workshopId
		}
		: false
}

export const selectIsWritingSurfaceShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isEditWritingSurfaceShowing
		: false
}

export const selectIsDrawingSurfaceShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isEditDrawingSurfaceShowing
		: false
}

export const selectWorkshopTypeId = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.workshopTypeId
		? store.keyboardShortcuts.workshopTypeId
		: undefined
}

export const selectWorkshopId = store => {
	return store && store.keyboardShortcuts && store.keyboardShortcuts.workshopId
		? store.keyboardShortcuts.workshopId
		: undefined
}

export const selectCharacterMessages = store => {
	if (!store || !store.messages || !store.messages.messages) {
		return;
	}

	const characterId = store.character._id;

	const messages = store.messages.messages
		.sort((a, b) => {
			if (a.createdAt === b.createdAt) {
				return 1;
			}

			const aTime = new Date(a.createdAt);
			const bTime = new Date(b.createdAt);
			return isBefore(aTime, bTime) ? -1 : 1;
		})
		.filter(message => (!message.embarkCharacterId))

	const characterMessages = {};

	messages.forEach(message => {
		message.character = store.characters.characters.find(character => (character._id === message.characterId))

		let otherCharacterId = characterId !== message.characterId ? message.characterId : message.targetCharacterId;

		if (message.type === 'TALK_TO_WORLD') {
			characterMessages[undefined]?.messages.push(message);
			return;
		}

		if (!characterMessages[otherCharacterId]) {
			characterMessages[otherCharacterId] = { character: undefined, messages: [] }
		}

		characterMessages[otherCharacterId]?.messages.push(message)

		if (!characterMessages[otherCharacterId].character) {
			characterMessages[otherCharacterId].character = store.characters.characters.find(character => (character._id === otherCharacterId))
		}


		if (message.targetFurnitureId) {
			if (!characterMessages[message.targetFurnitureId]) {
				characterMessages[message.targetFurnitureId] = { character: undefined, messages: [] }
			}

			characterMessages[message.targetFurnitureId].messages.push(message)
		}
	})

	return store && store.messages && store.messages.messages
		? characterMessages
		: []
}

export const selectSidebarMessages = store => {
	if (!store || !store.messages || !store.messages.messages) {
		return;
	}

	const characterId = store.character._id;

	const messages = store.messages.messages
		.sort((a, b) => {
			if (a.createdAt === b.createdAt) {
				return a._id.localeCompare(b._id)
			}

			const aTime = new Date(a.createdAt);
			const bTime = new Date(b.createdAt);
			return isBefore(aTime, bTime) ? 1 : -1;
		})
		.filter(message => (!message.embarkCharacterId))

	return store && store.messages && store.messages.messages
		? messages
		: []
}

export const selectEmbarkCharacterMessages = store => {
	if (!store || !store.messages || !store.messages.messages || !store.embarkCharacter) {
		return;
	}

	const embarkGroup = selectCurrentEmbarkGroup(store, store.embarkCharacter._id)
    const embarkCharacters = embarkGroup?.embarkCharacters;
    const focussedCharacterId = selectFocussedCharacterId(store);

	const characterId = store.embarkCharacter._id;

	const messages = store.messages.messages
		.sort((a, b) => {
			const aTime = new Date(a.createdAt);
			const bTime = new Date(b.createdAt);
			return isBefore(aTime, bTime) ? 1 : -1;
		})
		.map(message => {
			return { ...message, character: embarkCharacters?.find(character => (character._id === message.embarkCharacterId)) }
		})
		.filter(message => (!!message.embarkCharacterId))

	const characterMessages = {};

	return store && store.messages && store.messages.messages
		? messages
		: []
}

export const selectEventMessages = (store) => {
	if (!store || !store.messages || !store.messages.messages) {
		return;
	}

	if (!store || !store.messages || !store.messages.messages) {
		return;
	}

	const characterId = store.character._id;

	const messages = store.messages.messages
		.sort((a, b) => {
			if (a.createdAt === b.createdAt) {
				return 1;
			}

			const aTime = new Date(a.createdAt);
			const bTime = new Date(b.createdAt);
			return isBefore(aTime, bTime) ? 1 : -1;
		})
		.filter(message => (!message.embarkCharacterId))
		.filter(message => (!message.targetFurnitureId))

	return messages
}

export const selectDiaryEntries = (store) => {
	return store && store.diaryEntries
		? store.diaryEntries.diaryEntries
		: []
}

export const selectTotalMessages = store => {
	return store && store.messages
		? store.messages.totalMessages
		: 0
}

export const selectCharacterKeys = store => {
	return store && store.keys && store.keys.characterKeys
		? [ ...store.keys.characterKeys ]
		: []
}

export const selectOurTradeKeys = store => {
	return store && store.keys && store.keys.characterKeys
		? [ ...store.keys.characterKeys.filter(item => (item.markedForTradeCount)) ]
		: []
}

export const selectPartnerTradeKeys = store => {
	return store && store.keys && store.keys.tradeKeys
		? [ ...store.keys.tradeKeys ]
		: []
}

export const selectTileKeys = (store, tileId) => {
	return store && store.keys && store.keys.tileKeys
		? [ ...store.keys.tileKeys.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectCurrentLock = (store, position) => {
	return store && store.locks && store.locks.locks
		? { ...store.locks.locks.find(lockInstance => lockInstance.constructionInstance?.position?.x === position?.x && lockInstance.constructionInstance?.position?.y === position?.y) }
		: {}	
}

export const selectCharacterWritingSurfaces = store => {
	return store && store.writingSurfaces
		? [ ...store.writingSurfaces.characterWritingSurfaces ]
		: []
}

export const selectOurTradeWritingSurfaces = store => {
	return store && store.writingSurfaces
		? [ ...store.writingSurfaces.characterWritingSurfaces.filter(item => (item.markedForTradeCount)) ]
		: []
}

export const selectPartnerTradeWritingSurfaces = store => {
	return store && store.writingSurfaces
		? [ ...store.writingSurfaces.tradeWritingSurfaces ]
		: []
}

export const selectCharacterWritingImplements = store => {
	return store && store.writingImplements
		? [ ...store.writingImplements.characterWritingImplements ]
		: []
}

export const selectOurTradeWritingImplements = store => {
	return store && store.writingImplements
		? [ ...store.writingImplements.characterWritingImplements.filter(item => (item.markedForTradeCount)) ]
		: []
}

export const selectPartnerTradeWritingImplements = store => {
	return store && store.writingImplements
		? [ ...store.writingImplements.tradeWritingImplements ]
		: []
}

export const selectTileWritingSurfaces = (store, tileId) => {
	return store && store.writingSurfaces
		? [ ...store.writingSurfaces.tileWritingSurfaces.filter(tile => tile.tileId === tileId).sort((a, b) => (a.order !== undefined ? (a.order - (b.order || 0)) : (a.text?.localeCompare(b.text)))) ]
		: []
}

export const selectTileWritingImplements = (store, tileId) => {
	return store && store.writingImplements
		? [ ...store.writingImplements.tileWritingImplements.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectIsKeyboardMovementEnabled = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isMovementEnabled
		: false
}

export const selectIsInventoryShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isInventoryOpen
		: false
}

export const selectIsCraftingShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isCraftingOpen
		: false
}

export const selectIsActionsShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isActionsOpen
		: false
}

export const selectIsSeedListShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isSeedListShowing
		: false
}

export const selectIsCharacterFoodShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isCharacterFoodShowing
		: false
}

export const selectIsNoCharactersNearbyErrorShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isNoCharactersNearbyErrorShowing
		: false
}

export const selectIsCraftingErrorShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isCraftingErrorShowing
		: false
}

export const selectIsMovingErrorShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isMovingErrorShowing
		: false
}

export const selectIsUnknownErrorShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isUnknownErrorShowing
		: false
}

export const selectLastCraft = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.lastCraft
		: false
}

export const selectIsSuccessShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isSuccessShowing
		: false
}

export const selectIsSchedulerShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isSchedulerShowing
		: false
}

export const selectErrorMessage = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.errorMessage
		: ""
}

export const selectIsCharacterListShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isCharacterListShowing
		: false
}

export const selectIsFightConfirmationShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isFightConfirmationShowing
		: false
}

export const selectDefendingCharacterId = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.defendingCharacterId
		: null
}

export const selectIsHelpShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isHelpOpen
		: false
}

export const selectIsQuantityInputOpen = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isQuantityInputOpen
		: false
}

export const selectIsMessagesShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isMessagesShowing
		: false
}

export const selectIsSelectItemsShowing = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isSelectItemShowing
		: false
}

export const selectIsMessagesFocussed = store => {
	return store && store.keyboardShortcuts
		? store.keyboardShortcuts.isMessagesFocussed
		: false
}

export const selectFurniture = (store) => {
	return store && store.furniture && store.furniture.furniture
		? [ ...store.furniture.furniture ]
		: []
}

export const selectCharacterWeapons = store => {
	return store && store.weapons
		? [ ...store.weapons.characterWeapons ]
		: []
}

export const selectOurTradeWeapons = store => {
	return store && store.weapons
		? [ ...store.weapons.characterWeapons.filter(item => (item.markedForTradeCount)) ]
		: []
}

export const selectPartnerTradeWeapons = store => {
	return store && store.weapons
		? [ ...store.weapons.tradeWeapons ]
		: []
}

export const selectTileWeapons = (store, tileId) => {
	return store && store.weapons
		? [ ...store.weapons.tileWeapons.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectCharacterArmour = store => {
	return store && store.armour
		? [ ...store.armour.characterArmour ]
		: []
}

export const selectOurTradeArmour = store => {
	return store && store.armour
		? [ ...store.armour.characterArmour.filter(item => (item.markedForTradeCount)) ]
		: []
}

export const selectPartnerTradeArmour = store => {
	return store && store.armour
		? [ ...store.armour.tradeArmour ]
		: []
}

export const selectTileArmour = (store, tileId) => {
	return store && store.armour
		? [ ...store.armour.tileArmour.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectCharacterClothing = store => {
	return store && store.clothing
		? [ ...store.clothing.characterClothing ]
		: []
}

export const selectOurTradeClothing = store => {
	return store && store.clothing
		? [ ...store.clothing.characterClothing.filter(item => (item.markedForTradeCount)) ]
		: []
}

export const selectPartnerTradeClothing = store => {
	return store && store.clothing
		? [ ...store.clothing.tradeClothing ]
		: []
}

export const selectTileClothing = (store, tileId) => {
	return store && store.clothing
		? [ ...store.clothing.tileClothing.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectCharacterJewellery = store => {
	return store && store.jewellery
		? [ ...store.jewellery.characterJewellery ]
		: []
}
export const selectOurTradeJewellery = store => {
	return store && store.jewellery
		? [ ...store.jewellery.characterJewellery.filter(item => (item.markedForTradeCount)) ]
		: []
}
export const selectPartnerTradeJewellery = store => {
	return store && store.jewellery
		? [ ...store.jewellery.tradeJewellery ]
		: []
}

export const selectTileJewellery = (store, tileId) => {
	return store && store.jewellery
		? [ ...store.jewellery.tileJewellery.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectBoats = store => {
	return store && store.boats
		? [ ...store.boats.boats ]
		: []
}

export const selectBrainchips = store => {
	return store && store.brainchips
		? [ ...store.brainchips.brainchips ]
		: []
}

export const selectActiveBrainchips = store => {
	return store && store.brainchips
		? [ ...store.brainchips.brainchips.filter(brainchip => (brainchip.isActive)) ]
		: []
}

export const selectNeighbouringBoats = (store, currentCharacter) => {
	return store && store.boats && store.boats.boats
	    ? [ ...store.boats.boats
	    	.filter((boat) => {
	    		let boundries;

                boundries = [ ...boat.boatType?.horizontalBoundries ]

	    		const isPersonInBoatArea = boundries?.find(boundry => {
	    			const boatTilePositionX = boat.position.x + boundry.x;
	    			const boatTilePositionY = boat.position.y + boundry.y;

	    			return boatTilePositionX === currentCharacter?.position?.x
	    				&& boatTilePositionY === currentCharacter?.position?.y
	    				// && boundry.tileKey.toLowerCase().indexOf('.sail') === -1
	    		})

		    	return isPersonInBoatArea
	    	})
	   	]
	    : []
};

export const selectTents = store => {
	return store && store.tents
		? [ ...store.tents.tents ]
		: []
}

export const selectWorkshops = store => {
	return store && store.workshops
		? [ ...store.workshops.workshops ]
		: []
}

export const selectTentById = (store, _id) => {
	if (!store || !store.tents) {
		return {};
	}

	const tent = store.tents.characterTents.find(tent => (tent._id === _id));

	if (!tent) {
		return {};
	}

	const boundries = getTentBoundries(store.character.position, store.character.tentDirection, tent.tentType);

	tent.tentBoundries = boundries?.map(point => {
		const isFloorLayerItem = selectFloorLayerItemAtPosition(store, point);
		const construction = selectConstructionByPosition(store, point);
		const workshop = selectWorkshopByPosition(store, point);
		const isPlant = selectPlantInstanceIdAtTile(store, point);
		const tents = selectNeighbouringTents(store, store.character);

		const isConstruction = construction.length > 0
		const isWorkshop = !!workshop._id
		const isTent = tents.length > 0

		return {
			...point,
			tileKey: (isFloorLayerItem || isConstruction || isWorkshop || isPlant || isTent) ? 'ERROR' : point.tileKey
		}
	})

	return store && store.tents
		? { ...tent }
		: {}
}

export const selectCharacterWorkshopBoundries = (store) => {
	const direction = store.character?.workshopDirection
	const workshopType = store.character.workshopType

	let boundries = getTentBoundries(store.character.position, store.character.workshopDirection, workshopType);

	boundries = boundries?.map(point => {
		const isFloorLayerItem = selectFloorLayerItemAtPosition(store, point);
		const construction = selectConstructionByPosition(store, point);
		const workshop = selectWorkshopByPosition(store, point);
		const isPlant = selectPlantInstanceIdAtTile(store, point);
		const tents = selectNeighbouringTents(store, store.character);

		const isConstruction = construction.length > 0
		const isWorkshop = !!workshop._id
		const isTent = tents.length > 0

		return {
			...point,
			tileKey: (isFloorLayerItem || isConstruction || isWorkshop || isPlant || isTent) ? 'ERROR' : point.tileKey
		}
	})

	return boundries;
}

export const selectWorkshopType = (store) => {
	return store.keyboardShortcuts?.workshopType
}

export const selectWorkshopBoundries = (store) => {
	const direction = store.character?.workshopDirection
	const workshopType = store.character.workshopType

	let boundries = getTentBoundries(store.character.position, store.character.workshopDirection, workshopType);

	boundries = boundries?.map(point => {
		const isFloorLayerItem = selectFloorLayerItemAtPosition(store, point);
		const construction = selectConstructionByPosition(store, point);
		const workshop = selectWorkshopByPosition(store, point);
		const isPlant = selectPlantInstanceIdAtTile(store, point);
		const tents = selectNeighbouringTents(store, store.character);

		const isConstruction = construction.length > 0
		const isWorkshop = !!workshop._id
		const isTent = tents.length > 0

		return {
			...point,
			tileKey: (isFloorLayerItem || isConstruction || isWorkshop || isPlant || isTent) ? 'ERROR' : point.tileKey
		}
	})

	return boundries;
}

export const selectWorkshopTypeById = (store, _id) => {
	return store && store.workshopTypes
		? { ...store.workshopTypes.find(workshop => (workshop._id === _id)) }
		: { workshopTypeId: _id }
}

export const selectWorkshopByPosition = (store, position) => {
	let workshopAtPosition = store.workshops.workshops.find(workshop => (workshop.workshopBoundries?.find(boundry => boundry?.x === position?.x && boundry?.y === position?.y)));

	if (!workshopAtPosition) {
		workshopAtPosition = { workshopType: store.workshopTypes.find(type => (type.name === 'Builder\'s Yard')) }
	}


	return store && store.workshops && store.workshops.workshops
		? { ...workshopAtPosition }
		: {}
}

export const selectNeighbouringTents = (store, currentCharacter) => {
	return store && store.tents && store.tents.tents
	    ? [ ...store.tents.tents
	    	.filter((tent) => !!tent.tentBoundries.find(position => (position?.x === currentCharacter?.position?.x && position?.y === currentCharacter?.position?.y))
	    		&& tent._id !== currentCharacter?._id
	    	)
	   	]
	    : []
};

export const selectStairs = store => {
	return store && store.stairs
		? [ ...store.stairs.stairs ]
		: []
}

export const selectMineWalls = store => {
	return store && store.mineWalls
		? [ ...store.mineWalls.mineWalls ]
		: []
}

export const selectTileTents = (store, tileId) => {
	return store && store.tents
		? [ ...store.tents.tileTents.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectCharacterTents = store => {
	return store && store.tents
		? [ ...store.tents.characterTents ]
		: []
}

export const selectOurTradeTents = store => {
	return store && store.tents
		? [ ...store.tents.characterTents.filter(item => (item.markedForTradeCount)) ]
		: []
}

export const selectPartnerTradeTents = store => {
	return store && store.tents
		? [ ...store.tents.tradeTents ]
		: []
}

export const selectAllTileLocks = (store) => {
	return store && store.locks
		? [ ...store.locks.tileLocks ]
		: []
}

export const selectTileLocks = (store, tileId) => {
	return store && store.locks
		? [ ...store.locks.tileLocks.filter(tile => tile.tileId === tileId) ]
		: []
}

export const selectCharacterLocks = store => {
	return store && store.locks
		? [ ...store.locks.characterLocks ]
		: []
}
export const selectOurTradeLocks = store => {
	return store && store.locks
		? [ ...store.locks.characterLocks.filter(item => (item.markedForTradeCount)) ]
		: []
}
export const selectPartnerTradeLocks = store => {
	return store && store.locks
		? [ ...store.locks.tradeLocks ]
		: []
}

export const selectWagons = store => {
	return store && store.wagons
		? [ ...store.wagons.wagons ]
		: []
}

export const selectEmbarkCharacter = store => {
	return store && store.embarkCharacter
		? { ...store.embarkCharacter }
		: {}
}

export const selectEmbarkGroups = store => {
	return store && store.embarkGroups
		? [ ...store.embarkGroups.embarkGroups ]
		: []
}

export const selectCurrentEmbarkGroup = (store,  characterId) => {
	return store && store.embarkGroups && store.embarkGroups.embarkGroups
		? store.embarkGroups.embarkGroups.find(group => (!!group.embarkCharacters.find(character => (character._id === characterId))))
		: {}
}

export const selectOpenEmbarkGroups = store => {
	return store.groups.groups.filter((group) => (!group.isClosedGroup)).sort((a, b) => (b.size - a.size));
}

export const selectSortedEmbarkGroups = store => {
	const LOBBY_WIDTH = 16;
	const PANEL_WIDTH = 48;

	const sizeMap = {
		11: 5,
		21: 6,
		31: 7,
		10000000: 8
	};

	const waitingPanels = [
		{
			isInitialPanel: true,
			top: {
				size: LOBBY_WIDTH,
				groups: []
			},
			bottom: {
				size: LOBBY_WIDTH,
				groups: []
			}
		}
	];
	const existingPanels = [
		{
			isInitialPanel: true,
			top: {
				size: LOBBY_WIDTH,
				top: {
					size: LOBBY_WIDTH,
					groups: []
				},
				bottom: {
					size: LOBBY_WIDTH,
					groups: []
				}
			},
			bottom: {
				size: LOBBY_WIDTH,
				top: {
					size: LOBBY_WIDTH,
					groups: []
				},
				bottom: {
					size: LOBBY_WIDTH,
					groups: []
				}
			},
		}
	];

	store.groups.groups.sort((a, b) => (b.size - a.size));
	store.embarkGroups.embarkGroups.sort((a, b) => (b.size - a.size));

	let panelsIndex = 0;

	store.groups.groups.forEach(embarkGroup => {
		const currentExistingPanel = existingPanels[panelsIndex];

		const sizeCutoffs = Object.keys(sizeMap);
		const sizeCutoff = sizeCutoffs.find(sizeCutoff => (embarkGroup.size < sizeCutoff))

		const groupSize = sizeMap[sizeCutoff] || 5;
		embarkGroup.roomWidth = groupSize

		const targetLane = currentExistingPanel.top.size > currentExistingPanel.bottom.size ? currentExistingPanel.bottom : currentExistingPanel.top;

		const targetLaneLane = targetLane.top?.size > targetLane.bottom?.size ? targetLane.bottom : targetLane.top;

		if (!targetLaneLane || targetLaneLane.size + groupSize > PANEL_WIDTH) {
			//new panel
			existingPanels.push({
				top: {
					top: {
						size: groupSize,
						groups: [embarkGroup]
					},
					bottom: {
						size: 0,
						groups: []
					},
					size: groupSize
				},
				bottom: {
					top: {
						size: 0,
						groups: []
					},
					bottom: {
						size: 0,
						groups: []
					},
					size: 0
				}
			})
			panelsIndex++;
		} else {
			//add group to existing panel
			targetLane.size += groupSize;
			targetLaneLane.size += groupSize;
			targetLaneLane.groups.push(embarkGroup)
		}
	})

	panelsIndex = 0;

	store.embarkGroups.embarkGroups.forEach(embarkGroup => {
		const currentWaitingPanel = waitingPanels[panelsIndex];

		const sizeCutoffs = Object.keys(sizeMap);
		const sizeCutoff = sizeCutoffs.find(sizeCutoff => (embarkGroup.size < sizeCutoff))

		const groupSize = sizeMap[sizeCutoff];
		embarkGroup.roomWidth = groupSize

		const targetLane = currentWaitingPanel.top.size > currentWaitingPanel.bottom.size ? currentWaitingPanel.bottom : currentWaitingPanel.top;

		if (targetLane.size + groupSize > PANEL_WIDTH) {
			//new panel
			waitingPanels.push({
				top: {
					size: groupSize,
					groups: [embarkGroup],
				},
				bottom: {
					size: 0,
					groups: []
				}
			})
			panelsIndex++;
		} else {
			//add group to existing panel
			targetLane.size += groupSize;
			targetLane.groups.push(embarkGroup)
		}
	})

	return store && store.embarkGroups && store.groups
		? {
			existing: existingPanels,
			waiting: waitingPanels
		}
		: {}
}

export const selectEmbarkCharacterForm = store => {
	return store && store.embarkForms
		? { ...store.embarkForms.character }
		: {}
}

// hunting and fishing

export const selectAnimalInstances = store => {
	return store && store.animals
		? [ ...store.animals.animals ]
		: []
}

export const selectFishInstances = store => {
	return store && store.fish
		? [ ...store.fish.fish ]
		: []
}

export const selectPlantProducts = store => {
	return store && store.plantProducts
		? [ ...store.plantProducts ]
		: []
}

export const selectVisionRadius = store => {
	return store && store.clock
		? store.clock.visionRadius
		: 48
}

export const selectAnimals = store => {
	const currentCharacter = selectCharacter(store);
	return store && store.animals
		? store.animals.animals.filter(animal => animal.z === currentCharacter.z)
		: []
}

export const selectOnScreenAnimals = store => {
	const currentCharacter = selectCharacter(store);
	return store && store.animals
		? store.animals.animals.filter(animal => {
			if (animal.position.x < 0 || animal.position.x >= PANEL_WIDTH) {
				return false;
			}

			if (animal.position.y < 0 || animal.position.y === PANEL_HEIGHT) {
				return false;
			}

			return animal.z === currentCharacter.z;
		})
		: []
}

export const selectAnimalsByPosition = (store, position) => {
	return store && store.animals
		? store.animals.animals.filter(animal => (animal.position.x === position.x && animal.position.y === position.y))
		: []
}

export const selectCurrentTool = store => {
	return store && store.character
		? store.tools.characterTools.find(tool => tool._id === store.character.toolId)
		: {}
}

export const selectWritingSurfaceById = (store, writingOnSurface) => {
	if (!store || !store.writingSurfaces) {
		return {};
	}

	if (writingOnSurface.surfaceService === 'writing-surface-instances') {
		const characterWritingSurface = store.writingSurfaces.characterWritingSurfaces.find(tool => tool._id === writingOnSurface.surfaceId)
		const tileWritingSurface = store.writingSurfaces.tileWritingSurfaces.find(tool => tool._id === writingOnSurface.surfaceId)
		
		return characterWritingSurface || tileWritingSurface
	}

	if (writingOnSurface.surfaceService === 'tile-instances') {
		const tileSurface = Object.values(store.tiles?.byId).find(tool => tool._id === writingOnSurface.surfaceId);

		if (tileSurface && !tileSurface.writingSurfaces) {
			tileSurface.writingSurfaces = selectTileWritingSurfaces(store, writingOnSurface.surfaceId)
		}

		return store && store.tiles
			? { ...tileSurface }
			: {}
	}

	if (writingOnSurface.surfaceService === 'weapon-instances') {
		return store && store.weapons.characterWeapons
			? store.weapons.characterWeapons.find(tool => tool._id === writingOnSurface.surfaceId)
			: {}
	}

	if (writingOnSurface.surfaceService === 'armour-instances') {
		return store && store.armour.characterArmour
			? store.armour.characterArmour.find(tool => tool._id === writingOnSurface.surfaceId)
			: {}
	}

	if (writingOnSurface.surfaceService === 'clothing-instances') {
		return store && store.clothing.characterClothing
			? store.clothing.characterClothing.find(tool => tool._id === writingOnSurface.surfaceId)
			: {}
	}

	if (writingOnSurface.surfaceService === 'jewellery-instances') {
		return store && store.jewellery.characterJewellery
			? store.jewellery.characterJewellery.find(tool => tool._id === writingOnSurface.surfaceId)
			: {}
	}
}

const paintColours = [
	{
        colourHex: 'var(--sg-white)',
        shortcut: '.',
        name: 'White'
    },
    {
        colourHex: 'var(--sg-brown)',
        shortcut: 'd',
        name: 'Brown'
    },
	{
        colourHex: 'var(--sg-teal)',
        shortcut: 'e',
        name: 'Teal'
    },
    {
        colourHex: 'var(--sg-yellow)',
        shortcut: 'f',
        name: 'Yellow'
    },
	{
        colourHex: 'var(--sg-blue)',
        shortcut: 'h',
        name: 'Blue'
    },
	{
        colourHex: 'var(--sg-pink)',
        shortcut: 'l',
        name: 'Pink'
    },
    {
        colourHex: 'var(--sg-mauve)',
        shortcut: 'o',
        name: 'Mauve'
    },
    {
        colourHex: 'var(--sg-grey)',
        shortcut: 'q',
        name: 'Grey'
    },
    {
        colourHex: 'var(--sg-sky-blue)',
        shortcut: 'r',
        name: 'Sky Blue'
    },
    {
        colourHex: 'var(--sg-green)',
        shortcut: 's',
        name: 'Green'
    },
    {
        colourHex: 'var(--sg-orange)',
        shortcut: 't',
        name: 'Orange'
    },
    {
        colourHex: 'var(--sg-purple)',
        shortcut: 'u',
        name: 'Purple'
    },
    {
        colourHex: 'var(--sg-red)',
        shortcut: 'v',
        name: 'Red'
    },
	{
        colourHex: 'var(--sg-pale-pink)',
        shortcut: 'g',
        name: 'Baby Pink'
    },
	{
        colourHex: 'var(--sg-pale-green)',
        shortcut: 'i',
        name: 'Pale Green'
    },
	{
        colourHex: 'var(--sg-pale-blue)',
        shortcut: 'j',
        name: 'Pale Blue'
    },
	{
        colourHex: 'var(--sg-dark-green)',
        shortcut: 'k',
        name: 'Dark Green'
    },
	{
        colourHex: 'var(--sg-pale-yellow)',
        shortcut: 'm',
        name: 'Pale Yellow'
    },
    {
        colourHex: 'var(--sg-sunflow-orange)',
        shortcut: 'n',
        name: 'Sunflower Orange'
    },
    {
        colourHex: 'var(--sg-slate)',
        shortcut: 'p',
        name: 'Slate'
    },
]

export const selectPaints = (store) => {
	return store && store.inventory
		? store.inventory.inventory
			.filter(item => (item.materialType?.name.indexOf('Paint') > -1))
			.map(item => ({
				...item,
				materialType: {
					...item.materialType,
					...paintColours.find(colour => (item.materialType?.name === `${colour.name} Paint`))
				}
			}))
		: []
}

export const selectCharacterLevels = (store) => {
	return store && store.characterLevels
		? store.characterLevels.map(level => ({ ...level, skillType: store.skillTypes?.find(type => type._id === level.skillTypeId) }))
		: []
}

export const selectCurrencies = (store) => {
	return store && store.currency 
		? store.currency.currencies
		: []
}

export const selectCoins = (store) => {
	return store && store.coins
		? store.coins.coins
		: []
}

export const selectTileCoins = (store, tileId) => {
	return store && store.coins
		? store.coins.coins.filter(coin => (coin.tileId === tileId))
		: []
}

export const selectCurrentWeapon = (store) => {
	if (!store.character?.weaponId) {
		return undefined;
	}

	return store.weapons?.characterWeapons?.find(weapon => (weapon._id === store.character.weaponId))
}

export const selectOrganisations = (store) => {
	return store.organisations.organisations
}

export const selectZones = (store) => {
	return store.zones.zones
}

export const selectCharacterZones = (store, characterId) => {
	const characterOrganisations = store.organisations?.organisations.filter(organisation => {
		return (organisation.memberIds.indexOf(characterId) > -1 || organisation.modIds.indexOf(characterId) > -1 || organisation.adminIds.indexOf(characterId) > -1)
	})
	return store.zones.zones.filter(zone => {
		return characterOrganisations.find(organisation => (organisation._id === zone.organisationId)) || zone.characterId === characterId
	})
}

export const selectOpenZone = (store) => {
	const zoneId = store.keyboardShortcuts.zoneId

	return store.zones?.zones?.find(zone => {
		return zone._id === zoneId
	})
}

export const selectOpenOrganisation = (store) => {
	const organisationId = store.keyboardShortcuts.organisationId

	return store.organisations?.organisations?.find(organisation => {
		return organisation._id === organisationId
	})
}

export const selectCharacterZoneOrganisations = (store, characterId) => {
	return store.organisations?.organisations.filter(organisation => {
		return (organisation.memberIds.indexOf(characterId) > -1 || organisation.modIds.indexOf(characterId) > -1 || organisation.adminIds.indexOf(characterId) > -1)
	})
}

export const selectSelectedCharacterId = (store) => {
	return store.keyboardShortcuts?.selectedCharacterId
}

export const selectOrders = (store) => {
	return store.orders?.orders
}

export const selectCharacterOrders = (store, characterId) => {
	return store.orders?.orders.filter(order => (order.characterId === characterId))
}

export const selectCharacterPanels = (store) => {
	return store.characterPanels?.characterPanels
}

export const selectOrganisationCriminals = (store, organisationId) => {
	return store.criminals?.criminals.filter(crime => {

		if (crime.victimOrganisationId === organisationId) {
			return true;
		}

		return false;
	})
}

export const selectActiveCharacterOrder = (store, characterId) => {
	return store.orders?.orders.find(order => {
		return (order.isActive && !order.isCompleted && order.characterId === characterId)
	});
}

export const selectIsCharacterSelectShowing = (store) => {
	return store.keyboardShortcuts?.isCharacterSelectShowing
}