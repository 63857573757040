import { CHANGE_CHARACTER, INITIALISING_PANEL_STARTED, START_NEW_GAME, GET_LOCKS_SUCCESS, ADD_LOCK, UPDATE_LOCK, REMOVE_LOCK, GET_TRADE_LOCKS_SUCCESS, SHOW_CHARACTER_DEATH_PAGE } from "../actionTypes";

const initialState = {
  locks: [],
  characterLocks: [],
  tileLocks: [],
  tradeLocks: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_LOCKS_SUCCESS: {
      const locks = action.payload;

      const thatOneLock = locks.find(lock => lock._id === '8e095dbc81ebff4d');

      return {
        ...state,
        locks: [...locks.filter(lockInstance => lockInstance.panelId || lockInstance.constructionId)],
        characterLocks: [...state.characterLocks, ...locks.filter(lockInstance => lockInstance.characterId)],
        tileLocks: [...locks.filter(lockInstance => lockInstance.tileId)],
      }
    }

    case GET_TRADE_LOCKS_SUCCESS: {
      const locks = action.payload;

      return {
        ...state,
        tradeLocks: [...locks],
      }
    }

    case ADD_LOCK: {
      let characterLocks = [...state.characterLocks];
      let tileLocks = [...state.tileLocks];
      let locks = [...state.locks];

      if (action.payload.characterId) {
        characterLocks = [ ...characterLocks, action.payload ]
      }

      if (action.payload.tileId) {
        tileLocks = [ ...tileLocks, action.payload ]
      }

      if (action.payload.panelId || action.payload.constructionId) {
        locks = [ ...locks, action.payload ]
      }

      return {
        ...state,
        characterLocks: [ ...characterLocks ],
        tileLocks: [ ...tileLocks ],
        locks: [ ...locks ],
      }
    }

    case UPDATE_LOCK: {
      let characterLocks = [...state.characterLocks];
      let tileLocks = [...state.tileLocks];
      let locks = [...state.locks];

      if (action.payload.characterId) {
        characterLocks = [
          ...characterLocks.filter(tool => tool._id !== action.payload._id),
          { ...characterLocks.find(tool => tool._id === action.payload._id), ...action.payload }
        ]
      }

      if (action.payload.tileId) {
        tileLocks = [
          ...tileLocks.filter(tool => tool._id !== action.payload._id),
          { ...tileLocks.find(tool => tool._id === action.payload._id), ...action.payload }
        ]

        characterLocks = [
          ...characterLocks.filter(tool => (tool._id !== action.payload._id))
        ]
      }

      if (action.payload.panelId || action.payload.constructionId) {
        locks = [
          ...locks.filter(tool => tool._id !== action.payload._id),
          { ...locks.find(tool => tool._id === action.payload._id), ...action.payload }
        ]

        characterLocks = [
          ...characterLocks.filter(tool => (tool._id !== action.payload._id))
        ]
      }

      return {
        ...state,
        characterLocks: [ ...characterLocks ],
        tileLocks: [ ...tileLocks ],
        locks: [ ...locks ],
      }
    }

    case REMOVE_LOCK: {
      return {
        ...state,
        characterLocks: [ ...state.characterLocks.filter(tool => (tool._id !== action.payload._id)) ],
        tileLocks: [ ...state.tileLocks.filter(tool => (tool._id !== action.payload._id)) ],
        locks: [ ...state.locks.filter(tool => (tool._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
