import React from "react";
import { connect } from 'react-redux'

import Game from '../game/Game';
import Loading from '../loading/Loading';

import { getCharacterAsync } from '../../../redux/actions/character.actions';
import {
    selectHighlight,
    selectUser,
    selectCharacter,
    selectIsInitialisingApp,
    selectIsInitialisingPanel,
    selectIsCharacterSelectShowing
} from '../../../redux/selectors';

import { client } from '../../../services/client';

class Home extends React.Component {
    constructor() {
        super()

        this.state = {}
    }

    componentDidMount() {
        this.props.getCharacterAsync({ userId: this.props.user.user._id });
    }

    render() {
        return (
            <div className={this.props.highlight ? "highlighting" : ""}>
                {
                    this.props.isInitialising && <Loading />
                }
                <Game />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const highlight = selectHighlight(state)
    const user = selectUser(state)
    const character = selectCharacter(state)
    const isInitialisingApp = selectIsInitialisingApp(state);
    const isInitialisingPanel = selectIsInitialisingPanel(state);
    const isCharacterSelectShowing = selectIsCharacterSelectShowing(state);

    const isInitialising = (isInitialisingApp || isInitialisingPanel) && !isCharacterSelectShowing;

    return { highlight, user, character, isInitialising }
}

export default connect(
    mapStateToProps,
    {
        getCharacterAsync,
    }
)(Home);